import React, { useState, useEffect } from 'react'
import './Footer.css'
import { Menu } from 'semantic-ui-react'
import { useLocation, Link } from 'react-router-dom'

function Footer() {
	const location = useLocation()
	const [absoluteFooterPosition, setAbsoluteFooterPosition] = useState(false)
	const [lightColor, setLightColor] = useState(false)
	const [homeFooter, setHomeFooter] = useState(false)
	const [shortMenuFooter, setShortMenuFooter] = useState(false)

	useEffect(() => {
		if (
			location.pathname === '/' ||
			location.pathname === '/first-question' ||
			location.pathname === '/short-menu' ||
			location.pathname === '/search'
		) {
			setAbsoluteFooterPosition(true)
		} else {
			setAbsoluteFooterPosition(false)
		}
		if (location.pathname === '/' || location.pathname === '/first-question') {
			setLightColor(true)
		} else {
			setLightColor(false)
		}
		if (location.pathname === '/') {
			setHomeFooter(true)
		} else {
			setHomeFooter(false)
		}
		if (location.pathname === '/short-menu') {
			setShortMenuFooter(true)
		} else {
			setShortMenuFooter(false)
		}
	}, [location.pathname])

	return (
		<Menu
			className={ (absoluteFooterPosition ? 'absolute-bottom' : '') + ' ' + (homeFooter ? 'absolute-bottom-home' : '') + ' ' + (shortMenuFooter ? 'absolute-bottom' : '') }
			style={{ width: '-webkit-fill-available', background: 'transparent' }}>
			<Menu.Item as='a'>
				<Link to='/privacy-and-terms' style={{ color: '#000' }}>
					<u className={lightColor ? 'lighter-color link-black' : 'link-black'}>
						Privacy &amp; Terms
					</u>
				</Link>
			</Menu.Item>
			<Menu.Item as='a' style={{ marginLeft: 'auto' }}>
				<Link to='/contact-us' style={{ color: '#000' }}>
					<u className={lightColor ? 'lighter-color link-black' : 'link-black'}>
						Contact
					</u>
				</Link>
			</Menu.Item>
		</Menu>
	)
}

export default Footer
