import React, { useState, useEffect } from 'react'
import { Container, Grid, GridColumn, Image, Button } from 'semantic-ui-react'
import './Home.css'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'


function Home() {
	const [homeFooter, setHomeFooter] = useState(false)

	const location = useLocation()

	useEffect(() => {
		if (location.pathname === '/') {
			setHomeFooter(true)
		} else {
			setHomeFooter(false)
		}
	}, [location.pathname])
	
	return (
		<Container style={{ display: 'table', minHeight: '94vh' }} className={homeFooter ? 'display-home' : ''}>
			<Grid columns={1} style={{ display: 'table-cell', verticalAlign: 'middle' }}>
				<GridColumn textAlign='center'>
					<Image
						src='/images/logo-bootcamp-GA.svg'
						className='ui medium image centered aligned mt-40 mb-40'
					/>
					<p className='lighter-color' style={{ maxWidth: '600px', margin: 'auto' }}>
						A free resource helping organizations and communities set up and sustain
						their own registry.
					</p>
					<div
						className='get-started mt-40 mb-100'
						style={{ display: 'inline-block' }}>
						<Link to='/short-menu'>
							<Button className='button-blue button-text'>Get started</Button>
						</Link>
					</div>
					<div>
						<p className='lighter-color'>A program of</p>
						<a href='https://geneticalliance.org/' target='_blank' rel='noreferrer'>
							<Image src='/images/GA-logo.png' centered className='z-index-1' />
						</a>
					</div>
				</GridColumn>
			</Grid>
		</Container>
	)
}

export default Home
