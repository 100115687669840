import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

function PrivacyAndTerms() {
	return (
		<>
			<div className='content-container mt-220-180 mb-220-120'>
				<h1 className='mb-40-30'>Privacy and terms</h1>
				<p className='lighter-color p-italic'>Last updated: March 1, 2022</p>
				<p className='lighter-color'>
					<a
						className='link-black'
						href='https://www.geneticalliance.org/'
						rel='noreferrer'
						target='_blank'>
						Genetic Alliance
					</a>
					, the 501(c)3 nonprofit that operates this site (also referred to as “we,”
					“us,” “our”), is committed to protecting your privacy. Below is a summary
					of our privacy procedures, including issues of confidentiality.
				</p>
				<p className='lighter-color'>
					Your privacy is important to us. To better protect your privacy, we provide
					this Privacy Policy explaining our online information practices and your
					options for how your information is collected and used. To make this
					Privacy Policy easy to locate, we make it available as a link on our home
					page.
				</p>
				<p className='lighter-color'>
					Please review this Privacy Policy carefully to understand how we collect
					information, how we use it, with whom we share it, and the choices
					available regarding our use of it. If you disagree with our policies and
					practices, you may choose not to use this site or related services. By
					accessing and using this site and related services, you accept and agree to
					this Privacy Policy.
				</p>

				<hr className='mt-40 mb-40 border-neutral-100' />

				<h2>Information we collect and how we use it</h2>
				<p className='lighter-color'>
					When you contact us using the embedded forms on this site (“Contact us” and “Register for an event”), your email address, name, organization, and any other information provided in the form are forwarded through the site to the appropriate persons within Genetic Alliance to respond. We do not share this information outside of Genetic Alliance. We use this information to:
				</p>
				<ul>
					<li className='lighter-color'>
						Offer and provide information and services to you;
					</li>
					<li className='lighter-color'>
						Communicate with you about and administer your participation in events and
						webinars offered as part of Registry Bootcamp;
					</li>
					<li className='lighter-color'>
						Respond to and communicate about your inquiries and requests; and
					</li>
					<li className='lighter-color'>
						Operate, evaluate and improve the Registry Bootcamp program.
					</li>
				</ul>
				<p className='lighter-color'>
					We may also use the information you share to connect you with other users
					of the Registry Bootcamp site and services. We will do this only with your
					explicit permission.
				</p>
				<p className='lighter-color'>
					We also use Plausible Analytics to track overall trends in the usage of our
					website. Plausible Analytics collects only aggregated information, which
					does not allow us to identify our website visitors. This information is
					used for internal purposes by Genetic Alliance staff only to improve our
					understanding of site utilization and inform the services and events we
					offer to supplement the content provided on this site. For more information
					about the data collected by Plausible Analytics, please visit the{' '}
					<a
						className='lighter-color link-black'
						href='https://plausible.io/data-policy'
						rel='noreferrer'
						target='_blank'>
						Plausible Analytics Data Policy
					</a>
					.
				</p>
				<p className='lighter-color'>We do not collect cookies.</p>

				<hr className='mt-40 mb-40 border-neutral-100' />

				<h2>Other sites</h2>
				<p className='lighter-color'>
					Genetic Alliance links to other sites that may interest those who use our
					site. Genetic Alliance is not responsible for the privacy practices or the
					content of such websites.
				</p>

				<hr className='mt-40 mb-40 border-neutral-100' />

				<h2>Confidentiality</h2>
				<p className='lighter-color'>
					Genetic Alliance is firmly committed to the principle of confidentiality of
					information for its activities and programs. Genetic Alliance staff
					members, volunteers, and paid consultants have an ethical and legal
					obligation to respect the privacy of all agency and participant
					information.
				</p>

				<hr className='mt-40 mb-40 border-neutral-100' />

				<h2>Procedures for maintaining confidentiality</h2>
				<p className='lighter-color'>
					All individuals involved with Genetic Alliance programs or data, whether in
					a staff, volunteer, or consultant capacity, shall sign an assurance of
					confidentiality, which is kept on file by the Genetic Alliance office.
				</p>
				<p className='lighter-color'>
					All individuals working with Genetic Alliance in any capacity shall keep
					completely confidential the names of individuals contained in any data
					files, including these individuals’ affiliation or genetic condition. All
					information or opinions, whether learned directly or incidentally, shall
					also be kept confidential. Individuals shall exercise reasonable caution to
					prevent access by others to agency, organization, or individual consumer
					information in their possession.
				</p>
				<p className='lighter-color'>
					Any data containing personal identifiers (names, etc.) shall be kept in a
					secure area when not being used. Reasonable caution shall be exercised in
					limiting access to information to only those working with Genetic Alliance
					on a project or as a part of other organizational activities and to those
					who have been instructed in the applicable confidentiality requirements.
				</p>
				<p className='lighter-color'>
					If records or forms with identifiers are to be transmitted to another
					party, such as for data entry, any outside parties shall be informed of
					these procedures and sign an assurance of confidentiality form.
				</p>
				<p className='lighter-color'>
					At the end of the period of performance, project, or organizational
					activity, the Genetic Alliance CEO, as required, will determine how
					documents and information will be disposed of. Genetic Alliance has review
					rights for any publication that may result from surveys, data analysis, or
					other activities performed using Genetic Alliance information.
					"Publication" means a written article or paper presented at a meeting or
					published in a journal, newsletter, or another widely circulated
					periodical.
				</p>
				<p className='lighter-color'>
					We do our best to ensure that all information posted on this website is
					accurate, respects copyright, and is non-defamatory. If you wish to bring
					our attention to content that you believe does not meet these standards,
					please{' '}
					<Link to='/contact-us' className='link-black lighter-color'>
						contact us
					</Link>
					. Please include a link to the webpage in question, along with your
					specific concerns. We will respond promptly.
				</p>

				<hr className='mt-40 mb-40 border-neutral-100' />

				<h2>Changes to our privacy policy</h2>
				<p className='lighter-color'>
					At our sole discretion, we may make changes to this Privacy Policy at any
					time. We will post an updated Privacy Policy on this site when changes are
					made. The changes will apply to all information we have about you. All
					changes are effective immediately upon posting; the date the Privacy Policy
					was last revised is identified at the top of the page. If you access and
					use our Site or Services after the date on which the Privacy Policy has
					changes, Genetic Alliance will treat your use as acceptance of the updated
					Privacy Notice.
				</p>

				<hr className='mt-40 mb-40 border-neutral-100' />

				<h2>Contact information</h2>
				<p className='lighter-color'>
					Genetic Alliance, Inc.
					<br />
					26400 Woodfield Road #189, Damascus MD 20872
					<br />
					202-966-5557
				</p>
				<Link to='/contact-us'>
					<Button className='button-neutral button-text'>Contact us</Button>
				</Link>
			</div>
		</>
	)
}

export default PrivacyAndTerms
