import React, { useState, useEffect } from 'react'
import { Form, Button } from 'semantic-ui-react'
import { useForm } from 'react-hook-form'
import emailjs from 'emailjs-com'

function Contact() {
	const {
		register,
		handleSubmit,
		reset,
		isDirty,
		formState,
		formState: { errors, isSubmitSuccessful },
	} = useForm()

	const [showMessage, setShowMessage] = useState(false)

	const sendEmail = (data, e) => {
		e.preventDefault()
		emailjs
			.sendForm(
				'service_4gzqcpr',
				'template_wqh1pfi',
				e.target,
				'user_RvBcfNskpg2k1v812rots'
			)
			.then(
				result => {
					// console.log(result.text)
				},
				error => {
					// console.log(error.text)
				}
			)
		e.target.reset()
	}

	useEffect(() => {
		if (formState.isDirty) {
			setShowMessage(false)
		}
		if (formState.isSubmitSuccessful) {
			setShowMessage(true)
			reset()
		}
	}, [formState, isSubmitSuccessful, isDirty, reset])

	return (
		<>
			<div className='content-container mt-220-180 mb-220-120'>
				<h1>Contact us</h1>
				<p className='lighter-color'>
					Do you have a question about setting up registries, or are you looking for
					more support? Do you have an idea about how we could make this site even
					better? Whatever it is, we'd love to hear from you! Send us a message using
					this form.
				</p>
				<hr className='border-neutral-100 mt-40 mb-40' />
				<Form onSubmit={handleSubmit(sendEmail)} autoComplete='off'>
					<Form.Group widths='equal' className='mt-40 mb-40'>
						<Form.Field>
							<label htmlFor='fName' className='form-label'>
								First Name
							</label>
							<input
								required
								type='text'
								name='fName'
								className={`form-frame p-text lighter-color ${
									errors.fName && 'form-frame-error'
								}`}
								placeholder='First Name'
								{...register('fName', { required: 'First Name is required.' })}
							/>
							{errors.fName && (
								<p className='form-error-text'>{errors.fName.message}</p>
							)}
						</Form.Field>

						<Form.Field>
							<label htmlFor='lName' className='form-label'>
								Last Name
							</label>
							<input
								required
								type='text'
								name='lName'
								className={`form-frame p-text lighter-color ${
									errors.lName && 'form-frame-error'
								}`}
								placeholder='Last Name'
								{...register('lName', { required: 'Last Name is required.' })}
							/>
							{errors.lName && (
								<p className='form-error-text'>{errors.lName.message}</p>
							)}
						</Form.Field>
					</Form.Group>
					<Form.Group widths='equal' className='mt-40 mb-40'>
						<Form.Field>
							<label htmlFor='orgName' className='form-label'>
								Organization
							</label>
							<input
								required
								type='text'
								name='orgName'
								className={`form-frame p-text lighter-color ${
									errors.orgName && 'form-frame-error'
								}`}
								placeholder='Organization'
								{...register('orgName', { required: 'Organization is required.' })}
							/>
							{errors.orgName && (
								<p className='form-error-text'>{errors.orgName.message}</p>
							)}
						</Form.Field>
						<Form.Field>
							<label htmlFor='email' className='form-label'>
								Email
							</label>
							<input
								required
								type='email'
								name='email'
								className={`form-frame p-text lighter-color ${
									errors.email && 'form-frame-error'
								}`}
								placeholder='Email'
								{...register('email', {
									required: 'Email is required.',
									pattern: {
										value:
											/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
										message: 'Please enter a valid email',
									},
								})}
							/>
							{errors.email && (
								<p className='form-error-text'>{errors.email.message}</p>
							)}
						</Form.Field>
					</Form.Group>
					<Form.Field className='mb-40'>
						<label htmlFor='message' className='form-label'>
							Your Message
						</label>
						<textarea
							required
							type='text'
							name='message'
							style={{ height: '120px' }}
							className={`form-frame p-text lighter-color ${
								errors.message && 'form-frame-error'
							}`}
							placeholder='Your Message'
							{...register('message', { required: 'Your Message is required.' })}
						/>
						{errors.message && (
							<p className='form-error-text'>{errors.message.message}</p>
						)}
					</Form.Field>
					{/* <Form.Field className='mb-40'>
                    <div
                        className='g-recaptcha'
                        data-sitekey='6LdVkTgdAAAAAM9nHQfZXPpf-xwrofJT7-JVOLQu'></div>
                </Form.Field> */}
					<div>
						<Button
							type='submit'
							className='button-blue button-text mb-40'
							style={{ width: '100%' }}>
							Submit
						</Button>
						<p className={showMessage ? 'center' : 'center hide'}>
							Thank you for contacting Registry Bootcamp! We have received your
							message, and will be in touch within 5-7 days.
						</p>
					</div>
				</Form>
			</div>
		</>
	)
}

export default Contact
