import React, { useState, useEffect } from 'react'
import { Image, Button } from 'semantic-ui-react'
import { Accordion, Icon } from 'semantic-ui-react'
import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import { InView } from 'react-intersection-observer'
import './Registry.css'
import { progressIndicator } from '../../components/HelperFunction'
// import RingLoader from "react-spinners/RingLoader";
// import { css } from "@emotion/react";

// const override = css`
//   display: block;
//   margin: 0 auto;
//     margin-top: 350px;
//     margin-bottom: 350px;
// `;

function Registry({data}) {
	const [activeIndex, setActiveIndex] = useState(0)
	const handleAccordion = (e, index) => {
		setActiveIndex(activeIndex === index.index ? -1 : index.index)
	}
	const [sliderIndex, setSliderIndex] = useState(0)
	// const [width, setWidth] = useState(0)
	// const carousel = useRef()

	// useEffect(() => {
	// 	if (carousel.current) {
	// 		setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth - 74)
	// 	}
	// }, [])

	// useEffect(() => {
	// 	console.log('data', data)
	// 	console.log('bat', data && data['registry-goals#0'] && data['registry-goals#0'][0])
	// 	console.log('cat', data && data[0])
	// }, [data])

	const { pathname, hash, key } = useLocation()
	useEffect(() => {
		// if not a hash link, scroll to top
		if (hash === '') {
			window.scrollTo(0, 0)
		}
		// else scroll to id
		else {
			setTimeout(() => {
				const id = hash.replace('#', '')
				const element = document.getElementById(id)
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' })
				}
			}, 0)
		}
	}, [pathname, hash, key])

	useEffect(() => {
		progressIndicator()
	}, [])

	const handleLeftSlider = () => {
		const slider = document.querySelector(".slider")
		const sliderIndex = parseInt(getComputedStyle(slider).getPropertyValue("--slider-index"))
		if (sliderIndex === 0) {
			return null
		}
		slider.style.setProperty("--slider-index", sliderIndex - 1)
		setSliderIndex(sliderIndex - 1)
	}
	const handleRightSlider = () => {
		const slider = document.querySelector(".slider")
		const sliderIndex = parseInt(getComputedStyle(slider).getPropertyValue("--slider-index"))
		if (sliderIndex === 8) {
			return null
		}
		slider.style.setProperty("--slider-index", sliderIndex + 1)
		setSliderIndex(sliderIndex + 1)
	}

	return (
		<>
			{/* <RingLoader loading={!data.length} color={'#2973dd'} css={override} size={150} /> */}
            {/* {data.length && (<>*/}<nav className='scroll-dot'> 
				<ul>
					<li>
						<a href='#0' className='dot active' data-scroll='0'>
							<span>Why are you starting a registry?</span>
						</a>
					</li>
					<li>
						<a href='#1' className='dot' data-scroll='1'>
							<span>Who needs to be at the table?</span>
						</a>
					</li>
					<li>
						<a href='#2' className='dot' data-scroll='2'>
							<span>Understanding your stakeholders’ needs</span>
						</a>
					</li>
					<li>
						<a href='#3' className='dot' data-scroll='3'>
							<span>What do you want to accomplish?</span>
						</a>
					</li>
					<li>
						<a href='#4' className='dot' data-scroll='4'>
							<span>Considering return on investment</span>
						</a>
					</li>
					<li>
						<a href='#5' className='dot' data-scroll='5'>
							<span>Creating an advisory committee</span>
						</a>
					</li>
					<li>
						<a href='#6' className='dot' data-scroll='6'>
							<span>Developing SMART objectives</span>
						</a>
					</li>
					<li>
						<a href='#7' className='dot' data-scroll='7'>
							<span>Planning for ongoing engagement</span>
						</a>
					</li>
				</ul>
			</nav>

			<div className='content-container mt-260-190'>
				<div className='section' id='0'>
					<h1 className='mb-40-30'>{data[0] && data[0]['registry-goals#0'][0]}</h1>
					<p>
						{data[0] && data[0]['registry-goals#0'][1]}
					</p>
					<Image
						className='mt-80-40'
						src='/images/registry.png'
						size='large'
						width='300'
						height='430'
						centered
					/>
				</div>
				<hr className='mt-80-40 mb-80-40 border-neutral' />

				<InView threshold={0.04}>
					{({ ref, inView }) => (
						<motion.div
							ref={ref}
							initial={{ opacity: 0 }}
							animate={inView ? { opacity: 1 } : { opacity: 0 }}
							transition={{ duration: 0.2 }}
							className='section'
							id='1'>
							<h1 className='mb-40-30'>{data[1] && data[1]['registry-goals#1'][0]}</h1>
							<p>
							{data[1] && data[1]['registry-goals#1'][1]}<b>{data[1] && data[1]['registry-goals#1'][2]}</b> {data[1] && data[1]['registry-goals#1'][3]}<b>{data[1] && data[1]['registry-goals#1'][4]}</b>{data[1] && data[1]['registry-goals#1'][5]}{' '}
								<b>{data[1] && data[1]['registry-goals#1'][6]}</b>{data[1] && data[1]['registry-goals#1'][7]}<b>{data[1] && data[1]['registry-goals#1'][8]}</b>{data[1] && data[1]['registry-goals#1'][9]}<b>{data[1] && data[1]['registry-goals#1'][10]}</b>{data[1] && data[1]['registry-goals#1'][11]}
							</p>

							<p>
								{data[1] && data[1]['registry-goals#1'][12]}
							</p>

							<Accordion fluid className='mt-88-70 text-blue-500'>
								<Accordion.Title
									active={activeIndex === 0}
									index={0}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
									{data[1] && data[1]['registry-goals#1'][13]}
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}								
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 0}>
									<p>
										<b>{data[1] && data[1]['registry-goals#1'][14]}</b>{data[1] && data[1]['registry-goals#1'][15]}
									</p>
									<p>
										<b>{data[1] && data[1]['registry-goals#1'][16]}</b>{data[1] && data[1]['registry-goals#1'][17]}{' '}
									</p>
								</Accordion.Content>
								<hr className='border-blue-200' />
								<Accordion.Title
									active={activeIndex === 1}
									index={1}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>{data[1] && data[1]['registry-goals#1'][18]}{' '}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 1}>
									<p>
										<b>{data[1] && data[1]['registry-goals#1'][19]}</b>{data[1] && data[1]['registry-goals#1'][20]}{' '}
										<a
											className='link-blue'
											href='https://wikiadvocacy.org/Facilitating_Quality_Research'
											rel='noreferrer'
											target='_blank'>
											{data[1] && data[1]['registry-goals#1'][21]}
										</a>{' '}
										{data[1] && data[1]['registry-goals#1'][22]}
									</p>
									<p>
										<b>{data[1] && data[1]['registry-goals#1'][23]}</b>{data[1] && data[1]['registry-goals#1'][24]}{' '}
									</p>
								</Accordion.Content>
								<hr className='border-blue-200' />
								<Accordion.Title
									active={activeIndex === 2}
									index={2}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
									{data[1] && data[1]['registry-goals#1'][25]}{' '}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 2}>
									<p>
										<b>{data[1] && data[1]['registry-goals#1'][26]}</b>{data[1] && data[1]['registry-goals#1'][27]}
									</p>
									<p>
										<b>{data[1] && data[1]['registry-goals#1'][28]}</b>{data[1] && data[1]['registry-goals#1'][29]}
									</p>
								</Accordion.Content>
							</Accordion>
						</motion.div>
					)}
				</InView>

				<hr className='mt-80-40 mb-80-40 border-neutral' />

				<InView threshold={0.04}>
					{({ ref, inView }) => (
						<motion.div
							ref={ref}
							initial={{ opacity: 0 }}
							animate={inView ? { opacity: 1 } : { opacity: 0 }}
							transition={{ duration: 0.2 }}
							className='section'
							id='2'>
							<h1 className='mb-40-30'>
								{data[2] && data[2]['registry-goals#2'][0]}
							</h1>

							<p>
								{data[2] && data[2]['registry-goals#2'][1]}
							</p>

							<p>
								{data[2] && data[2]['registry-goals#2'][2]}{' '}
								<a
									className=' link-black'
									href='/pdf/LunaProductSheet_CommunityDrivenInnovation.pdf'
									rel='noreferrer'
									target='_blank'>
									{data[2] && data[2]['registry-goals#2'][3]}
								</a>
								{data[2] && data[2]['registry-goals#2'][4]}
							</p>

							<p>
								{data[2] && data[2]['registry-goals#2'][5]}
							</p>

							<Accordion fluid className='mt-88-70 text-blue-500'>
								<Accordion.Title
									active={activeIndex === 3}
									index={3}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										{' '}
										{data[2] && data[2]['registry-goals#2'][6]}{' '}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 3}>
									<p>
										{data[2] && data[2]['registry-goals#2'][7]}
									</p>
									<a href='#5'>
										<Button className='mt-40 mb-20 button-blue button-text'>
											Learn more about advisory committees
										</Button>
									</a>
								</Accordion.Content>
								<hr className='border-blue-200' />
								<Accordion.Title
									active={activeIndex === 4}
									index={4}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										{' '}
										{data[2] && data[2]['registry-goals#2'][8]}{' '}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 4}>
									<p>
										{data[2] && data[2]['registry-goals#2'][9]}
									</p>
									<p>
										{data[2] && data[2]['registry-goals#2'][10]}
									</p>
								</Accordion.Content>
								<hr className='border-blue-200' />
								<Accordion.Title
									active={activeIndex === 5}
									index={5}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										{' '}
										{data[2] && data[2]['registry-goals#2'][11]}{' '}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 5}>
									<p>
										{data[2] && data[2]['registry-goals#2'][12]}
									</p>
									<p>
										{data[2] && data[2]['registry-goals#2'][13]}
									</p>
								</Accordion.Content>
								<hr className='border-blue-200' />
								<Accordion.Title
									active={activeIndex === 6}
									index={6}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										{' '}
										{data[2] && data[2]['registry-goals#2'][14]}{' '}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 6}>
									<p>
										{data[2] && data[2]['registry-goals#2'][15]}{' '}
										<a
											className='link-blue'
											href='https://dicemethods.org/DelphiTechnique'
											rel='noreferrer'
											target='_blank'>
											{data[2] && data[2]['registry-goals#2'][16]}
										</a>{' '}
										{data[2] && data[2]['registry-goals#2'][17]}{' '}
										<a
											className='link-blue'
											href='https://dicemethods.org/NominalGroupTechnique'
											rel='noreferrer'
											target='_blank'>
											{data[2] && data[2]['registry-goals#2'][18]}
										</a>
										{data[2] && data[2]['registry-goals#2'][19]}{' '}
										<a
											className='link-blue'
											href='/pdf/LunaProductSheet_CommunityDrivenInnovation.pdf'
											rel='noreferrer'
											target='_blank'>
											{data[2] && data[2]['registry-goals#2'][20]}
										</a>{' '}
										{data[2] && data[2]['registry-goals#2'][21]}
									</p>
								</Accordion.Content>
							</Accordion>

							<p className=' mt-88-70'>
								{data[2] && data[2]['registry-goals#2'][22]}{' '}
								<a
									className=' link-black'
									href='https://dicemethods.org/Tool'
									rel='noreferrer'
									target='_blank'>
									{data[2] && data[2]['registry-goals#2'][23]}
								</a>{' '}
								{data[2] && data[2]['registry-goals#2'][24]}
							</p>

							<p>
								{data[2] && data[2]['registry-goals#2'][25]}{' '}
								<i>
									{data[2] && data[2]['registry-goals#2'][26]}
								</i>{' '}
								{data[2] && data[2]['registry-goals#2'][27]}{' '}
								<i>{data[2] && data[2]['registry-goals#2'][28]}</i>.{data[2] && data[2]['registry-goals#2'][29]}
							</p>

							<Accordion fluid className='mt-88-70 text-blue-500'>
								<Accordion.Title
									active={activeIndex === 7}
									index={7}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										{data[2] && data[2]['registry-goals#2'][30]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 7}>
									<div className='text-center'>
										<div className='video-responsive'>
											<iframe
												width='560'
												height='315'
												src='https://www.youtube.com/embed/bge3C6YLQhg'
												title='YouTube video player'
												frameBorder='0'
												allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'></iframe>
										</div>
									</div>
									<p className='mt-50'>
										{data[2] && data[2]['registry-goals#2'][31]}
									</p>
								</Accordion.Content>
							</Accordion>
						</motion.div>
					)}
				</InView>

				<hr className='mt-80-40 mb-80-40 border-neutral' />

				<InView threshold={0.04}>
					{({ ref, inView }) => (
						<motion.div
							ref={ref}
							initial={{ opacity: 0 }}
							animate={inView ? { opacity: 1 } : { opacity: 0 }}
							transition={{ duration: 0.2 }}
							className='section'
							id='3'>
							<h1 className='mb-40-30'>{data[3] && data[3]['registry-goals#3'][0]}</h1>
							<p>
								{data[3] && data[3]['registry-goals#3'][1]}{' '}
							</p>

							<p>
								{data[3] && data[3]['registry-goals#3'][2]}
							</p>

							<p>
								{data[3] && data[3]['registry-goals#3'][3]}
							</p>

							<Accordion fluid className='mt-88-70 text-blue-500'>
								<Accordion.Title
									active={activeIndex === 8}
									index={8}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										{' '}
										{data[3] && data[3]['registry-goals#3'][4]}
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 8}>
									<p>
										<b>
											{data[3] && data[3]['registry-goals#3'][5]}
										</b>{' '}
										{data[3] && data[3]['registry-goals#3'][6]}
									</p>
									<p>
										<b>{data[3] && data[3]['registry-goals#3'][7]}</b>{data[3] && data[3]['registry-goals#3'][8]}
									</p>
								</Accordion.Content>
								<hr className='border-blue-200' />
								<Accordion.Title
									active={activeIndex === 9}
									index={9}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										{' '}
										{data[3] && data[3]['registry-goals#3'][9]}{' '}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 9}>
									<p>
										<b>
											{data[3] && data[3]['registry-goals#3'][10]}
										</b>{' '}
										{data[3] && data[3]['registry-goals#3'][11]}
									</p>
									<p>
										<b>{data[3] && data[3]['registry-goals#3'][12]}</b>{data[3] && data[3]['registry-goals#3'][13]}
									</p>
								</Accordion.Content>
								<hr className='border-blue-200' />
								<Accordion.Title
									active={activeIndex === 10}
									index={10}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										{' '}
										{data[3] && data[3]['registry-goals#3'][14]}{' '}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 10}>
									<p>
										<b>
											{data[3] && data[3]['registry-goals#3'][15]}
										</b>{' '}
										{data[3] && data[3]['registry-goals#3'][16]}
									</p>
									<p>
										<b>{data[3] && data[3]['registry-goals#3'][17]}</b>{data[3] && data[3]['registry-goals#3'][18]}
									</p>
								</Accordion.Content>
							</Accordion>

							<p className=' mt-88-70'>
								{data[3] && data[3]['registry-goals#3'][19]}
							</p>

							<p>
								{data[3] && data[3]['registry-goals#3'][20]}{' '}
								<a
									className=' link-black'
									href='/pdf/LunaProductSheet_CommunityDrivenInnovation.pdf'
									rel='noreferrer'
									target='_blank'>
									{data[3] && data[3]['registry-goals#3'][21]}
								</a>{' '}
								{data[3] && data[3]['registry-goals#3'][22]}
							</p>
							<a href='#5'>
								<Button className='mt-40 button-blue button-text'>
									Learn more about advisory committees
								</Button>
							</a>
						</motion.div>
					)}
				</InView>

				<hr className='mt-80-40 mb-80-40 border-neutral' />

				<InView threshold={0.04}>
					{({ ref, inView }) => (
						<motion.div
							ref={ref}
							initial={{ opacity: 0 }}
							animate={inView ? { opacity: 1 } : { opacity: 0 }}
							transition={{ duration: 0.2 }}
							className='section'
							id='4'>
							<h1 className='mb-40-30'>{data[4] && data[4]['registry-goals#4'][0]}</h1>
							<p>
								{data[4] && data[4]['registry-goals#4'][1]}
							</p>

							<Accordion fluid className='mt-88-70 text-blue-500'>
								<Accordion.Title
									active={activeIndex === 11}
									index={11}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										{' '}
										{data[4] && data[4]['registry-goals#4'][2]}{' '}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 11}>
									<p>
										{data[4] && data[4]['registry-goals#4'][3]}
									</p>
								</Accordion.Content>
								<hr className='border-blue-200' />
								<Accordion.Title
									active={activeIndex === 12}
									index={12}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										{' '}
										{data[4] && data[4]['registry-goals#4'][4]}{' '}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 12}>
									<p>
										{data[4] && data[4]['registry-goals#4'][5]}
									</p>
								</Accordion.Content>
								<hr className='border-blue-200' />
								<Accordion.Title
									active={activeIndex === 13}
									index={13}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										{' '}
										{data[4] && data[4]['registry-goals#4'][6]}
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 13}>
									<p>
										{data[4] && data[4]['registry-goals#4'][7]}
									</p>
								</Accordion.Content>
							</Accordion>

							<p className='mt-88-70 '>
								{data[4] && data[4]['registry-goals#4'][8]}
							</p>

							<Accordion fluid className='mt-88-70 text-blue-500 hide-mobile'>
								<Accordion.Title
									active={activeIndex === 14}
									index={14}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										{' '}
										{data[4] && data[4]['registry-goals#4'][9]}{' '}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 14}>
									<table>
										<tbody>
										<tr className='tr-1'>
											<th>
												<p className='indicator-text'>{data[4] && data[4]['registry-goals#4'][10]}</p>
											</th>
											<th>
												<p className='indicator-text'>{data[4] && data[4]['registry-goals#4'][11]}</p>
											</th>
										</tr>
										<tr className='tr-2'>
											<td>
												<p>{data[4] && data[4]['registry-goals#4'][12]}</p>
											</td>
											<td>
												<p>
													{data[4] && data[4]['registry-goals#4'][13]}
												</p>
											</td>
										</tr>
										<tr className='tr-3'>
											<td>
												<p>{data[4] && data[4]['registry-goals#4'][14]}</p>
											</td>
											<td>
												<p>
													{data[4] && data[4]['registry-goals#4'][15]}
												</p>
											</td>
										</tr>
										<tr className='tr-4'>
											<td>
												<p>{data[4] && data[4]['registry-goals#4'][16]}</p>
											</td>
											<td>
												<p>
													{data[4] && data[4]['registry-goals#4'][17]}
												</p>
											</td>
										</tr>
										<tr className='tr-5'>
											<td>
												<p>{data[4] && data[4]['registry-goals#4'][18]}</p>
											</td>
											<td>
												<p>
													{data[4] && data[4]['registry-goals#4'][19]}
												</p>
											</td>
												</tr>
												</tbody>
									</table>
								</Accordion.Content>
							</Accordion>

							<div className='text-blue-500 hide-desktop'>
								<h2 className='text-blue-500 mt-88-70 mb-25 '>
									{data[4] && data[4]['registry-goals#4'][20]}
								</h2>
								<p className='indicator-text'>{data[4] && data[4]['registry-goals#4'][21]}</p>
								<p>{data[4] && data[4]['registry-goals#4'][22]}</p>
								<p className='indicator-text'>{data[4] && data[4]['registry-goals#4'][23]}</p>
								<p>
									{data[4] && data[4]['registry-goals#4'][24]}
								</p>
								<hr className='border-blue-200 mt-25 mb-25' />
								<p className='indicator-text'>{data[4] && data[4]['registry-goals#4'][25]}</p>
								<p>{data[4] && data[4]['registry-goals#4'][26]}</p>
								<p className='indicator-text'>{data[4] && data[4]['registry-goals#4'][27]}</p>
								<p>
									{data[4] && data[4]['registry-goals#4'][28]}
								</p>
								<hr className='border-blue-200 mt-25 mb-25' />
								<p className='indicator-text'>{data[4] && data[4]['registry-goals#4'][29]}</p>
								<p>{data[4] && data[4]['registry-goals#4'][30]}</p>
								<p className='indicator-text'>{data[4] && data[4]['registry-goals#4'][31]}</p>
								<p>
									{data[4] && data[4]['registry-goals#4'][32]}
								</p>
								<hr className='border-blue-200 mt-25 mb-25' />
								<p className='indicator-text'>{data[4] && data[4]['registry-goals#4'][33]}</p>
								<p>{data[4] && data[4]['registry-goals#4'][34]}</p>
								<p className='indicator-text'>{data[4] && data[4]['registry-goals#4'][35]}</p>
								<p>
									{data[4] && data[4]['registry-goals#4'][36]}
								</p>
							</div>

							<p className='mt-88-70 '>
								{data[4] && data[4]['registry-goals#4'][37]}
							</p>

							<p>
								{data[4] && data[4]['registry-goals#4'][38]}
							</p>
							<a href='#6'>
								<Button className='mt-40 mb-20 button-blue button-text'>
									Learn how to develop SMART objectives
								</Button>
							</a>
						</motion.div>
					)}
				</InView>

				<hr className='mt-80-40 mb-80-40 border-neutral' />

				<InView threshold={0.04}>
					{({ ref, inView }) => (
						<motion.div
							ref={ref}
							initial={{ opacity: 0 }}
							animate={inView ? { opacity: 1 } : { opacity: 0 }}
							transition={{ duration: 0.2 }}
							className='section'
							id='5'>
							<h1 className='mb-40-30'>{data[5] && data[5]['registry-goals#5'][0]}</h1>

							<p>
								{data[5] && data[5]['registry-goals#5'][1]}
							</p>

							<p>
								{data[5] && data[5]['registry-goals#5'][2]}
							</p>

							<p>
								{data[5] && data[5]['registry-goals#5'][3]}
							</p>

							<Accordion fluid className='mt-88-70 text-blue-500'>
								<Accordion.Title
									active={activeIndex === 15}
									index={15}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										{' '}
										{data[5] && data[5]['registry-goals#5'][4]}{' '}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 15}>
									<p>
										{data[5] && data[5]['registry-goals#5'][5]}
									</p>{' '}
								</Accordion.Content>
								<hr className='border-blue-200' />
								<Accordion.Title
									active={activeIndex === 16}
									index={16}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										{' '}
										{data[5] && data[5]['registry-goals#5'][6]}{' '}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 16}>
									<p>
										{data[5] && data[5]['registry-goals#5'][7]}
									</p>
								</Accordion.Content>
								<hr className='border-blue-200' />
								<Accordion.Title
									active={activeIndex === 17}
									index={17}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										{' '}
										{data[5] && data[5]['registry-goals#5'][8]}{' '}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 17}>
									<p>
										{data[5] && data[5]['registry-goals#5'][9]}
									</p>
								</Accordion.Content>
							</Accordion>

							<p className='mt-88-70 '>
								{data[5] && data[5]['registry-goals#5'][10]}
							</p>

							<Accordion fluid className='mt-88-70 text-blue-500'>
								<Accordion.Title
									active={activeIndex === 18}
									index={18}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										{' '}
										{data[5] && data[5]['registry-goals#5'][11]}{' '}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 18}>
									<p>
										{data[5] && data[5]['registry-goals#5'][12]}
									</p>{' '}
								</Accordion.Content>
								<hr className='border-blue-200' />
								<Accordion.Title
									active={activeIndex === 19}
									index={19}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										{' '}
										{data[5] && data[5]['registry-goals#5'][13]}{' '}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 19}>
									<p>
										{data[5] && data[5]['registry-goals#5'][14]}
										<a
											className='link-blue'
											href='/pdf/Considerations-for-Community-Stakeholder-Compensation.pdf'
											rel='noreferrer'
											target='_blank'>
											{data[5] && data[5]['registry-goals#5'][15]}
										</a>
										{data[5] && data[5]['registry-goals#5'][16]}
									</p>
									<p>
										{data[5] && data[5]['registry-goals#5'][17]}{' '}
										<a
											className='link-blue'
											href='https://docs.google.com/document/d/1lAgUfOiHkYMwLNbwJHNelTvWBdfjLDjD0mOULbO0G7w/edit'
											rel='noreferrer'
											target='_blank'>
											{data[5] && data[5]['registry-goals#5'][18]}
										</a>{' '}
										{data[5] && data[5]['registry-goals#5'][19]}
									</p>
								</Accordion.Content>
								<hr className='border-blue-200' />
								<Accordion.Title
									active={activeIndex === 20}
									index={20}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										{' '}
										{data[5] && data[5]['registry-goals#5'][20]}{' '}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 20}>
									<p>
										{data[5] && data[5]['registry-goals#5'][21]}
									</p>
									<p>
										{data[5] && data[5]['registry-goals#5'][22]}
										<a
											className='link-blue'
											href='/pdf/Genetic-Alliance-Guide-To-Navigating-A-Successful-Collaboration.pdf'
											rel='noreferrer'
											target='_blank'>
											{data[5] && data[5]['registry-goals#5'][23]}
										</a>
										{data[5] && data[5]['registry-goals#5'][24]}
									</p>
								</Accordion.Content>
							</Accordion>

							<p className='mt-88-70 '>
								{data[5] && data[5]['registry-goals#5'][25]}
							</p>

							<Accordion fluid className='mt-88-70 text-blue-500'>
								<h2 className='text-blue-500 h2-dropdown'>
									{' '}
									{data[5] && data[5]['registry-goals#5'][26]}{' '}
								</h2>
								<hr className='border-blue-200' />
								<Accordion.Title
									active={activeIndex === 21}
									index={21}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										{' '}
										{data[5] && data[5]['registry-goals#5'][27]}{' '}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 21}>
									<p>
										{data[5] && data[5]['registry-goals#5'][28]}{' '}
									</p>
								</Accordion.Content>
								<hr className='border-blue-200' />
								<Accordion.Title
									active={activeIndex === 22}
									index={22}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										{' '}
										{data[5] && data[5]['registry-goals#5'][29]}{' '}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 22}>
									<p>
										{data[5] && data[5]['registry-goals#5'][30]}
									</p>
									<a href='#3'>
										<Button className='mt-40 mb-20 button-blue button-text'>
											Exercises for developing goals
										</Button>
									</a>
								</Accordion.Content>
								<hr className='border-blue-200' />
								<Accordion.Title
									active={activeIndex === 23}
									index={23}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										{' '}
										{data[5] && data[5]['registry-goals#5'][31]}{' '}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 23}>
									<p>
										{data[5] && data[5]['registry-goals#5'][32]}
									</p>
									<ul>
										<li>{data[5] && data[5]['registry-goals#5'][33]}</li>
										<ul>
											<li>
												{data[5] && data[5]['registry-goals#5'][34]}
											</li>
											<li>
												{data[5] && data[5]['registry-goals#5'][35]}
											</li>
										</ul>
										<li>
											{data[5] && data[5]['registry-goals#5'][36]}
										</li>
									</ul>
									<p>
										{data[5] && data[5]['registry-goals#5'][37]}{' '}
										<a
											className='link-blue'
											href='https://www.ncbi.nlm.nih.gov/books/NBK562567/#ch2.s2'
											rel='noreferrer'
											target='_blank'>
											{data[5] && data[5]['registry-goals#5'][38]}
										</a>{' '}
										{data[5] && data[5]['registry-goals#5'][39]}
									</p>
								</Accordion.Content>
								<hr className='border-blue-200' />
								<Accordion.Title
									active={activeIndex === 24}
									index={24}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										{' '}
										{data[5] && data[5]['registry-goals#5'][40]}{' '}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 24}>
									<p>
										{data[5] && data[5]['registry-goals#5'][41]}
									</p>{' '}
									<a href='#6'>
										<Button className='mt-40 button-blue button-text'>
											Learn how to develop SMART objectives
										</Button>
									</a>
								</Accordion.Content>
							</Accordion>

							<p className=' mt-88-70'>
								{data[5] && data[5]['registry-goals#5'][42]}
								<a
									className=' link-black'
									href='https://docs.google.com/document/d/1YNKV8Ln5abUXZpZOdtq3RwLERqTAaAO1PoIGZ_ys44M/edit'
									rel='noreferrer'
									target='_blank'>
									{data[5] && data[5]['registry-goals#5'][43]}
								</a>
								{data[5] && data[5]['registry-goals#5'][44]}
							</p>
						</motion.div>
					)}
				</InView>

				<hr className='mt-80-40 mb-80-40 border-neutral' />

				{/* <InView threshold={0.04}>
					{({ ref, inView }) => (
						<motion.div
							ref={ref}
							initial={{ opacity: 0 }}
							animate={inView ? { opacity: 1 } : { opacity: 0 }}
							transition={{ duration: 0.2 }}
							className='section'
							id='6'>
							<h1 className='mb-40-30'>{data[6] && data[6]['registry-goals#6'][0]}</h1>
							<p>
								{data[6] && data[6]['registry-goals#6'][1]}
							</p> */}
					<div
							// ref={ref}
							initial={{ opacity: 0 }}
							// animate={inView ? { opacity: 1 } : { opacity: 0 }}
							transition={{ duration: 0.2 }}
							className='section'
							id='6'>
							<h1 className='mb-40-30'>{data[6] && data[6]['registry-goals#6'][0]}</h1>
							<p>
								{data[6] && data[6]['registry-goals#6'][1]}
					</p>
				</div>
			</div>
			

			<div className=''>
					

							<div
								// ref={carousel}
								className='carousel hide-mobile'
								// whileTap={{ cursor: 'grabbing' }}
							>
							<button className={`handle left-handle ${sliderIndex === 0 ? 'disable-handle' : ''}`} onClick={handleLeftSlider}>
							<div className="text"><h2><Icon name='chevron left' className={`handle-color ${sliderIndex === 0 ? 'disable-handle-color' : ''}`} onClick={handleLeftSlider} /></h2></div>
							</button>
								<div
									// drag='x'
									// dragConstraints={{ right: 0, left: -width }}
									className='inner-carousel slider'>
									<div className='item'>
										<img src='/images/S.svg' alt='Specific' />
										<div className='text-blue-500'>
											<h2 className='text-blue-500 h2-dropdown'>Specific</h2>
											<p>
												(Simple, Sensible, Significant)
												<br />
												Specific objectives allow you to focus your efforts and achieve your
												goal. To ensure specificity, answer the five “W” questions:
											</p>
											<ul>
												<li>What do I want to accomplish?</li>
												<li>Why is this goal important?</li>
												<li>Who is involved?</li>
												<li>Where is it located?</li>
												<li>Which resources are required, and are there any limits?</li>
											</ul>
										</div>
									</div>
									{/* <img className="next-carousel-button" src='/images/next-carousel-button.png' height="56" alt=""  /> */}
									<div className='item'>
										<img src='/images/M.svg' alt='Measurable' />
										<div className='text-blue-500'>
											<h2 className='text-blue-500 h2-dropdown'>Measurable</h2>
											<p>(Meaningful, Motivating)</p>
											<p>
												Measurable objectives allow you to track and realize your progress,
												as well as any hiccups. Assessing progress helps you stay motivated,
												meet deadlines, and document impact. A measurable objective should
												address questions such as:
											</p>
											<ul>
												<li>How much?</li>
												<li>How many?</li>
												<li>How will I know when I have accomplished the objective?</li>
											</ul>
										</div>
									</div>
									<div className='item'>
										<img src='/images/A.svg' alt='Attainable' />
										<div className='text-blue-500'>
											<h2 className='text-blue-500 h2-dropdown'>Attainable</h2>
											<p>(Agreed, Achievable)</p>
											<p>
												By setting realistic objectives, you will ensure that your goal is
												attainable. Setting realistic objectives can also help you meet a
												stretch goal. An achievable goal will usually answer questions such
												as:
											</p>
											<ul>
												<li>How can I accomplish this goal?</li>
												<li>
													How realistic is the goal, based on other constraints (such as
													financial factors)?
												</li>
											</ul>
										</div>
									</div>
									<div className='item'>
										<img src='/images/R.svg' alt='Relevant' />
										<div className='text-blue-500'>
											<h2 className='text-blue-500 h2-dropdown'>Relevant</h2>
											<p>(Reasonable, Reviewed, Results-based)</p>
											<p>
												Objectives must be relevant, not just to you as the organization
												starting a registry but also to your stakeholders. In other words,
												your objectives should be relevant across stakeholder audiences. A
												relevant objective answers ‘yes’ to these questions:
											</p>
											<ul>
												<li>Does this seem worthwhile?</li>
												<li>Is this the right time?</li>
												<li>Does this match our other efforts/needs?</li>
												<li>Am I the right person to reach this goal?</li>
												<li>Is it applicable in the current socio-economic environment?</li>
											</ul>
										</div>
									</div>
									<div className='item'>
										<img src='/images/T.svg' alt='Time-Based' />
										<div className='text-blue-500'>
											<h2 className='text-blue-500 h2-dropdown'>Time-Based</h2>
											<p>(Time-bound, Time-limited)</p>
											<p>
												Time-based objectives allow you to stick to a target deadline and
												prevent you from prioritizing everyday tasks over the important
												goals set here. A time-bound objective answers these questions:
											</p>
											<ul>
												<li>When?</li>
												<li>What can I do six months from now?</li>
												<li>What can I do six weeks from now?</li>
												<li>What can I do today?</li>
											</ul>
										</div>
									</div>
								</div>
					<button className={`handle right-handle ${sliderIndex === 8 ? 'disable-handle' : ''}`}onClick={handleRightSlider}>
						<div className="text"><h2><Icon name='chevron right' className={`handle-color ${sliderIndex === 8 ? 'disable-handle-color' : ''}`} onClick={handleRightSlider} /></h2></div>
					</button>
				</div>
				</div>

			<div className='content-container mb-160-40'>
			
							<Accordion
								fluid
								className='mt-88-70 mb-88-70 text-blue-500 hide-desktop'>
								<Accordion.Title
									active={activeIndex === 25}
									index={25}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										S - Specific
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 25}>
									<p>
										(Simple, Sensible, Significant)
										<br />
										Specific objectives allow you to focus your efforts and achieve your
										goal. To ensure specificity, answer the five “W” questions:
									</p>
									<ol>
										<li>What do I want to accomplish?</li>
										<li>Why is this goal important?</li>
										<li>Who is involved?</li>
										<li>Where is it located?</li>
										<li>Which resources are required, and are there any limits?</li>
									</ol>
								</Accordion.Content>
								<hr className='border-blue-200' />
								<Accordion.Title
									active={activeIndex === 26}
									index={26}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										M - Measurable
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 26}>
									<p>(Meaningful, Motivating)</p>
									<p>
										Measurable objectives allow you to track and realize your progress, as
										well as any hiccups. Assessing progress helps you stay motivated, meet
										deadlines, and document impact. A measurable objective should address
										questions such as:
									</p>
									<ol>
										<li>How much?</li>
										<li>How many?</li>
										<li>How will I know when I have accomplished the objective?</li>
									</ol>
								</Accordion.Content>
								<hr className='border-blue-200' />
								<Accordion.Title
									active={activeIndex === 27}
									index={27}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										A - Attainable
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 27}>
									<p>(Agreed, Achievable)</p>
									<p>
										By setting realistic objectives, you will ensure that your goal is
										attainable. Setting realistic objectives can also help you meet a
										stretch goal. An achievable goal will usually answer questions such
										as:
									</p>
									<ol>
										<li>How can I accomplish this goal?</li>
										<li>
											How realistic is the goal, based on other constraints (such as
											financial factors)?
										</li>
									</ol>
								</Accordion.Content>
								<hr className='border-blue-200' />
								<Accordion.Title
									active={activeIndex === 28}
									index={28}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										R - Relevant
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 28}>
									<p>(Reasonable, Reviewed, Results-based)</p>
									<p>
										Objectives must be relevant, not just to you as the organization
										starting a registry but also to your stakeholders. In other words,
										your objectives should be relevant across stakeholder audiences. A
										relevant objective answers ‘yes’ to these questions:
									</p>
									<ol>
										<li>Does this seem worthwhile?</li>
										<li>Is this the right time?</li>
										<li>Does this match our other efforts/needs?</li>
										<li>Am I the right person to reach this goal?</li>
										<li>Is it applicable in the current socio-economic environment?</li>
									</ol>
								</Accordion.Content>
								<hr className='border-blue-200' />
								<Accordion.Title
									active={activeIndex === 29}
									index={29}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										T - Time-based
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 29}>
									<p>(Time-bound, Time-limited)</p>
									<p>
										Time-based objectives allow you to stick to a target deadline and
										prevent you from prioritizing everyday tasks over the important goals
										set here. A time-bound objective answers these questions:
									</p>
									<ol>
										<li>When?</li>
										<li>What can I do six months from now?</li>
										<li>What can I do six weeks from now?</li>
										<li>What can I do today?</li>
									</ol>
								</Accordion.Content>
							</Accordion>

							<p>
								{data[6] && data[6]['registry-goals#6'][2]}
							</p>
						{/* </motion.div> */}
					{/* )} */}
				{/* </InView> */}

				<hr className='mt-80-40 mb-80-40 border-neutral' />

				<InView threshold={0.04}>
					{({ ref, inView }) => (
						<motion.div
							ref={ref}
							initial={{ opacity: 0 }}
							animate={inView ? { opacity: 1 } : { opacity: 0 }}
							transition={{ duration: 0.2 }}
							className='section'
							id='7'>
							<h1 className='mb-40-30'>{data[7] && data[7]['registry-goals#7'][0]}</h1>
							<p>
								{data[7] && data[7]['registry-goals#7'][1]}
							</p>
							<Image src='./images/bootcamp-lifecycle.svg' className='mt-60 mb-60' />
							<p className='center'>
								<i>
									{data[7] && data[7]['registry-goals#7'][2]}
								</i>
							</p>
							<p>
								{data[7] && data[7]['registry-goals#7'][3]}
							</p>

							<Accordion fluid className='mt-88-70 text-blue-500'>
								<Accordion.Title
									active={activeIndex === 30}
									index={30}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										{data[7] && data[7]['registry-goals#7'][4]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 30}>
									<p>
										{data[7] && data[7]['registry-goals#7'][5]}
										<a
											className='link-blue'
											href='https://docs.google.com/document/d/120dxI7CafmXgbflylt3xC_9gBrFl7RMZkXueVJ9NylE/edit'
											rel='noreferrer'
											target='_blank'>
											{data[7] && data[7]['registry-goals#7'][6]}
										</a>
										{data[7] && data[7]['registry-goals#7'][7]}
									</p>{' '}
								</Accordion.Content>
								<hr className='border-blue-200' />
								<Accordion.Title
									active={activeIndex === 31}
									index={31}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										{data[7] && data[7]['registry-goals#7'][8]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 31}>
									<p>
										{data[7] && data[7]['registry-goals#7'][9]}
										<a
											className='link-blue'
											href='https://docs.google.com/document/d/1thofaoT8oRzBPPrm6iYManDw7sNUqYf_Jz3UfgA-tqo/edit?usp=sharing'
											rel='noreferrer'
											target='_blank'>
											{data[7] && data[7]['registry-goals#7'][10]}
										</a>
										{data[7] && data[7]['registry-goals#7'][11]}
									</p>
								</Accordion.Content>
								<hr className='border-blue-200' />
								<Accordion.Title
									active={activeIndex === 32}
									index={32}
									onClick={handleAccordion}>
									<h2 className='text-blue-500 h2-dropdown'>
										{data[7] && data[7]['registry-goals#7'][12]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-blue-500' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 32}>
									<p>
										{data[7] && data[7]['registry-goals#7'][13]}
										<a
											className='link-blue'
											href='https://docs.google.com/document/d/1YNKV8Ln5abUXZpZOdtq3RwLERqTAaAO1PoIGZ_ys44M/edit?usp=sharing'
											rel='noreferrer'
											target='_blank'>
											{data[7] && data[7]['registry-goals#7'][14]}
										</a>
										{data[7] && data[7]['registry-goals#7'][15]}
									</p>
								</Accordion.Content>
							</Accordion>
						</motion.div>
					)}
				</InView>
			</div>
			{/* </>)} */}
		</>
	)
}

export default Registry
