import React, { useState, useEffect } from 'react'
import { Form, Button } from 'semantic-ui-react'
import { useForm } from 'react-hook-form'
import emailjs from 'emailjs-com'

function EventRegistration() {
	const {
		register,
		handleSubmit,
		reset,
		isDirty,
		formState,
		formState: { errors, isSubmitSuccessful },
	} = useForm()

	const [showMessage, setShowMessage] = useState(false)

	const sendEmail = (data, e) => {
		e.preventDefault()
		emailjs
			.sendForm(
				'service_4gzqcpr',
				'template_ae9vtz7',
				e.target,
				'user_RvBcfNskpg2k1v812rots'
			)
			.then(
				result => {
					// console.log(result.text)
				},
				error => {
					// console.log(error.text)
				}
			)
		e.target.reset()
	}

	useEffect(() => {
		if (formState.isDirty) {
			setShowMessage(false)
		}
		if (formState.isSubmitSuccessful) {
			setShowMessage(true)
			reset()
		}
	}, [formState, isSubmitSuccessful, isDirty, reset])

	return (
		<>
			<div className='content-container mt-220-180 mb-220-120'>
				<h1>Join us for a Registry Bootcamp workshop</h1>
				<p className='lighter-color mb-60'>
					Use this form to register for one or multiple Bootcamp workshops. Please
					submit once per organization.
				</p>
				<Form onSubmit={handleSubmit(sendEmail)} autoComplete='off'>
					<Form.Group widths='equal' className='mb-60'>
						<Form.Field>
							<label htmlFor='orgName' className='form-label'>
								Organization
							</label>
							<input
								required
								type='text'
								name='orgName'
								className={`form-frame p-text lighter-color ${
									errors.orgName && 'form-frame-error'
								}`}
								placeholder='Organization'
								{...register('orgName', { required: 'Organization is required.' })}
							/>
							{errors.orgName && (
								<p className='form-error-text'>{errors.orgName.message}</p>
							)}
						</Form.Field>
						<Form.Field>
							<label htmlFor='orgWebsite' className='form-label'>
								Organization Website
							</label>
							<input
								required
								type='text'
								name='orgWebsite'
								className={`form-frame p-text lighter-color ${
									errors.orgWebsite && 'form-frame-error'
								}`}
								placeholder='Organization website'
								{...register('orgWebsite', {
									required: 'Organization website is required.',
								})}
							/>
							{errors.orgWebsite && (
								<p className='form-error-text'>{errors.orgWebsite.message}</p>
							)}
						</Form.Field>
					</Form.Group>
					<Form.Group grouped className='mb-60'>
						<label html='orgType' className='form-label'>
							Type of Organization
						</label>
						<Form.Field className='mb-15'>
							<label
								htmlFor='orgType'
								className='radio-label radio-text lighter-color'>
								<input
									required
									type='radio'
									name='orgType'
									value='Non-profit'
									className={`radio-button ${errors.orgType}`}
									{...register('orgType', {
										required: 'Type of organization is required.',
									})}
								/>
								Non-profit
							</label>
						</Form.Field>
						<Form.Field className='mb-15'>
							<label
								htmlFor='orgType'
								className='radio-label radio-text lighter-color'>
								<input
									required
									type='radio'
									name='orgType'
									value='Academic institution'
									className={`radio-button ${errors.orgType}`}
									{...register('orgType', {
										required: 'Type of organization is required.',
									})}
								/>
								Academic institution
							</label>
						</Form.Field>
						<Form.Field className='mb-15'>
							<label
								htmlFor='orgType'
								className='radio-label radio-text lighter-color'>
								<input
									required
									type='radio'
									name='orgType'
									value='Hospital'
									className={`radio-button ${errors.orgType}`}
									{...register('orgType', {
										required: 'Type of organization is required.',
									})}
								/>
								Hospital
							</label>
						</Form.Field>
						<Form.Field className='mb-15'>
							<label
								htmlFor='orgType'
								className='radio-label radio-text lighter-color'>
								<input
									required
									type='radio'
									name='orgType'
									value='Public benefit corporation'
									className={`radio-button ${errors.orgType}`}
									{...register('orgType', {
										required: 'Type of organization is required.',
									})}
								/>
								Public benefit corporation
							</label>
						</Form.Field>
						<Form.Field className='mb-15'>
							<label
								htmlFor='orgType'
								className='radio-label radio-text lighter-color'>
								<input
									required
									type='radio'
									name='orgType'
									value='For-profit'
									className={`radio-button ${errors.orgType}`}
									{...register('orgType', {
										required: 'Type of organization is required.',
									})}
								/>
								For-profit
							</label>
						</Form.Field>
						<Form.Field>
							<label
								htmlFor='orgType'
								className='radio-label radio-text lighter-color'>
								<input
									required
									type='radio'
									name='orgType'
									value='Other'
									className={`radio-button ${errors.orgType}`}
									{...register('orgType', {
										required: 'Type of organization is required.',
									})}
								/>
								Other
							</label>
							{errors.orgType && (
								<p className='form-error-text'>{errors.orgType.message}</p>
							)}
						</Form.Field>
					</Form.Group>
					<Form.Group widths='equal' className='mb-60'>
						<Form.Field>
							<label htmlFor='fName' className='form-label'>
								First Name
							</label>
							<input
								required
								type='text'
								name='fName'
								className={`form-frame p-text lighter-color ${
									errors.fName && 'form-frame-error'
								}`}
								placeholder='First Name'
								{...register('fName', { required: 'First Name is required.' })}
							/>
							{errors.fName && (
								<p className='form-error-text'>{errors.fName.message}</p>
							)}
						</Form.Field>
						<Form.Field>
							<label htmlFor='lName' className='form-label'>
								Last Name
							</label>
							<input
								required
								type='text'
								name='lName'
								className={`form-frame p-text lighter-color ${
									errors.lName && 'form-frame-error'
								}`}
								placeholder='Last Name'
								{...register('lName', { required: 'Last Name is required.' })}
							/>
							{errors.lName && (
								<p className='form-error-text'>{errors.lName.message}</p>
							)}
						</Form.Field>
					</Form.Group>
					<Form.Group widths='equal' className='mb-60'>
						<Form.Field>
							<label htmlFor='email' className='form-label'>
								Email
							</label>
							<input
								required
								type='email'
								name='email'
								className={`form-frame p-text lighter-color ${
									errors.email && 'form-frame-error'
								}`}
								placeholder='Email'
								{...register('email', {
									required: 'Email is required.',
									pattern: {
										value:
											/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
										message: 'Please enter a valid email',
									},
								})}
							/>
							{errors.email && (
								<p className='form-error-text'>{errors.email.message}</p>
							)}
						</Form.Field>
						<Form.Field>
							<label htmlFor='title' className='form-label'>
								Title
							</label>
							<input
								required
								type='text'
								name='title'
								className={`form-frame p-text lighter-color ${
									errors.title && 'form-frame-error'
								}`}
								placeholder='Title'
								{...register('title', { required: 'Title is required.' })}
							/>
							{errors.title && (
								<p className='form-error-text'>{errors.title.message}</p>
							)}
						</Form.Field>
					</Form.Group>
					<Form.Group grouped className='mb-60'>
						<label html='workshop' className='p-question'>
							Which workshop(s) do you wish to attend?
						</label>
						<Form.Field className='mb-15'>
							<label
								htmlFor='workshop'
								className='checkbox-label checkbox-text lighter-color'>
								<input
									type='checkbox'
									name='workshop'
									value='Introduction to Registry Bootcamp - May 5th, 2022, 4-5:30p ET/1-2:30p PT'
									className={`checkbox-button ${errors.workshop}`}
									{...register('workshop', { required: 'Workshop is required.' })}
								/>
								Introduction to Registry Bootcamp - May 5th, 2022, 4-5:30p ET/1-2:30p PT
							</label>
						</Form.Field>
						<Form.Field className='mb-15'>
							<label
								htmlFor='workshop'
								className='checkbox-label checkbox-text lighter-color'>
								<input
									type='checkbox'
									name='workshop'
									value='Introduction to Registry Bootcamp - June 6th, 2022, 12-1:30p ET/9-10:30a PT'
									className={`checkbox-button ${errors.workshop}`}
									{...register('workshop', { required: 'Workshop is required.' })}
								/>
								Introduction to Registry Bootcamp - June 6th, 2022, 12-1:30p ET/9-10:30a
								PT
							</label>
						</Form.Field>
						<Form.Field className='mb-15'>
							<label
								htmlFor='workshop'
								className='checkbox-label checkbox-text lighter-color'>
								<input
									type='checkbox'
									name='workshop'
									value='Workshop: Registries as relationship - June 13th, 2022, 1-5p ET/10a-2p PT'
									className={`checkbox-button ${errors.workshop}`}
									{...register('workshop', { required: 'Workshop is required.' })}
								/>
								Workshop: Registries as relationship - June 13th, 2022, 1-5p ET/10a-2p
								PT
							</label>
						</Form.Field>
						<Form.Field className='mb-15'>
							<label
								htmlFor='workshop'
								className='checkbox-label checkbox-text lighter-color'>
								<input
									type='checkbox'
									name='workshop'
									value='Workshop: Who&#8218;s the boss of me? - April 29th, 2022, 3-5p ET/12-2p PT'
									className={`checkbox-button ${errors.workshop}`}
									{...register('workshop', { required: 'Workshop is required.' })}
								/>
								Workshop: Who's the boss of me? - April 29th, 2022, 3-5p ET/12-2p PT
							</label>
						</Form.Field>
						<Form.Field className='mb-15'>
							<label
								htmlFor='workshop'
								className='checkbox-label checkbox-text lighter-color'>
								<input
									type='checkbox'
									name='workshop'
									value='Workshop: Who&#8218;s the boss of me? - June 27th, 2022, 12-2p ET/9-11a PT'
									className={`checkbox-button ${errors.workshop}`}
									{...register('workshop', { required: 'Workshop is required.' })}
								/>
								Workshop: Who's the boss of me? - June 27th, 2022, 12-2p ET/9-11a PT
							</label>
						</Form.Field>
						<Form.Field className='mb-15'>
							<label
								htmlFor='workshop'
								className='checkbox-label checkbox-text lighter-color'>
								<input
									type='checkbox'
									name='workshop'
									value='Workshop: Let&#8218;s get technical! - May 11, 2022, 11a-3p ET/8a-12p PT'
									className={`checkbox-button ${errors.workshop}`}
									{...register('workshop', { required: 'Workshop is required.' })}
								/>
								Workshop: Let's get technical! - May 11, 2022, 11a-3p ET/8a-12p PT
							</label>
						</Form.Field>
						<Form.Field>
							<label
								htmlFor='workshop'
								className='checkbox-label checkbox-text lighter-color'>
								<input
									type='checkbox'
									name='workshop'
									value='Workshop: Let&#8218;s get technical! - July 13th, 2022, 1-5p ET/10a-2p PT'
									className={`checkbox-button ${errors.workshop}`}
									{...register('workshop', { required: 'Workshop is required.' })}
								/>
								Workshop: Let's get technical! - July 13th, 2022, 1-5p ET/10a-2p PT
							</label>
							{errors.workshop && (
								<p className='form-error-text'>{errors.workshop.message}</p>
							)}
						</Form.Field>
					</Form.Group>
					<Form.Field className='mb-60'>
						<label htmlFor='participantContact' className='p-question'>
							Please provide the name, email, and title of any other people from your
							organization who will participate in the workshop(s). If you are
							interested in multiple workshops, please be sure to specify which
							workshops these individuals will attend.
						</label>
						<textarea
							required
							type='text'
							name='participantContact'
							style={{ height: '120px' }}
							className={`form-frame p-text lighter-color ${
								errors.participantContact && 'form-frame-error'
							}`}
							placeholder='Your message'
							{...register('participantContact', {
								required: 'Participant contact information is required.',
							})}
						/>
						{errors.participantContact && (
							<p className='form-error-text'>{errors.participantContact.message}</p>
						)}
					</Form.Field>
					<Form.Field className='mb-60'>
						<label htmlFor='orgMission' className='p-question'>
							Please provide a brief description of your organization’s mission.
						</label>
						<textarea
							required
							type='text'
							name='orgMission'
							style={{ height: '120px' }}
							className={`form-frame p-text lighter-color ${
								errors.orgMission && 'form-frame-error'
							}`}
							placeholder='Your message'
							{...register('orgMission', {
								required: 'Mission description is required.',
							})}
						/>
						{errors.orgMission && (
							<p className='form-error-text'>{errors.orgMission.message}</p>
						)}
					</Form.Field>
					<Form.Field className='mb-60'>
						<label htmlFor='community' className='p-question'>
							Please provide a brief description of the community you serve.
						</label>
						<textarea
							required
							type='text'
							name='community'
							style={{ height: '120px' }}
							className={`form-frame p-text lighter-color ${
								errors.community && 'form-frame-error'
							}`}
							placeholder='Your message'
							{...register('community', {
								required: 'Community description is required.',
							})}
						/>
						{errors.community && (
							<p className='form-error-text'>{errors.community.message}</p>
						)}
					</Form.Field>
					<Form.Field className='mb-60'>
						<label htmlFor='focus' className='p-question'>
							Please provide a brief description of the specific topic of interest,
							disease, condition, quality of life, or study on which you are focusing
							your registry or study.
						</label>
						<textarea
							required
							type='text'
							name='focus'
							style={{ height: '120px' }}
							className={`form-frame p-text lighter-color ${
								errors.focus && 'form-frame-error'
							}`}
							placeholder='Your message'
							{...register('focus', { required: 'Focus description is required.' })}
						/>
						{errors.focus && (
							<p className='form-error-text'>{errors.focus.message}</p>
						)}
					</Form.Field>
					<Form.Field className='mb-60'>
						<label htmlFor='process' className='p-question'>
							Please provide a brief description of where you are currently in the
							process of launching your registry or study. Are there specific topics or
							areas of interest that you would like to explore with us?
						</label>
						<textarea
							required
							type='text'
							name='process'
							style={{ height: '120px' }}
							className={`form-frame p-text lighter-color ${
								errors.process && 'form-frame-error'
							}`}
							placeholder='Your message'
							{...register('process', {
								required: 'Process description is required.',
							})}
						/>
						{errors.process && (
							<p className='form-error-text'>{errors.process.message}</p>
						)}
					</Form.Field>
					<Form.Field className='mb-80'>
						<label htmlFor='past' className='p-question'>
							Have had a registry or conducted a study in the past? If yes, please
							provide a short description.
						</label>
						<textarea
							required
							type='text'
							name='past'
							style={{ height: '120px' }}
							className={`form-frame p-text lighter-color ${
								errors.past && 'form-frame-error'
							}`}
							placeholder='Your message'
							{...register('past', { required: 'Past description is required.' })}
						/>
						{errors.past && <p className='form-error-text'>{errors.past.message}</p>}
					</Form.Field>
					<Form.Group grouped className='mb-60'>
						<label html='shareContact' className='p-question'>
							Are you willing to share your contact information with other workshop
							participants?
						</label>
						<Form.Field className='mb-15'>
							<label
								htmlFor='shareContact'
								className='radio-label radio-text lighter-color'>
								<input
									required
									type='radio'
									name='shareContact'
									value='Yes'
									className={`radio-button ${errors.shareContact}`}
									{...register('shareContact', {
										required: 'Contact permission is required.',
									})}
								/>
								Yes
							</label>
						</Form.Field>
						<Form.Field>
							<label
								htmlFor='shareContact'
								className='radio-label radio-text lighter-color'>
								<input
									required
									type='radio'
									name='shareContact'
									value='Not at this time'
									className={`radio-button ${errors.shareContact}`}
									{...register('shareContact', {
										required: 'Contact permission is required.',
									})}
								/>
								Not at this time
							</label>
							{errors.shareContact && (
								<p className='form-error-text'>{errors.shareContact.message}</p>
							)}
						</Form.Field>
					</Form.Group>
					<Form.Group grouped className='mb-60'>
						<label html='learnMore' className='p-question'>
							Are you interested in learning more about Genetic Alliance’s registry
							solution, the Promise for Engaging Everyone Responsibly (PEER)?
						</label>
						<Form.Field className='mb-15'>
							<label
								htmlFor='learnMore'
								className='radio-label radio-text lighter-color'>
								<input
									required
									type='radio'
									name='learnMore'
									value='Yes'
									className={`radio-button ${errors.learnMore}`}
									{...register('learnMore', {
										required: 'Interest choice is required.',
									})}
								/>
								Yes
							</label>
						</Form.Field>
						<Form.Field>
							<label
								htmlFor='learnMore'
								className='radio-label radio-text lighter-color'>
								<input
									required
									type='radio'
									name='learnMore'
									value='Not at this time'
									className={`radio-button ${errors.learnMore}`}
									{...register('learnMore', {
										required: 'Interest choice is required.',
									})}
								/>
								Not at this time
							</label>
							{errors.learnMore && (
								<p className='form-error-text'>{errors.learnMore.message}</p>
							)}
						</Form.Field>
					</Form.Group>
					<Form.Group grouped className='mb-60'>
						<label htmlFor='updates' className='p-question'>
							Would you like to sign up to receive updates from Genetic Alliance?
						</label>
						<Form.Field className='mb-15'>
							<label
								htmlFor='updates'
								className='checkbox-label checkbox-text lighter-color'>
								<input
									type='checkbox'
									name='updates'
									value='Genetic Alliance Weekly Bulletin'
									className='checkbox-button'
									{...register('updates', { required: false })}
								/>
								Genetic Alliance Weekly Bulletin
							</label>
						</Form.Field>
						<Form.Field>
							<label
								htmlFor='updates'
								className='checkbox-label checkbox-text lighter-color'>
								<input
									type='checkbox'
									name='updates'
									value='Genetic Alliance Announcements'
									className='checkbox-button'
									{...register('updates', { required: false })}
								/>
								Genetic Alliance Announcements
							</label>
						</Form.Field>
					</Form.Group>

					{/* <Form.Field className='mb-40'>
                    <div
                        className='g-recaptcha'
                        data-sitekey='6LdVkTgdAAAAAM9nHQfZXPpf-xwrofJT7-JVOLQu'></div>
                </Form.Field> */}
					<div>
						<Button
							type='submit'
							className='button-blue button-text mb-40'
							style={{ width: '100%' }}>
							Submit
						</Button>
						<p className={showMessage ? 'center' : 'center hide'}>
							Thank you for signing up for a Registry Bootcamp workshop! We will be in
							touch with you shortly to share additional event information.
						</p>
					</div>
				</Form>
			</div>
		</>
	)
}

export default EventRegistration
