import React from 'react'
import { Link } from 'react-router-dom'
import { Grid, Button } from 'semantic-ui-react'

function Events() {
	return (
		<>
			<div className='content-container mt-220-180 mb-220-120'>
				<h1>Join us live for a deep dive into the world of registries</h1>
				<p className='lighter-color'>
					Whether you’re just getting started or you’re well on your way to launching
					your registry, the staff at Genetic Alliance are here to help! Join us live
					for an introduction to Registry Bootcamp and for deep dives into
					engagement, data collection, and governance. From time to time, we also
					offer specialized workshops for communities launching a registry using the{' '}
					<a 	className='link-black'
						href='https://www.geneticalliance.org/peer'
						rel='noreferrer'
						target='_blank'
					>
					Promise for Engaging Everyone Responsibly (PEER)
					</a>.
				</p>
				<p className='lighter-color'>
					Workshops are offered online through Zoom. For a list of upcoming
					workshops, please review upcoming events, below. To register for a
					workshop, please fill in{' '}
					<Link to='/event-registration' target='_blank' className='link-black'>
						this form
					</Link>
					. To request a private event for your network, fill in our{' '}
					<Link to='/contact-us' target='_blank' className='link-black'>
						contact form
					</Link>
					.
				</p>
				<p className='lighter-color'>
					Workshops are free of charge to not-for-profit advocacy organizations and
					community groups.
				</p>
				<hr className='border-neutral-1000 mt-40 mb-40' />
				<h1 className='mb-40'>Upcoming events</h1>
				<h2>Introduction to Registry Bootcamp</h2>
				<p className='lighter-color'>
					Join us for an introduction to registries - what they are, what they’re
					for, and why they’re important - and a tour of the Registry Bootcamp
					website! We’ll give you an overview of the different sections of the site
					and share some tips and tricks for using them. This event is perfect for
					those just getting started with their registry or those new to using the
					Registry Bootcamp resources.
				</p>
				<p className='lighter-color'>
					Attending an introduction is required before attending any other Registry
					Bootcamp events. Please{' '} 
					<Link
						className='link-black'
						to='/contact-us'
						target='_blank'
					>
					contact us
					</Link>
					{' '}if you would like to register for
					another event but cannot attend an introduction before that event.
				</p>
				<Grid stackable className='mt-40 mb-40'>
					<Grid.Row columns={3}>
						<Grid.Column className='text-blue-500' width={4}>
							<p>
								<span className='p-bold'>May 5th, 2022</span>
								<br />
								<span className='lighter-color'>
									4-5:30p ET/
									<br />
									1-2:30p PT
								</span>
								<br />
								<span className='lighter-color p-italic'>
									Registration closes at 12p ET/9a PT on May 4th, 2022.
								</span>
							</p>
						</Grid.Column>
						<Grid.Column className='text-blue-500' width={4}>
							<p>
								<span className='p-bold'>June 6th, 2022</span>
								<br />
								<span className='lighter-color'>
									12-1:30p ET/
									<br />
									9-10:30a PT
								</span>
								<br />
								<span className='lighter-color p-italic'>
									Registration closes at 12p ET/9a PT on June 3rd, 2022.
								</span>
							</p>
						</Grid.Column>
						<Grid.Column className='text-blue-500' width={4}>
							<p>
								<span className='p-bold'>July 7th, 2022</span>
								<br />
								<span className='lighter-color'>
									4-5:30p ET/
									<br />
									1-2:30p PT
								</span>
								<br />
								<span className='lighter-color p-italic'>
									Registration closes at 12p ET/9a PT on July 6th, 2022.
								</span>
							</p>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Link to='/event-registration' target='_blank'>
					<Button className='button-blue button-text width-100'>Register</Button>
				</Link>
				<hr className='border-neutral-100 mt-40 mb-40' />
				<h2>Workshop: Registries as relationship</h2>
				<p className='lighter-color'>
					A registry is more than a tool for doing research with your community.
					Registries offer an opportunity to build and strengthen your relationship
					with your community members and your community members’ relationship with
					you and the research process. This workshop will explore ways to approach
					“registries as relationship” at each stage of your registry’s lifecycle:
					identification and prioritization of research topics; research planning,
					design, and proposal development; execution of research; data analysis; and
					dissemination of results. We will explore community engagement strategies
					at each stage of this cycle, with an emphasis on techniques that are most
					relevant to workshop attendees’ current activities. Come ready to ask
					questions, share experiences, and explore!
				</p>
				<p className='lighter-color'>To attend this workshop, you must:</p>
				<ul>
					<li className='lighter-color'>
						Have previously attended an Introduction to Registry Bootcamp event. If
						this is a challenge for you, please{' '}
						<Link to='/contact-us' className='link-black' target='_blank'>
							contact us
						</Link>
						.
					</li>
					<li className='lighter-color'>
						Have identified the stakeholder groups you will be working with to launch
						your registry, as discussed under “Why are you starting a registry?”
					</li>
					<li className='lighter-color'>
						Be actively working on launching your registry, recruiting for your
						registry, or launching a new study.
					</li>
				</ul>
				<Grid stackable className='mt-40 mb-40'>
					<Grid.Row columns={1}>
						<Grid.Column className='text-blue-500' width={4}>
							<p>
								<span className='p-bold'>June 13th, 2022</span>
								<br />
								<span className='lighter-color'>
									1-5p ET/
									<br />
									10a-2p PT
								</span>
								<br />
								<span className='lighter-color p-italic'>
									Registration closes at 12p ET/9a PT on June 6th, 2022.
								</span>
							</p>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Link to='/event-registration' target='_blank'>
					<Button className='button-blue button-text width-100'>Register</Button>
				</Link>
				<hr className='border-neutral-100 mt-40 mb-40' />
				<h2>Workshop: Who’s the boss of me?</h2>
				<p className='lighter-color'>
					Establishing appropriate oversight is an important aspect of registry
					planning. In this workshop, we’ll explore various approaches to registry
					governance, and the ways that these intersect with the development of a
					sustainable registry program. Areas of exploration include the intersection
					of governance with research agendas, funding, data, ethics, science, and
					dissemination. We’ll also take a look at the ways that platform governance
					models inform registry governance. Our discussion will draw heavily from
					elements of the community engagement tactics discussed elsewhere on this
					site, and in the “Registries as relationship” workshop.
				</p>
				<p className='lighter-color'>To attend this workshop, you must:</p>
				<ul>
					<li className='lighter-color'>
						Have previously attended an Introduction to Registry Bootcamp event. If
						this is a challenge for you, please{' '}
						<Link to='/contact-us' className='link-black' target='_blank'>
							contact us
						</Link>
						.
					</li>
					<li className='lighter-color'>
						Have identified the stakeholder groups you will be working with to launch
						your registry, as discussed under “Why are you starting a registry?”
					</li>
					<li className='lighter-color'>
						Be actively working on launching your registry, recruiting for your
						registry, or launching a new study.
					</li>
					<li className='lighter-color'>
						[RECOMMENDED] Have previously attended a “Registries as relationship”
						workshop. If this is a challenge for you, please{' '}
						<Link to='/contact-us' className='link-black' target='_blank'>
							contact us
						</Link>
						.
					</li>
				</ul>
				<Grid stackable className='mt-40 mb-40'>
					<Grid.Row columns={2}>
						<Grid.Column className='text-blue-500' width={4}>
							<p>
								<span className='p-bold'>April 29th, 2022</span>
								<br />
								<span className='lighter-color'>
									3-5p ET/
									<br />
									12-2p PT
								</span>
								<br />
								<span className='lighter-color p-italic'>
									Registration closes at 12p ET/9a PT on April 22nd, 2022.
								</span>
							</p>
						</Grid.Column>
						<Grid.Column className='text-blue-500' width={4}>
							<p>
								<span className='p-bold'>June 27th, 2022</span>
								<br />
								<span className='lighter-color'>
									12-2p ET/
									<br />
									9-11a PT
								</span>
								<br />
								<span className='lighter-color p-italic'>
									Registration closes at 12p ET/9a PT on June 20th, 2022.
								</span>
							</p>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Link to='/event-registration' target='_blank'>
					<Button className='button-blue button-text width-100'>Register</Button>
				</Link>
				<hr className='border-neutral-100 mt-40 mb-40' />
				<h2>Workshop: Let’s get technical!</h2>
				<p className='lighter-color'>
					There’s a reason for the saying, “the devil’s in the details.” The
					technical aspects of planning and launching a registry can seem daunting at
					first! However, with the proper approach, you can get a registry up and
					running in no time.
				</p>
				<p className='lighter-color'>
					This workshop explores the importance of planning the technical aspects of
					your registry with the support of your stakeholders. It also emphasizes the
					importance of iterative project planning to keep your goals manageable.
					Workshop content will vary depending on attendees' interests and includes
					both lecture-style information and group discussions with facilitators and
					other participants. Topics covered may include:
				</p>
				<ul>
					<li className='lighter-color'>Selecting data types for your registry</li>
					<li className='lighter-color'>Survey design</li>
					<li className='lighter-color'>
						Considerations for choosing a registry platform
					</li>
					<li className='lighter-color'>Research protocol development</li>
					<li className='lighter-color'>Relevant regulatory considerations</li>
					<li className='lighter-color'>
						Process of ethics approval by an institutional review board or ethics
						committee
					</li>
				</ul>
				<p className='lighter-color'>
					Let us know about the topics that interest you in the registration form!
				</p>
				<p className='lighter-color'>To attend this workshop, you must:</p>
				<ul>
					<li className='lighter-color'>
						Have previously attended an Introduction to Registry Bootcamp event. If
						this is a challenge for you, please{' '}
						<Link to='/contact-us' className='link-black' target='_blank'>
							contact us
						</Link>
						.
					</li>
					<li className='lighter-color'>
						Have identified the research topics your registry will address (or be in
						the process of narrowing this down).
					</li>
					<li className='lighter-color'>
						Be actively working on launching your registry, recruiting for your
						registry, or launching a new study.
					</li>
				</ul>
				<Grid stackable className='mt-40 mb-40'>
					<Grid.Row columns={2}>
						<Grid.Column className='text-blue-500' width={4}>
							<p>
								<span className='p-bold'>May 11, 2022</span>
								<br />
								<span className='lighter-color'>
									11a-3p ET/
									<br />
									8a-12p PT
								</span>
								<br />
								<span className='lighter-color p-italic'>
									Registration closes at 12p ET/9a PT on May 4th, 2022.
								</span>
							</p>
						</Grid.Column>
						<Grid.Column className='text-blue-500' width={4}>
							<p>
								<span className='p-bold'>July 13th, 2022</span>
								<br />
								<span className='lighter-color'>
									1-5p ET/
									<br />
									10a-2p PT
								</span>
								<br />
								<span className='lighter-color p-italic'>
									Registration closes at 12p ET/9a PT on July 6th, 2022.
								</span>
							</p>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Link to='/event-registration' target='_blank'>
					<Button className='button-blue button-text width-100'>Register</Button>
				</Link>
				<hr className='border-neutral-100 mt-40 mb-40' />
				<h2>Special series: Launching your PEER registry</h2>
				<p className='lighter-color'>
					You’ve engaged your community, you’ve identified your research topics, and
					you’ve chosen your registry platform… now you’re ready to implement! Join
					us for this special event designed to fast-track your registry’s launch on
					the{' '}
					<a
						className='link-black'
						href='https://www.lunadna.com'
						target='_blank'
						rel='noreferrer'>
						Luna
					</a>{' '}
					platform. We’ll take you through the process step-by-step. By the end of
					this event, you’ll be well on your way to submitting your research
					protocol, building your surveys, and launching your registry to your
					community.
				</p>
				<p className='lighter-color'>To attend this workshop, you must:</p>
				<ul>
					<li className='lighter-color'>
						[RECOMMENDED] Have previously attended an Introduction to Registry
						Bootcamp event. If this is a challenge for you, please{' '}
						<Link to='/contact-us' className='link-black' target='_blank'>
							contact us
						</Link>
						.
					</li>
					<li className='lighter-color'>
						Be launching your registry using the{' '}
						<a
							className='link-black'
							href='https://www.lunadna.com'
							target='_blank'
							rel='noreferrer'>
							Luna
						</a>{' '}
						platform.
					</li>
					<li className='lighter-color'>
						Be a member of the{' '}
						<a
							className='link-black'
							href='http://www.geneticalliance.org/peer'
							target='_blank'
							rel='noreferrer'>
							Promise for Engaging Everyone Responsibly (PEER)
						</a>
						.
					</li>
				</ul>
				<p className='text-blue-500 p-bold mt-40'>Dates coming soon!</p>
				<hr className='border-neutral-100 mt-40 mb-40' />
			</div>
		</>
	)
}

export default Events
