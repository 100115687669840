import React from 'react'
import { Image, Grid } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import './About.css'

function About() {
	return (
		<>
			<div className='content-container mt-220-180 mb-220-120'>
				<h2>
					Launching a registry can feel daunting but is manageable with proper
					planning and the appropriate outlook. With the right resources and
					platform, it can even be easy!
				</h2>
				<Image className='mt-40 mb-40' src='/images/FLOWERS-PINK-1.png' centered />
				<p className='lighter-color'>
					Registry Bootcamp offers advocates, advocacy organizations, and other
					community groups the information and strategies needed to launch a
					successful registry. Whether you are looking for an introduction to
					registries, a deep dive into a specific sub-topic, or a practical guide,
					this site has something for you.
				</p>
				<p className='lighter-color'>
					This website will guide you through the steps necessary to design and
					develop a registry for your community. Along the way, you’ll find
					wide-ranging resources to support you in this endeavor.
				</p>
				<p className='lighter-color'>
					We (the folks running this site) believe that the registry planning process
					is best approached as a communal activity. As such, we encourage you to
					explore this site together with your collaborators and to engage with us
					and your peers (other communities launching registries) as you go. We also offer Registry Bootcamp workshops upon request. These workshops offer a chance to dive deep into the content and
					processes shared on this site and simultaneously connect with and learn
					from others. For more information about workshops, please{' '} 
					<Link to='/contact-us' className='link-black' target='_blank'>
					contact us
					</Link>.
				</p>
				<p className='lighter-color'>
					You can engage with the content on this website linearly or in the order of
					your choosing. You can comb through the site from start to finish or only
					use the pieces that resonate with you. While there is a general arc to the
					process of launching a registry, no two communities will follow the same
					path. Whatever you do, we invite you to explore and enjoy the creative
					process.
				</p>

				<hr className='mt-40 mb-40 border-neutral-100' />

				<h2>About Registry Bootcamp</h2>
				<p className='lighter-color'>
					Registry Bootcamp is a program of{' '}
					<a	
						className='lighter-color link-black'
						href='https://www.geneticalliance.org/'
						target='_blank'
						rel='noreferrer noopener'
					>
					Genetic Alliance
					</a>
					, a 501(c)3 nonprofit
					that engages individuals, families, and communities to transform health.
					The Genetic Alliance staff have more than 25 years’ worth of experience in
					building registries and designing research studies within and alongside
					communities: research from the ground up, not the top down. We have created
					tools and resources to support communities in doing the research that
					matters most to their members, including the{' '}
					<a
						className='lighter-color link-black'
						href='https://geneticalliance.org/biobank'
						rel='noreferrer'
						target='_blank'>
						Genetic Alliance BioBank
					</a>
					, the{' '}
					<a
						className='lighter-color link-black'
						href='https://geneticalliance.org/irb'
						rel='noreferrer'
						target='_blank'>
						Genetic Alliance IRB
					</a>
					, and our registry program,{' '}
					<a
						className='lighter-color link-black'
						href='https://geneticalliance.org/registries/promise-for-engaging-everyone-responsibly'
						rel='noreferrer'
						target='_blank'>
						the Promise for Engaging Everyone Responsibly (PEER)
					</a>
					. As we worked with communities to launch registries through PEER, we saw a
					need for a general guide to establishing and operating a registry. In 2021
					we launched the first series of Registry Bootcamp courses. In an effort to
					make the content even more accessible - both to increased numbers of
					communities and across the variety of timescales at which communities build
					registries - we now offer the course content through a website and ongoing
					workshop series.
				</p>

				<hr className='mt-40 mb-40 border-neutral-100' />

				<p className='lighter-color mb-40'>
					Thank you to the following sponsors, whose support made the development of
					this site possible:
				</p>

				<div>
					<Grid stackable centered>
						<Grid.Row className='mb-40'>
							<Grid.Column>
								<Image centered src='/images/1200px-PTC_Therapeutics_1.png' />
							</Grid.Column>
						</Grid.Row>
						<Grid.Row columns={3} className='mb-15'>
							<Grid.Column>
								<Image centered src='/images/logo-ultragenyx_1.png' />
							</Grid.Column>
							<Grid.Column>
								<Image centered src='/images/download-2_1.png' />
							</Grid.Column>
							<Grid.Column>
								<Image centered src='/images/layer1.png' />
							</Grid.Column>
						</Grid.Row>
						<Grid.Row columns={2} centered>
							<Grid.Column>
								<Image centered src='/images/_20201106_cpub_spark_logo_1.png' className='mr-26' />
							</Grid.Column>
							<Grid.Column>
								<Image centered src='/images/Pfizer_Logo_Black_RGB_1.png' className='ml-26' />
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>
			</div>
		</>
	)
}

export default About
