import React, { useState, useEffect } from 'react'
import { Image, Container } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import './ShortMenu.css'
import { motion } from 'framer-motion'
import { useLocation } from 'react-router-dom'

function ShortMenu() {
	const [registry, setRegistry] = useState(true)
	const [resources, setResources] = useState(false)
	const [aspects, setAspects] = useState(false)
	const [building, setBuilding] = useState(false)
	// const [blank, setBlank] = useState(true)
	const [shortMenuFooter, setShortMenuFooter] = useState(false)

	const location = useLocation()

	useEffect(() => {
		if (location.pathname === '/short-menu') {
			setShortMenuFooter(true)
		} else {
			setShortMenuFooter(false)
		}
	}, [location.pathname])

	const handleAspects = () => {
		// setTimeout(() => { setBlank(false) }, 100)
		if (document.getElementById('imageAspects')) {
			document.getElementById('imageAspects').classList.remove('fade')
			document.getElementById('imageAspects')?.classList.add('show')
		}
		if (resources) {
			// setTimeout(() => { setResources(false) }, 100)
			setResources(false)
		}
		if (registry) {
			// setTimeout(() => { setRegistry(false) }, 100)
			setRegistry(false)
		}
		if (building) {
			// setTimeout(() => { setBuilding(false) }, 100)
			setBuilding(false)
		}
		// setTimeout(() => { setAspects(true) }, 100)
		setAspects(true)
	}

	const handleRegistry = () => {
		// setTimeout(() => { setBlank(false) }, 100)
		if (document.getElementById('imageRegistry')) {
			document.getElementById('imageRegistry').classList.remove('fade')
			document.getElementById('imageRegistry')?.classList.add('show')
		}
		if (resources) {
			// setTimeout(() => { setResources(false) }, 100)
			setResources(false)
		}
		if (aspects) {
			// setTimeout(() => { setAspects(false) }, 100)
			setAspects(false)
		}
		if (building) {
			// setTimeout(() => { setBuilding(false) }, 100)
			setBuilding(false)
		}
		// setTimeout(() => { setRegistry(true) }, 100)
		setRegistry(true)
	}

	const handleResources = () => {
		// setTimeout(() => { setBlank(false) }, 100)
		if (document.getElementById('imageResources')) {
			document.getElementById('imageResources').classList.remove('fade')
			document.getElementById('imageResources')?.classList.add('show')
		}
		if (registry) {
			// setTimeout(() => { setRegistry(false) }, 100)
			setRegistry(false)
		}
		if (aspects) {
			// setTimeout(() => { setAspects(false) }, 100)
			setAspects(false)
		}
		if (building) {
			// setTimeout(() => { setBuilding(false) }, 100)
			setBuilding(false)
		}
		// setTimeout(() => { setResources(true) }, 100)
		setResources(true)
	}

	const handleBuilding = () => {
		// setTimeout(() => { setBlank(false) }, 100)
		if (document.getElementById('imageBuilding')) {
			document.getElementById('imageBuilding').classList.remove('fade')
			document.getElementById('imageBuilding')?.classList.add('show')
		}
		if (registry) {
			// setTimeout(() => { setRegistry(false) }, 100)
			setRegistry(false)
		}
		if (aspects) {
			// setTimeout(() => { setAspects(false) }, 100)
			setAspects(false)
		}
		if (resources) {
			// setTimeout(() => { setResources(false) }, 100)
			setResources(false)
		}
		// setTimeout(() => { setBuilding(true) }, 100)
	// }
	// const handleBlankRegistry = () => {
	// 	if (document.getElementById('imageRegistry')) {
	// 		document.getElementById('imageRegistry').classList.remove('show')
	// 		document.getElementById('imageRegistry').classList.add('fade')
	// 	}
	// 	setTimeout(() => { setRegistry(false) }, 100)
	// 	setTimeout(() => { setBlank(true) }, 100)
	// }
	// const handleBlankResources = () => {
	// 	if (document.getElementById('imageResources')) {
	// 		document.getElementById('imageResources').classList.remove('show')
	// 		document.getElementById('imageResources').classList.add('fade')
	// 	}
	// 	setTimeout(() => { setResources(false) }, 100)
	// 	setTimeout(() => { setBlank(true) }, 100)
	// }
	// const handleBlankAspects = () => {
	// 	if (document.getElementById('imageAspects')) {
	// 		document.getElementById('imageAspects').classList.remove('show')
	// 		document.getElementById('imageAspects').classList.add('fade')
	// 	}
	// 	setTimeout(() => { setAspects(false) }, 100)
	// 	setTimeout(() => { setBlank(true) }, 100)
	// }
	// const handleBlankBuilding = () => {
	// 	if (document.getElementById('imageBuilding')) {
	// 		document.getElementById('imageBuilding').classList.remove('show')
	// 		document.getElementById('imageBuilding').classList.add('fade')
	// 	}
	// 	setTimeout(() => { setBuilding(false) }, 100)
	// 	setTimeout(() => { setBlank(true) }, 100)

		setBuilding(true)
	}
	
	return (
		<Container className={shortMenuFooter ? 'display-short-menu' : ''}>
			<div className={shortMenuFooter ? 'short-menu-page ' : 'short-menu-page'}>
				<div >
					<Link to='/registry-goals' onMouseEnter={handleRegistry} /* onMouseLeave={handleBlankRegistry} */ >
						<h2 className='hr-line registry'>Why are you starting a registry?</h2>
					</Link>
					<Link to='/required-resources' onMouseEnter={handleResources} /* onMouseLeave={handleBlankResources} */ >
						<h2 className='hr-line resources'>Introducing available resources</h2>
					</Link>
					<Link to='/technical-planning' onMouseEnter={handleAspects} /* onMouseLeave={handleBlankAspects} */ >
						<h2 className='hr-line aspects'>Planning the technical aspects</h2>
					</Link>
					<Link to='/community-building' onMouseEnter={handleBuilding} /* onMouseLeave={handleBlankBuilding} */ >
						<h2 className='hr-line building'>Community-building and research</h2>
					</Link>
				</div>
				{/* {blank && (
					<div className='image-container'>
						<Image
							src='/images/white.png'
							size='large'
							width='392'
							centered
							className='blank-picture'
							id='imageBlank'	
						/>
					</div>
				)} */}
				{registry && (
					<motion.div 
						className='image-container'
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ duration: 1 }}

						// initial={{ opacity: 0 }}
						// whileHover={{
						// 	opacity: 1,
						// 	transition: { duration: 1 },
						// }}
						>
						<Image
							src='https://ik.imagekit.io/bootcamp/registry_CCirNgKER.png?ik-sdk-version=javascript-1.4.3&updatedAt=1660765259626'
							size='large'
							width='392'
							centered
							id='imageRegistry'
						/>
					</motion.div>
				)}
				{aspects && (
					<motion.div
						className='image-container'
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ duration: 1 }}>
						<Image
							src='https://ik.imagekit.io/bootcamp/aspects_Lt42gLltEI.png?ik-sdk-version=javascript-1.4.3&updatedAt=1660765259619'
							size='large'
							width='392'
							centered
							id='imageAspects'
						/>
					</motion.div>
				)}
				{resources && (
					<motion.div
						className='image-container'
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ duration: 1 }}>
						<Image
							src='https://ik.imagekit.io/bootcamp/resources_7mjnmd4_j.png?ik-sdk-version=javascript-1.4.3&updatedAt=1660765259557'
							size='large'
							width='392'
							centered
							id='imageResources'
						/>
					</motion.div>
				)}
				{building && (
					<motion.div
						className='image-container'
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ duration: 1 }}>
						<Image
							src='https://ik.imagekit.io/bootcamp/building_ACTcLQcKSQ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1660765259647'
							size='large'
							width='392'
							centered
							id='imageBuilding'
						/>
					</motion.div>
				)}
			</div>
		</Container>
	)
}

export default ShortMenu
