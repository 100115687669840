import React, { useState, useEffect } from 'react'
import { Image, Grid } from 'semantic-ui-react'
import { Accordion, Icon } from 'semantic-ui-react'
import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import { InView } from 'react-intersection-observer'
import { progressIndicator } from '../../components/HelperFunction'


function Resources({data}) {
	const [activeIndex, setActiveIndex] = useState(0)
	const handleAccordion = (e, index) => {
		setActiveIndex(activeIndex === index.index ? -1 : index.index)
	}

	// useEffect(() => {
	// 	setTimeout(() => {
	// 	console.log('data', data)
	// 	console.log('bat', data && data['required-resources#0'] && data['required-resources#0'][0])
	// 	console.log('cat', data && data[0])
	// }, [data])

	const { pathname, hash, key } = useLocation()
	useEffect(() => {
		// if not a hash link, scroll to top
		if (hash === '') {
			window.scrollTo(0, 0)
		}
		// else scroll to id
		else {
			setTimeout(() => {
				const id = hash.replace('#', '')
				const element = document.getElementById(id)
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' })
				}
			}, 0)
		}
	}, [pathname, hash, key])

	// function activateNavigation() {
	// 	const sections = document.querySelectorAll('.section')
	// 	const navContainer = document.createElement('nav')
	// 	const navItems = Array.from(sections).map(section => {
	// 		return `
	// 			<div class="nav-item" data-for-section="${section.id}">
	// 					<a href="#${section.id}" class="nav-link"></a>
	// 					<span class="nav-label">${section.dataset.label}</span>
	// 			</div>
	// 		`
	// 	})

	// 	navContainer.classList.add('nav')
	// 	navContainer.innerHTML = navItems.join('')

	// 	const observer = new IntersectionObserver(
	// 		entries => {
	// 			document.querySelectorAll('.nav-link').forEach(navLink => {
	// 				navLink.classList.remove('nav-link-selected')
	// 			})

	// 			const visibleSection = entries.filter(entry => entry.isIntersecting)[0]

	// 			if (document.querySelector(
	// 						`.nav-item[data-for-section="${visibleSection.target.id}"] .nav-link`
	// 					)) {
	// 				document
	// 					.querySelector(
	// 						`.nav-item[data-for-section="${visibleSection.target.id}"] .nav-link`
	// 					)
	// 					.classList.add('nav-link-selected')
	// 			}
	// 		},
	// 		{ threshold: 0.5 }
	// 	)

	// 	sections.forEach(section => observer.observe(section))

	// 	document.body.appendChild(navContainer)
	// }

	// activateNavigation()

	useEffect(() => {
		progressIndicator()
	}, [])

	return (
		<>
			<nav className='scroll-dot resource-dot'>
				<ul>
					<li>
						<a href='#0' className='dot active' data-scroll='0'>
							<span>Introducing available resources</span>
						</a>
					</li>
					<li>
						<a href='#1' className='dot' data-scroll='1'>
							<span>Key<br /> resources</span>
						</a>
					</li>
					<li>
						<a href='#2' className='dot' data-scroll='2'>
							<span>Thinking<br /> about cost</span>
						</a>
					</li>
					<li>
						<a href='#3' className='dot' data-scroll='3'>
							<span>Funding<br /> your registry</span>
						</a>
					</li>
				</ul>
			</nav>
		<div className='content-container mt-260-190 mb-160-40'>
			<div class='section' id='0'>
				<h1 className='mb-40-30'>{data[8] && data[8]['required-resources#0'][0]}</h1>
				<p className='lighter-color'>
					{data[8] && data[8]['required-resources#0'][1]}
				</p>
				<Image
					src='/images/resources.png'
					size='large'
					width='300'
					height='430'
					centered
				/>
			</div>
			<hr className='mb-80-40 border-neutral' />

			<InView threshold={0.04}>
				{({ ref, inView }) => (
					<motion.div
						ref={ref}
						initial={{ opacity: 0 }}
						animate={inView ? { opacity: 1 } : { opacity: 0 }}
						transition={{ duration: 0.2 }}
						className='section'
						id='1'>
						<h1 className='mb-40-30'>{data[9] && data[9]['required-resources#1'][0]}</h1>
						<p className='lighter-color'>
							{data[9] && data[9]['required-resources#1'][1]}
							<span class='tooltip'>
								<sup>
									{data[9] && data[9]['required-resources#1'][2]}
									<span class='tooltiptext'>
										Adapted from FasterCures' 2016 report,{' '}
										<a
											href='https://milkeninstitute.org/report/expanding-science-patient-input-building-smarter-patient-registries'
											target='_blank'
											rel='noreferrer'>
											"Expanding the Science of Patient Input: Building Smarter Patient
											Registries."
										</a>
									</span>
								</sup>
							</span>{' '}
							{data[9] && data[9]['required-resources#1'][3]}
						</p>

						<Accordion fluid className='mt-88-70 text-red-500'>
							<Accordion.Title
								active={activeIndex === 1}
								index={1}
								onClick={handleAccordion}>
								<h2 className='text-red-500 h2-dropdown'>
								{data[9] && data[9]['required-resources#1'][4]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-red-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 1}>
								<p>
								{data[9] && data[9]['required-resources#1'][5]}
									<a
										className='link-red'
										href='https://www.lunadna.com/'
										rel='noreferrer'
										target='_blank'>
										{data[9] && data[9]['required-resources#1'][6]}
									</a>
									{data[9] && data[9]['required-resources#1'][7]}
									<a
										className='link-red'
										href='https://www.geneticalliance.org/peer'
										rel='noreferrer'
										target='_blank'>
										{data[9] && data[9]['required-resources#1'][8]}
									</a>
									{data[9] && data[9]['required-resources#1'][9]}
								</p>
								<p>
								{data[9] && data[9]['required-resources#1'][10]}
									<a
										className='link-red'
										href='/technical-planning#9'
										rel='noreferrer'
										target='_blank'>
										{data[9] && data[9]['required-resources#1'][11]}
									</a>
									{data[9] && data[9]['required-resources#1'][12]}
									<a
										className='link-red'
										href='https://groups.google.com/g/geneticalliancecommunityforum'
										rel='noreferrer'
										target='_blank'>
										{data[9] && data[9]['required-resources#1'][13]}
									</a>{' '}
									{data[9] && data[9]['required-resources#1'][14]}
								</p>{' '}
							</Accordion.Content>
							<hr className='border-red-200' />
							<Accordion.Title
								active={activeIndex === 2}
								index={2}
								onClick={handleAccordion}>
								<h2 className='text-red-500 h2-dropdown'>
								{data[9] && data[9]['required-resources#1'][15]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-red-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 2}>
								<p>
								{data[9] && data[9]['required-resources#1'][16]}
								</p>
								<p>
								{data[9] && data[9]['required-resources#1'][17]}
									<a
										className='link-red'
										href='https://www.lunadna.com/'
										rel='noreferrer'
										target='_blank'>
										{data[9] && data[9]['required-resources#1'][18]}
									</a>
									{data[9] && data[9]['required-resources#1'][19]}
									<a
										className='link-red'
										href='https://www.geneticalliance.org/peer'
										rel='noreferrer'
										target='_blank'>
										{data[9] && data[9]['required-resources#1'][20]}
									</a>
									{data[9] && data[9]['required-resources#1'][21]}
								</p>
								<p>
								{data[9] && data[9]['required-resources#1'][22]}
								</p>
								<ul>
									<li>{data[9] && data[9]['required-resources#1'][23]}</li>
									<li>
									{data[9] && data[9]['required-resources#1'][24]}
									</li>
									<li>
									{data[9] && data[9]['required-resources#1'][25]}
									</li>
								</ul>
								<p>
								{data[9] && data[9]['required-resources#1'][26]}
									<a
										className='link-red'
										href='/technical-planning#3'
										rel='noreferrer'
										target='_blank'>
										{data[9] && data[9]['required-resources#1'][27]}
									</a>
									{data[9] && data[9]['required-resources#1'][28]}
								</p>
							</Accordion.Content>
							<hr className='border-red-200' />
							<Accordion.Title
								active={activeIndex === 3}
								index={3}
								onClick={handleAccordion}>
								<h2 className='text-red-500 h2-dropdown'>
								{data[9] && data[9]['required-resources#1'][29]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-red-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 3}>
								<p>
								{data[9] && data[9]['required-resources#1'][30]}
								</p>
								<p>
								{data[9] && data[9]['required-resources#1'][31]}
								</p>
								<p>
								{data[9] && data[9]['required-resources#1'][32]}
								</p>
								<p>{data[9] && data[9]['required-resources#1'][33]}</p>
								<ul>
									<li>{data[9] && data[9]['required-resources#1'][34]}</li>
									<li>{data[9] && data[9]['required-resources#1'][35]}</li>
									<li>{data[9] && data[9]['required-resources#1'][36]}</li>
									<li>{data[9] && data[9]['required-resources#1'][37]}</li>
									<li>{data[9] && data[9]['required-resources#1'][38]}</li>
									<li>{data[9] && data[9]['required-resources#1'][39]}</li>
									<li>{data[9] && data[9]['required-resources#1'][40]}</li>
									<li>{data[9] && data[9]['required-resources#1'][41]}</li>
								</ul>
							</Accordion.Content>
							<hr className='border-red-200' />
							<Accordion.Title
								active={activeIndex === 4}
								index={4}
								onClick={handleAccordion}>
								<h2 className='text-red-500 h2-dropdown'>
								{data[9] && data[9]['required-resources#1'][42]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-red-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 4}>
								<p>
								{data[9] && data[9]['required-resources#1'][43]}
								</p>
								<ul>
									<li>{data[9] && data[9]['required-resources#1'][44]}</li>
									<li>{data[9] && data[9]['required-resources#1'][45]}</li>
									<li>{data[9] && data[9]['required-resources#1'][46]}</li>
									<li>{data[9] && data[9]['required-resources#1'][47]}</li>
								</ul>
								<p>
								{data[9] && data[9]['required-resources#1'][48]}
								</p>
								<p>
								{data[9] && data[9]['required-resources#1'][49]}
								</p>
								<p>
								{data[9] && data[9]['required-resources#1'][50]}
								</p>
								<p>
								{data[9] && data[9]['required-resources#1'][51]}
									<a
										className='link-red'
										href='/community-building'
										rel='noreferrer'
										target='_blank'>
										{data[9] && data[9]['required-resources#1'][52]}
									</a>
									{data[9] && data[9]['required-resources#1'][53]}
								</p>
							</Accordion.Content>
							<hr className='border-red-200' />
							<Accordion.Title
								active={activeIndex === 5}
								index={5}
								onClick={handleAccordion}>
								<h2 className='text-red-500 h2-dropdown'>
								{data[9] && data[9]['required-resources#1'][54]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-red-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 5}>
								<p>
								{data[9] && data[9]['required-resources#1'][55]}
								</p>
								<p>
								{data[9] && data[9]['required-resources#1'][56]}
								</p>
								<p>
								{data[9] && data[9]['required-resources#1'][57]}
									<a
										className='link-red'
										href='https://geneticalliance.org/irb'
										rel='noreferrer'
										target='_blank'>
										{data[9] && data[9]['required-resources#1'][58]}
									</a>{' '}
									{data[9] && data[9]['required-resources#1'][59]}
									<a
										className='link-red'
										href='https://www.geneticalliance.org/peer'
										rel='noreferrer'
										target='_blank'>
										{data[9] && data[9]['required-resources#1'][60]}
									</a>
									{data[9] && data[9]['required-resources#1'][61]}
								</p>
								<p>
								{data[9] && data[9]['required-resources#1'][62]}
									<a
										className='link-red'
										href='https://wikiadvocacy.org/Finding_a_Lawyer'
										rel='noreferrer'
										target='_blank'>
										{data[9] && data[9]['required-resources#1'][63]}
									</a>
									{data[9] && data[9]['required-resources#1'][64]}
									<a
										className='link-red'
										href='https://wikiadvocacy.org/Working_with_a_Lawyer'
										rel='noreferrer'
										target='_blank'>
										{data[9] && data[9]['required-resources#1'][65]}
									</a>
									{data[9] && data[9]['required-resources#1'][66]}
								</p>
								<p>
								{data[9] && data[9]['required-resources#1'][67]}
								</p>
							</Accordion.Content>
							<hr className='border-red-200' />
							<Accordion.Title
								active={activeIndex === 6}
								index={6}
								onClick={handleAccordion}>
								<h2 className='text-red-500 h2-dropdown'>
								{data[9] && data[9]['required-resources#1'][68]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-red-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 6}>
								<p>
								{data[9] && data[9]['required-resources#1'][69]}
								</p>
								<p>
								{data[9] && data[9]['required-resources#1'][70]}
								</p>
								<p>
								{data[9] && data[9]['required-resources#1'][71]}
								</p>
								<p>
								{data[9] && data[9]['required-resources#1'][72]}
									<a
										className='link-red'
										href='https://www.ncbi.nlm.nih.gov/books/NBK493603/'
										rel='noreferrer'
										target='_blank'>
										{data[9] && data[9]['required-resources#1'][73]}
									</a>
									{data[9] && data[9]['required-resources#1'][74]}
								</p>
							</Accordion.Content>
						</Accordion>
					</motion.div>
				)}
			</InView>

			<hr className='mt-80-40 mb-80-40 border-neutral' />

			<InView threshold={0.04}>
				{({ ref, inView }) => (
					<motion.div
						ref={ref}
						initial={{ opacity: 0 }}
						animate={inView ? { opacity: 1 } : { opacity: 0 }}
						transition={{ duration: 0.2 }}
						className='section'
						id='2'>
						<h1 className='mb-40-30'>{data[10] && data[10]['required-resources#2'][0]}</h1>
						<p className='lighter-color'>
						{data[10] && data[10]['required-resources#2'][1]}
						</p>

						<Accordion fluid className='mt-88-70 text-red-500'>
							<Accordion.Title
								active={activeIndex === 7}
								index={7}
								onClick={handleAccordion}>
								<h2 className='text-red-500 h2-dropdown'>
								{data[10] && data[10]['required-resources#2'][2]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-red-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 7}>
								<Grid stackable>
									<Grid.Row columns={3}>
										<Grid.Column>
											<p>
												<b>{data[10] && data[10]['required-resources#2'][3]}</b>
											</p>
											<p>
											{data[10] && data[10]['required-resources#2'][4]}
											</p>
										</Grid.Column>
										<Grid.Column>
											<p>
												<b>{data[10] && data[10]['required-resources#2'][5]}</b>
											</p>
											<p>
											{data[10] && data[10]['required-resources#2'][6]}
											</p>
										</Grid.Column>
										<Grid.Column>
											<p>
												<b>{data[10] && data[10]['required-resources#2'][7]}</b>
											</p>
											<p>
											{data[10] && data[10]['required-resources#2'][8]}
											</p>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Accordion.Content>
						</Accordion>
						<p className='lighter-color mt-88-70'>
						{data[10] && data[10]['required-resources#2'][9]}
							<a
								className='lighter-color link-black'
								href='https://www.lunadna.com/'
								rel='noreferrer'
								target='_blank'>
								{data[10] && data[10]['required-resources#2'][10]}
							</a>
							{data[10] && data[10]['required-resources#2'][11]}
							<a
								className='lighter-color link-black'
								href='https://www.geneticalliance.org/peer'
								rel='noreferrer'
								target='_blank'>
								{data[10] && data[10]['required-resources#2'][12]}
							</a>
							{data[10] && data[10]['required-resources#2'][13]}
						</p>
					</motion.div>
				)}
			</InView>

			<hr className='mt-80-40 mb-80-40 border-neutral' />

			<InView threshold={0.04}>
				{({ ref, inView }) => (
					<motion.div
						ref={ref}
						initial={{ opacity: 0 }}
						animate={inView ? { opacity: 1 } : { opacity: 0 }}
						transition={{ duration: 0.2 }}
						className='section'
						id='3'>
						<h1 className='mb-40-30'>{data[11] && data[11]['required-resources#3'][0]}</h1>
						<p className='lighter-color'>
						{data[11] && data[11]['required-resources#3'][1]}
						</p>
						<p className='lighter-color'>
						{data[11] && data[11]['required-resources#3'][2]}
						</p>

						<Accordion fluid className='mt-88-70 text-red-500'>
							<Accordion.Title
								active={activeIndex === 8}
								index={8}
								onClick={handleAccordion}>
								<h2 className='text-red-500 h2-dropdown'>
								{data[11] && data[11]['required-resources#3'][3]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-red-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 8}>
								<p>
								{data[11] && data[11]['required-resources#3'][4]}
								</p>{' '}
							</Accordion.Content>
							<hr className='border-red-200' />
							<Accordion.Title
								active={activeIndex === 9}
								index={9}
								onClick={handleAccordion}>
								<h2 className='text-red-500 h2-dropdown'>
								{data[11] && data[11]['required-resources#3'][5]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-red-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 9}>
								<p>
								{data[11] && data[11]['required-resources#3'][6]}
								</p>{' '}
							</Accordion.Content>
							<hr className='border-red-200' />
							<Accordion.Title
								active={activeIndex === 10}
								index={10}
								onClick={handleAccordion}>
								<h2 className='text-red-500 h2-dropdown'>
								{data[11] && data[11]['required-resources#3'][7]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-red-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 10}>
								<p>
								{data[11] && data[11]['required-resources#3'][8]}
								</p>{' '}
							</Accordion.Content>
							<hr className='border-red-200' />
							<Accordion.Title
								active={activeIndex === 11}
								index={11}
								onClick={handleAccordion}>
								<h2 className='text-red-500 h2-dropdown'>
								{data[11] && data[11]['required-resources#3'][9]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-red-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 11}>
								<p>
								{data[11] && data[11]['required-resources#3'][10]}
								</p>{' '}
							</Accordion.Content>
							<hr className='border-red-200' />
							<Accordion.Title
								active={activeIndex === 12}
								index={12}
								onClick={handleAccordion}>
								<h2 className='text-red-500 h2-dropdown'>
								{data[11] && data[11]['required-resources#3'][11]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-red-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 12}>
								<p>
								{data[11] && data[11]['required-resources#3'][12]}
								</p>
							</Accordion.Content>
							<hr className='border-red-200' />
							<Accordion.Title
								active={activeIndex === 13}
								index={13}
								onClick={handleAccordion}>
								<h2 className='text-red-500 h2-dropdown'>
								{data[11] && data[11]['required-resources#3'][13]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-red-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 13}>
								<p>
								{data[11] && data[11]['required-resources#3'][14]}
								</p>
							</Accordion.Content>
						</Accordion>
						<p className='lighter-color mt-88-70'>
						{data[11] && data[11]['required-resources#3'][15]}{' '}
							<a
								className='lighter-color link-black'
								href='https://wikiadvocacy.org/Main_Page'
								rel='noreferrer'
								target='_blank'>
								{data[11] && data[11]['required-resources#3'][16]}
							</a>
							{data[11] && data[11]['required-resources#3'][17]}
						</p>
						<p className='lighter-color'>
						{data[11] && data[11]['required-resources#3'][18]}
						</p>
						<p className='lighter-color'>
						{data[11] && data[11]['required-resources#3'][19]}
						</p>
						<ul>
							<li className='lighter-color'>
							{data[11] && data[11]['required-resources#3'][20]}
							</li>
							<li className='lighter-color'>{data[11] && data[11]['required-resources#3'][21]}</li>
							<li className='lighter-color'>{data[11] && data[11]['required-resources#3'][22]}</li>
							<li className='lighter-color'>{data[11] && data[11]['required-resources#3'][23]}</li>
							<li className='lighter-color'>{data[11] && data[11]['required-resources#3'][24]}</li>
							<li className='lighter-color'>
							{data[11] && data[11]['required-resources#3'][25]}
							</li>
							<li className='lighter-color'>
							{data[11] && data[11]['required-resources#3'][26]}
							</li>
							<li className='lighter-color'>{data[11] && data[11]['required-resources#3'][27]}</li>
							<li className='lighter-color'>{data[11] && data[11]['required-resources#3'][28]}</li>
							<li className='lighter-color'>
							{data[11] && data[11]['required-resources#3'][29]}
							</li>
							<li className='lighter-color'>{data[11] && data[11]['required-resources#3'][30]}</li>
							<li className='lighter-color'>
							{data[11] && data[11]['required-resources#3'][31]}
							</li>
						</ul>
						<p className='lighter-color'>
						{data[11] && data[11]['required-resources#3'][32]}
						</p>
						<p className='lighter-color'>
						{data[11] && data[11]['required-resources#3'][33]}
							<a
								className='lighter-color link-black'
								href='/technical-planning#9'
								rel='noreferrer'
								target='_blank'>
								{data[11] && data[11]['required-resources#3'][34]}
							</a>
							{data[11] && data[11]['required-resources#3'][35]}
						</p>
					</motion.div>
				)}
			</InView>
			</div>
		</>
	)
}

export default Resources
