import React, { useState, useEffect } from 'react'
import { Image, Button } from 'semantic-ui-react'
import { Accordion, Icon, Grid } from 'semantic-ui-react'
import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import { InView } from 'react-intersection-observer'
import './Building.css'
import { progressIndicator } from '../../components/HelperFunction'

function Building({data}) {
	const [activeIndex, setActiveIndex] = useState(0)
	const handleAccordion = (e, index) => {
		setActiveIndex(activeIndex === index.index ? -1 : index.index)
	}
	const [sliderIndex, setSliderIndex] = useState(0)
	// useEffect(() => {
	// 	console.log('data', data)
	// 	console.log('bat', data && data['community-building#0'] && data['community-building#0'][0])
	// 	console.log('cat', data && data[0])
	// }, [data])
	const { pathname, hash, key } = useLocation()
	useEffect(() => {
		// if not a hash link, scroll to top
		if (hash === '') {
			window.scrollTo(0, 0)
		}
		// else scroll to id
		else {
			setTimeout(() => {
				const id = hash.replace('#', '')
				const element = document.getElementById(id)
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' })
				}
			}, 0)
		}
	}, [pathname, hash, key])

	// const [width, setWidth] = useState(0)
	// const carousel = useRef()

	// useEffect(() => {
	// 	setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth - 74)
	// }, [])

	useEffect(() => {
		progressIndicator()
	}, [])

	const handleLeftSlider = () => {
		const slider = document.querySelector(".slider-building")
		const sliderIndex = parseInt(getComputedStyle(slider).getPropertyValue("--slider-index"))
		if (sliderIndex === 0) {
			return null
		}
		slider.style.setProperty("--slider-index", sliderIndex - 1)
		setSliderIndex(sliderIndex - 1)
	}
	const handleRightSlider = () => {
		const slider = document.querySelector(".slider-building")
		const sliderIndex = parseInt(getComputedStyle(slider).getPropertyValue("--slider-index"))
		if (sliderIndex === 12) {
			return null
		}
		slider.style.setProperty("--slider-index", sliderIndex + 1)
		setSliderIndex(sliderIndex + 1)
	}

	return (
		<>
			<nav className='scroll-dot building-dot'>
				<ul>
					<li>
						<a href='#0' className='dot active' data-scroll='0'>
							<span>Community-building and research</span>
						</a>
					</li>
					<li>
						<a href='#1' className='dot' data-scroll='1'>
							<span>Articulating commitment<br /> to your community</span>
						</a>
					</li>
					<li>
						<a href='#2' className='dot' data-scroll='2'>
							<span>How are you reaching people?</span>
						</a>
					</li>
				</ul>
			</nav>
			<div className='content-container mt-260-190'>
				<div className='section' id='0'>
					<h1 className=' mb-40-30'>{data[12] && data[12]['community-building#0'][0]}</h1>
					<p className='lighter-color'>
					{data[12] && data[12]['community-building#0'][1]}
					</p>
					<Image
						src='/images/building.png'
						size='large'
						width='300'
						height='430'
						centered
							/>
				</div>
			<hr className='mt-80-40 mb-80-40 border-neutral' />

			<InView threshold={0.04}>
				{({ ref, inView }) => (
					<motion.div
						ref={ref}
						initial={{ opacity: 0 }}
						animate={inView ? { opacity: 1 } : { opacity: 0 }}
						transition={{ duration: 0.2 }}
						className='section'
						id='1'>
						<h1 className=' mb-40-30'>
						{data[13] && data[13]['community-building#1'][0]}
						</h1>
						<p className='lighter-color'>
						{data[13] && data[13]['community-building#1'][1]}
						</p>
						<p className='lighter-color'>
						{data[13] && data[13]['community-building#1'][2]}
							<a
								className='lighter-color link-black'
								href='/registry-goals'
								rel='noreferrer'
								target='_blank'>
								{data[13] && data[13]['community-building#1'][3]}
							</a>
							{data[13] && data[13]['community-building#1'][4]}
						</p>
						<Accordion fluid className='mt-88-70 text-purple-500'>
							<Accordion.Title
								active={activeIndex === 1}
								index={1}
								onClick={handleAccordion}>
								<h2 className='text-purple-500 h2-dropdown'>
								{data[13] && data[13]['community-building#1'][5]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-purple-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 1}>
								<p>
								{data[13] && data[13]['community-building#1'][6]}
									<a
										className='link-purple'
										href='https://docs.google.com/document/d/120dxI7CafmXgbflylt3xC_9gBrFl7RMZkXueVJ9NylE/edit?usp=sharing'
										rel='noreferrer'
										target='_blank'>
										{data[13] && data[13]['community-building#1'][7]}
									</a>
									{data[13] && data[13]['community-building#1'][8]}
								</p>{' '}
							</Accordion.Content>
							<hr className='border-purple-200' />
							<Accordion.Title
								active={activeIndex === 2}
								index={2}
								onClick={handleAccordion}>
								<h2 className='text-purple-500 h2-dropdown'>
								{data[13] && data[13]['community-building#1'][9]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-purple-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 2}>
								<p>
								{data[13] && data[13]['community-building#1'][10]}
									<a
										className='link-purple'
										href='https://docs.google.com/document/d/1thofaoT8oRzBPPrm6iYManDw7sNUqYf_Jz3UfgA-tqo/edit?usp=sharing'
										rel='noreferrer'
										target='_blank'>
										{data[13] && data[13]['community-building#1'][11]}
									</a>
									{data[13] && data[13]['community-building#1'][12]}
								</p>
							</Accordion.Content>
							<hr className='border-purple-200' />
							<Accordion.Title
								active={activeIndex === 3}
								index={3}
								onClick={handleAccordion}>
								<h2 className='text-purple-500 h2-dropdown'>
								{data[13] && data[13]['community-building#1'][13]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-purple-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 3}>
								<p>
								{data[13] && data[13]['community-building#1'][14]}
									<a
										className='link-purple'
										href='https://docs.google.com/document/d/1YNKV8Ln5abUXZpZOdtq3RwLERqTAaAO1PoIGZ_ys44M/edit?usp=sharing'
										rel='noreferrer'
										target='_blank'>
										{data[13] && data[13]['community-building#1'][15]}
									</a>
									{data[13] && data[13]['community-building#1'][16]}
								</p>
							</Accordion.Content>
						</Accordion>
					</motion.div>
				)}
			</InView>

			<hr className='mt-80-40 mb-80-40 border-neutral' />
			<div
				initial={{ opacity: 1 }}
				transition={{ duration: 0.2 }}
				className='section'
				id='2'
			>		
			{/* <InView threshold={0.04}> */}
				{/* {({ ref, inView }) => ( */}
					{/* <motion.div */}
						{/* ref={ref}
						initial={{ opacity: 1 }}
						animate={inView ? { opacity: 1 } : { opacity: 0 }}
						transition={{ duration: 0.2 }}
						className='section' */}
						{/* id='2'> */}
						<h1 className=' mb-40-30'>{data[14] && data[14]['community-building#2'][0]}</h1>
						<p className='lighter-color'>
						{data[14] && data[14]['community-building#2'][1]}
						</p>

						<h2 className='mt-40-30 mb-40-30' id='2.1'>
						{data[14] && data[14]['community-building#2'][2]}
						</h2>
						<p className='lighter-color'>
						{data[14] && data[14]['community-building#2'][3]}
							<a
								className='lighter-color link-black'
								href='https://www.geneticalliance.org/irb'
								rel='noreferrer'
								target='_blank'>
								{data[14] && data[14]['community-building#2'][4]}
							</a>
							{data[14] && data[14]['community-building#2'][5]}
						</p>
						<p className='lighter-color'>
						{data[14] && data[14]['community-building#2'][6]}
						</p>
						<Accordion fluid className='mt-88-70 text-purple-500'>
							<Accordion.Title
								active={activeIndex === 4}
								index={4}
								onClick={handleAccordion}>
								<h2 className='text-purple-500 h2-dropdown'>
								{data[14] && data[14]['community-building#2'][7]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-purple-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 4}>
								<p>
									{data[14] && data[14]['community-building#2'][8]}
								</p>
								<p>
									<b>{data[14] && data[14]['community-building#2'][9]}</b>{data[14] && data[14]['community-building#2'][10]}
									<a
										className='link-purple'
										href='https://groups.google.com/g/geneticalliancecommunityforum'
										rel='noreferrer'
										target='_blank'>
										{data[14] && data[14]['community-building#2'][11]}
									</a>{' '}
									{data[14] && data[14]['community-building#2'][12]}
								</p>
								<p>
									<b>{data[14] && data[14]['community-building#2'][13]}</b>{data[14] && data[14]['community-building#2'][14]}
								</p>
								<p>
									<b>{data[14] && data[14]['community-building#2'][15]}</b>{data[14] && data[14]['community-building#2'][16]}
								</p>{' '}
							</Accordion.Content>
							<hr className='border-purple-200' />
							<Accordion.Title
								active={activeIndex === 5}
								index={5}
								onClick={handleAccordion}>
								<h2 className='text-purple-500 h2-dropdown'>
								{data[14] && data[14]['community-building#2'][17]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-purple-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 5}>
								<p>
								{data[14] && data[14]['community-building#2'][18]}
								</p>
								<p>
									<b>{data[14] && data[14]['community-building#2'][19]}</b>{data[14] && data[14]['community-building#2'][20]}
								</p>
								<p>
									<b>
									{data[14] && data[14]['community-building#2'][21]}
									</b>{' '}
									{data[14] && data[14]['community-building#2'][22]}
								</p>
							</Accordion.Content>
						</Accordion>
						<p className='lighter-color mt-88-70'>
						{data[14] && data[14]['community-building#2'][23]}
						</p>
						</div>
			</div>

			<div>
						<div
							// ref={carousel}
							className='carousel-numbers hide-mobile'
							// whileTap={{ cursor: 'grabbing' }}
							>
							<button className={`handle-building left-handle-building ${sliderIndex === 0 ? 'disable-handle-building' : ''}`} onClick={handleLeftSlider}>
							<div className="text-building"><h2><Icon name='chevron left' className={`handle-color-building ${sliderIndex === 0 ? 'disable-handle-color-building' : ''}`} onClick={handleLeftSlider} /></h2></div>
							</button>
							<div
								// drag='x'
								// dragConstraints={{ right: 0, left: -width }}
								className='inner-carousel-numbers slider-building'>
								<div className='item'>
									<img
										src='/images/1-purple.svg'
										alt='A strong value proposition and clear ask'
									/>
									<div className='text-purple-500'>
										<h2 className='text-purple-500 '>
											A strong value proposition and clear ask
										</h2>
										<p>
											Why are your participants doing this? What exactly do you want them
											to do?
										</p>
									</div>
								</div>
								{/* <img className="next-carousel-button" src='/images/next-carousel-button.png' height="56" alt=""  /> */}
								<div className='item'>
									<img
										src='/images/2-purple.svg'
										alt='An increased and intentional social media presence'
									/>
									<div className='text-purple-500'>
										<h2 className='text-purple-500 '>
											An increased and intentional social media presence
										</h2>
										<p>
											Think carefully about what platforms are the right fit for your
											community. Where do your community members gather already?
										</p>
									</div>
								</div>
								<div className='item'>
									<img src='/images/3-purple.svg' alt='A list of potential partners' />
									<div className='text-purple-500'>
										<h2 className='text-purple-500 '>A list of potential partners</h2>
										<p>
											What outreach partnerships do you hope to build further as you
											continue to develop your registry?
										</p>
									</div>
								</div>
								<div className='item'>
									<img src='/images/4-purple.svg' alt='Ready channels' />
									<div className='text-purple-500'>
										<h2 className='text-purple-500 '>Ready channels</h2>
										<p>Include mailing lists, social media platforms, and websites.</p>
									</div>
								</div>
								<div className='item'>
									<img
										src='/images/5-purple.svg'
										alt='Substantial communities of partnership and alignment'
									/>
									<div className='text-purple-500'>
										<h2 className='text-purple-500 '>
											Substantial communities of partnership and alignment
										</h2>
										<p>
											Where do you already have strong connections that will support you in
											spreading the word?
										</p>
									</div>
								</div>
								<div className='item'>
									<img src='/images/6-purple.svg' alt='Media partnerships' />
									<div className='text-purple-500'>
										<h2 className='text-purple-500 '>Media partnerships</h2>
										<p>
											Be ready to spread the word about what you are doing to the world at
											large. Doing so may help you bring in new participants and
											collaborators.
										</p>
									</div>
								</div>
								<div className='item'>
									<img src='/images/7-purple.svg' alt='Trained champions' />
									<div className='text-purple-500'>
										<h2 className='text-purple-500 '>Trained champions</h2>
										<p>
											Who in your community will speak out on behalf of your research
											initiative?
										</p>
									</div>
								</div>
							</div>
							<button className={`handle-building right-handle-building ${sliderIndex === 12 ? 'disable-handle-building' : ''}`}onClick={handleRightSlider}><div className="text-building"><h2><Icon name='chevron right' className={`handle-color-building ${sliderIndex === 12 ? 'disable-handle-color-building' : ''}`} onClick={handleRightSlider} /></h2></div>
								</button>
						</div>
			</div>	

			<div className='content-container mb-160-40'>
						<Accordion fluid className='mt-40 mb-40 text-purple-500 hide-desktop'>
							<Accordion.Title
								active={activeIndex === 6}
								index={6}
								onClick={handleAccordion}>
								<h2 className='text-purple-500 h2-dropdown'>
								{data[14] && data[14]['community-building#2'][24]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-purple-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 6}>
								<p>
								{data[14] && data[14]['community-building#2'][25]}
								</p>{' '}
							</Accordion.Content>
							<hr className='border-purple-200' />
							<Accordion.Title
								active={activeIndex === 7}
								index={7}
								onClick={handleAccordion}>
								<h2 className='text-purple-500 h2-dropdown'>
								{data[14] && data[14]['community-building#2'][26]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-purple-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 7}>
								<p>
								{data[14] && data[14]['community-building#2'][27]}
								</p>
							</Accordion.Content>
							<hr className='border-purple-200' />
							<Accordion.Title
								active={activeIndex === 8}
								index={8}
								onClick={handleAccordion}>
								<h2 className='text-purple-500 h2-dropdown'>
								{data[14] && data[14]['community-building#2'][28]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-purple-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 8}>
								<p>
								{data[14] && data[14]['community-building#2'][29]}
								</p>
							</Accordion.Content>
							<hr className='border-purple-200' />
							<Accordion.Title
								active={activeIndex === 9}
								index={9}
								onClick={handleAccordion}>
								<h2 className='text-purple-500 h2-dropdown'>
								{data[14] && data[14]['community-building#2'][30]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-purple-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 9}>
								<p>{data[14] && data[14]['community-building#2'][31]}</p>
							</Accordion.Content>
							<hr className='border-purple-200' />
							<Accordion.Title
								active={activeIndex === 10}
								index={10}
								onClick={handleAccordion}>
								<h2 className='text-purple-500 h2-dropdown'>
								{data[14] && data[14]['community-building#2'][32]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-purple-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 10}>
								<p>
								{data[14] && data[14]['community-building#2'][33]}
								</p>
							</Accordion.Content>
							<hr className='border-purple-200' />
							<Accordion.Title
								active={activeIndex === 11}
								index={11}
								onClick={handleAccordion}>
								<h2 className='text-purple-500 h2-dropdown'>
								{data[14] && data[14]['community-building#2'][34]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-purple-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 11}>
								<p>
								{data[14] && data[14]['community-building#2'][35]}
								</p>
							</Accordion.Content>
							<hr className='border-purple-200' />
							<Accordion.Title
								active={activeIndex === 12}
								index={12}
								onClick={handleAccordion}>
								<h2 className='text-purple-500 h2-dropdown'>
								{data[14] && data[14]['community-building#2'][36]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-purple-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 12}>
								<p>
								{data[14] && data[14]['community-building#2'][37]}
								</p>
							</Accordion.Content>
						</Accordion>
						<p className='lighter-color'>
						{data[14] && data[14]['community-building#2'][38]}
						</p>
						<Accordion fluid className='mt-88-70 text-purple-500'>
							<Accordion.Title
								active={activeIndex === 13}
								index={13}
								onClick={handleAccordion}>
								<h2 className='text-purple-500 h2-dropdown'>
								{data[14] && data[14]['community-building#2'][39]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-purple-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 13}>
								<Grid stackable>
									<Grid.Row columns={3}>
										<Grid.Column>
											<p>
												<b>{data[14] && data[14]['community-building#2'][40]}</b>
											</p>
											<p>
											{data[14] && data[14]['community-building#2'][41]}
											</p>
										</Grid.Column>
										<Grid.Column>
											<p>
												<b>{data[14] && data[14]['community-building#2'][42]}</b>
											</p>
											<p>
											{data[14] && data[14]['community-building#2'][43]}
											</p>
										</Grid.Column>
										<Grid.Column>
											<p>
												<b>{data[14] && data[14]['community-building#2'][44]}</b>
											</p>
											<p>
											{data[14] && data[14]['community-building#2'][45]}
											</p>
										</Grid.Column>
									</Grid.Row>
								</Grid>
								<br />
								<p>
								{data[14] && data[14]['community-building#2'][46]}
								</p>
								<p>
									<a href='#2.3'>
										<Button className='mt-40 mb-20 button-purple button-text'>
											Learn more about monitoring outreach
										</Button>
									</a>
								</p>
							</Accordion.Content>
						</Accordion>

						<h2 className='mt-88-70 mb-40-30' id='2.2'>
						{data[14] && data[14]['community-building#2'][47]}
						</h2>
						<p className='lighter-color'>
						{data[14] && data[14]['community-building#2'][48]}
						</p>
						<Accordion fluid className='mt-88-70 text-purple-500'>
							<Accordion.Title
								active={activeIndex === 14}
								index={14}
								onClick={handleAccordion}>
								<h2 className='text-purple-500 h2-dropdown'>
								{data[14] && data[14]['community-building#2'][49]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-purple-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 14}>
								<p>
								{data[14] && data[14]['community-building#2'][50]}
								</p>{' '}
							</Accordion.Content>
							<hr className='border-purple-200' />
							<Accordion.Title
								active={activeIndex === 15}
								index={15}
								onClick={handleAccordion}>
								<h2 className='text-purple-500 h2-dropdown'>
								{data[14] && data[14]['community-building#2'][51]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-purple-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 15}>
								<p>
								{data[14] && data[14]['community-building#2'][52]}
								</p>
							</Accordion.Content>
							<hr className='border-purple-200' />
							<Accordion.Title
								active={activeIndex === 16}
								index={16}
								onClick={handleAccordion}>
								<h2 className='text-purple-500 h2-dropdown'>
								{data[14] && data[14]['community-building#2'][53]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-purple-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 16}>
								<p>
								{data[14] && data[14]['community-building#2'][54]}
								</p>
							</Accordion.Content>
							<hr className='border-purple-200' />
							<Accordion.Title
								active={activeIndex === 17}
								index={17}
								onClick={handleAccordion}>
								<h2 className='text-purple-500 h2-dropdown'>
								{data[14] && data[14]['community-building#2'][55]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-purple-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 17}>
								<p>
								{data[14] && data[14]['community-building#2'][56]}
								</p>
							</Accordion.Content>
							<hr className='border-purple-200' />
							<Accordion.Title
								active={activeIndex === 18}
								index={18}
								onClick={handleAccordion}>
								<h2 className='text-purple-500 h2-dropdown'>
								{data[14] && data[14]['community-building#2'][57]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-purple-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 18}>
								<p>
								{data[14] && data[14]['community-building#2'][58]}
								</p>
							</Accordion.Content>
							<hr className='border-purple-200' />
							<Accordion.Title
								active={activeIndex === 19}
								index={19}
								onClick={handleAccordion}>
								<h2 className='text-purple-500 h2-dropdown'>
								{data[14] && data[14]['community-building#2'][59]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-purple-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 19}>
								<p>
								{data[14] && data[14]['community-building#2'][60]}
								</p>
							</Accordion.Content>
							<hr className='border-purple-200' />
							<Accordion.Title
								active={activeIndex === 20}
								index={20}
								onClick={handleAccordion}>
								<h2 className='text-purple-500 h2-dropdown'>
								{data[14] && data[14]['community-building#2'][61]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-purple-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 20}>
								<p>
								{data[14] && data[14]['community-building#2'][62]}
								</p>
							</Accordion.Content>
						</Accordion>
						<p className='lighter-color mt-88-70'>
						{data[14] && data[14]['community-building#2'][63]}
						</p>
						<Accordion fluid className='mt-88-70 text-purple-500'>
							<Accordion.Title
								active={activeIndex === 21}
								index={21}
								onClick={handleAccordion}>
								<h2 className='text-purple-500 h2-dropdown'>
								{data[14] && data[14]['community-building#2'][64]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-purple-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 21}>
								<p>
								{data[14] && data[14]['community-building#2'][65]}
								</p>
								<ul>
									<li>{data[14] && data[14]['community-building#2'][66]}</li>
									<li>
									{data[14] && data[14]['community-building#2'][67]}
									</li>
									<li>
									{data[14] && data[14]['community-building#2'][68]}
									</li>
									<li>
									{data[14] && data[14]['community-building#2'][69]}
									</li>
									<li>
									{data[14] && data[14]['community-building#2'][70]}
									</li>
									<li>
									{data[14] && data[14]['community-building#2'][71]}
									</li>
									<li>
									{data[14] && data[14]['community-building#2'][72]}
									</li>
								</ul>
							</Accordion.Content>
							<hr className='border-purple-200' />
							<Accordion.Title
								active={activeIndex === 22}
								index={22}
								onClick={handleAccordion}>
								<h2 className='text-purple-500 h2-dropdown'>
								{data[14] && data[14]['community-building#2'][73]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-purple-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 22}>
								<p>
								{data[14] && data[14]['community-building#2'][74]}
								</p>
								<ul>
									<li>
									{data[14] && data[14]['community-building#2'][75]}
									</li>
									<li>{data[14] && data[14]['community-building#2'][76]}</li>
									<li>{data[14] && data[14]['community-building#2'][77]}</li>
									<li>
									{data[14] && data[14]['community-building#2'][78]}
									</li>
									<li>{data[14] && data[14]['community-building#2'][79]}</li>
								</ul>
							</Accordion.Content>
						</Accordion>

						<h2 className='mt-88-70 mb-40-30' id='2.3'>
						{data[14] && data[14]['community-building#2'][80]}
						</h2>
						<p className='lighter-color'>
						{data[14] && data[14]['community-building#2'][81]}
						</p>
						<p className='lighter-color'>
						{data[14] && data[14]['community-building#2'][82]}
						</p>
						<Accordion fluid className='mt-88-70 text-purple-500'>
							<Accordion.Title
								active={activeIndex === 23}
								index={23}
								onClick={handleAccordion}>
								<h2 className='text-purple-500 h2-dropdown'>
								{data[14] && data[14]['community-building#2'][83]}
									
								</h2>
								<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-purple-500' /></h2></div>{' '}
							</Accordion.Title>
							<Accordion.Content active={activeIndex === 23}>
								<Grid stackable>
									<Grid.Row columns={2}>
										<Grid.Column>
											<p>
												<b>{data[14] && data[14]['community-building#2'][84]}</b>
											</p>
											<p>
											{data[14] && data[14]['community-building#2'][85]}
											</p>
										</Grid.Column>
										<Grid.Column>
											<p>
												<b>{data[14] && data[14]['community-building#2'][86]}</b>
											</p>
											<p>
											{data[14] && data[14]['community-building#2'][87]}
											</p>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row columns={2}>
										<Grid.Column>
											<p>
												<b>{data[14] && data[14]['community-building#2'][88]}</b>
											</p>
											<p>
											{data[14] && data[14]['community-building#2'][89]}
											</p>
										</Grid.Column>
										<Grid.Column>
											<p>
												<b>{data[14] && data[14]['community-building#2'][90]}</b>
											</p>
											<p>
											{data[14] && data[14]['community-building#2'][91]}
											</p>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Accordion.Content>
						</Accordion>
						<p className='lighter-color mt-88-70'>
						{data[14] && data[14]['community-building#2'][92]}
						</p>
					{/* </motion.div>
				)}
			</InView> */}
			</div>
		</>
	)
}

export default Building
