import { Routes, Route } from 'react-router-dom'
import './App.css'
import Header from './components/header/Header'
import Home from './pages/home/Home'
import Footer from './components/footer/Footer'
import Registry from './pages/registry/Registry'
import Aspects from './pages/aspects/Aspects'
import Resources from './pages/resources/Resources'
import Building from './pages/building/Building'
import ShortMenu from './components/shortmenu/ShortMenu'
import Search from './components/search/Search'
import FirstQuestion from './pages/firstquestion/FirstQuestion'
import About from './pages/about/About'
import Events from './pages/events/Events'
import EventRegistration from './pages/eventregistration/EventRegistration'
import PrivacyAndTerms from './pages/privacyandterms/PrivacyAndTerms'
import Contact from './pages/contact/Contact'
import * as Realm from 'realm-web'
import {useEffect, useState} from 'react'

function App() {
	const [data, setData] = useState([])

	useEffect(() => {
		const getData = async () => {
			const app = new Realm.App({ id: "peer-bootcamp-application-ghmpz" });
			const credentials = Realm.Credentials.anonymous();
			try {
				const data = await app.logIn(credentials);
				const bootcampData = await data.functions.getBootcamp()
				// console.log('bootcampData', bootcampData);
				setData(bootcampData)
			} catch (err) {
				console.error("Failed to log in", err);
			}
		}
		getData()
	}, [])
	return (
		<>
			<Header />
			<Routes>
				<Route path='/contact-us' element={<Contact />} />
				<Route path='/privacy-and-terms' element={<PrivacyAndTerms />} />
				<Route path='/event-registration' element={<EventRegistration />} />
				<Route path='/events' element={<Events />} />
				<Route path='/about' element={<About />} />
				<Route path='/registry-goals' element={<Registry data={data}/>} />
				<Route path='/technical-planning' element={<Aspects data={data}/>} />
				<Route path='/required-resources' element={<Resources data={data} />} />
				<Route path='/community-building' element={<Building data={data}/>} />
				<Route path='/search' element={<Search />} />
				<Route path='/short-menu' element={<ShortMenu />} />
				<Route path='/first-question' element={<FirstQuestion />} />
				<Route path='/' element={<Home />} />
			</Routes>
			<Footer />
		</>
	)
}

export default App
