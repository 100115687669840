import React, { useState, useEffect } from 'react'
import { Image, Button } from 'semantic-ui-react'
import { Accordion, Icon } from 'semantic-ui-react'
import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import { InView } from 'react-intersection-observer'
import './Aspects.css'
import { progressIndicator } from '../../components/HelperFunction'

function Aspects({data}) {
	const [activeIndex, setActiveIndex] = useState(0)
	const handleAccordion = (e, index) => {
		setActiveIndex(activeIndex === index.index ? -1 : index.index)
	}
	const [sliderIndex, setSliderIndex] = useState(0)
	// useEffect(() => {
	// 	console.log('data', data)
	// 	console.log('bat', data && data['technical-planning#0'] && data['technical-planning#0'][0])
	// 	console.log('cat', data && data[0])
	// }, [data])
	const { pathname, hash, key } = useLocation()
	useEffect(() => {
		// if not a hash link, scroll to top
		if (hash === '') {
			window.scrollTo(0, 0)
		}
		// else scroll to id
		else {
			setTimeout(() => {
				const id = hash.replace('#', '')
				const element = document.getElementById(id)
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' })
				}
			}, 0)
		}
	}, [pathname, hash, key])

	// const [width, setWidth] = useState(0)
	// const carousel = useRef()

	// useEffect(() => {
	// 	setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth - 74)
	// }, [])

	useEffect(() => {
		progressIndicator()
	}, [])

	const handleLeftSlider = () => {
		const slider = document.querySelector(".slider-aspects")
		const sliderIndex = parseInt(getComputedStyle(slider).getPropertyValue("--slider-index"))
		if (sliderIndex === 0) {
			return null
		}
		slider.style.setProperty("--slider-index", sliderIndex - 1)
		setSliderIndex(sliderIndex - 1)
	}
	const handleRightSlider = () => {
		const slider = document.querySelector(".slider-aspects")
		const sliderIndex = parseInt(getComputedStyle(slider).getPropertyValue("--slider-index"))
		if (sliderIndex === 6) {
			return null
		}
		slider.style.setProperty("--slider-index", sliderIndex + 1)
		setSliderIndex(sliderIndex + 1)
	}


	return (
		<>
			<nav className='scroll-dot aspect-dot'>
				<ul>
					<li>
						<a href='#0' className='dot active' data-scroll='0'>
							<span>Planning the technical aspects</span>
						</a>
					</li>
					<li>
						<a href='#1' className='dot' data-scroll='1'>
							<span>The registry planning process</span>
						</a>
					</li>
					<li>
						<a href='#2' className='dot' data-scroll='2'>
							<span>What is the primary focus of your registry?</span>
						</a>
					</li>
					<li>
						<a href='#3' className='dot' data-scroll='3'>
							<span>Understanding data types</span>
						</a>
					</li>
					<li>
						<a href='#4' className='dot' data-scroll='4'>
							<span>Deciding what data you will collect</span>
						</a>
					</li>
					<li>
						<a href='#5' className='dot' data-scroll='5'>
							<span>Planning for your research protocol</span>
						</a>
					</li>
					<li>
						<a href='#6' className='dot' data-scroll='6'>
							<span>Considering data analysis</span>
						</a>
					</li>
					<li>
						<a href='#7' className='dot' data-scroll='7'>
							<span>Ethics review for your registry</span>
						</a>
					</li>
					<li>
						<a href='#8' className='dot' data-scroll='8'>
							<span>Registries and regulatory compliance</span>
						</a>
					</li>
					<li>
						<a href='#9' className='dot' data-scroll='9'>
							<span>Choosing the right platform provider</span>
						</a>
					</li>
				</ul>
			</nav>

			<div className='content-container mt-260-190'>
				<div className='section' id='0'>
			<h1 className=' mb-40-30'>{data[15] && data[15]['technical-planning#0'][0]}</h1>
			<p className='lighter-color'>
			{data[15] && data[15]['technical-planning#0'][1]}
			</p>
			<p className='lighter-color'>
			{data[15] && data[15]['technical-planning#0'][2]}
				<a
					className='lighter-color link-black'
					href='https://www.ncbi.nlm.nih.gov/books/NBK562575/'
					rel='noreferrer'
					target='_blank'>
					{data[15] && data[15]['technical-planning#0'][3]}
				</a>{' '}
				{data[15] && data[15]['technical-planning#0'][4]}
				<a
					className='lighter-color link-black'
					href='https://www.ncbi.nlm.nih.gov/books/NBK493594/'
					rel='noreferrer'
					target='_blank'>
					{data[15] && data[15]['technical-planning#0'][5]}
				</a>
				{data[15] && data[15]['technical-planning#0'][6]}
			</p>
			<Image
				className='mt-80-40'
				src='/images/aspects.png'
				size='large'
				width='300'
				height='430'
				centered
					/>
				</div>
				
				<hr className='mt-80-40 mb-80-40 border-neutral' />

				<InView threshold={0.04}>
					{({ ref, inView }) => (
						<motion.div
							ref={ref}
							initial={{ opacity: 0 }}
							animate={inView ? { opacity: 1 } : { opacity: 0 }}
							transition={{ duration: 0.2 }}
							className='section'
							id='1'>
							<h1 className=' mb-40-30'>{data[16] && data[16]['technical-planning#1'][0]}</h1>
							<p className='lighter-color'>
							{data[16] && data[16]['technical-planning#1'][1]}
							</p>

							<Accordion fluid className='mt-88-70 text-green-700'>
								<Accordion.Title
									active={activeIndex === 1}
									index={1}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
										{' '}
										{data[16] && data[16]['technical-planning#1'][2]}{' '}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 1}>
									<div className='text-center'>
										<div className='video-responsive'>
											<iframe
												width='560'
												height='315'
												src='https://www.youtube.com/embed/jMBFX2GGqFw'
												title='YouTube video player'
												frameborder='0'
												allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
												allowfullscreen></iframe>
										</div>
									</div>

									<p className=' mt-50'>
									{data[16] && data[16]['technical-planning#1'][3]}
										<a
											className='link-green'
											href='http://ncbi.nlm.nih.gov/books/NBK562567/'
											target='_blank'
											rel='noreferrer'>
											{data[16] && data[16]['technical-planning#1'][4]}
										</a>{' '}
										{data[16] && data[16]['technical-planning#1'][5]}
									</p>
								</Accordion.Content>
							</Accordion>
						</motion.div>
					)}
				</InView>

				<hr className='mt-80-40 mb-80-40 border-neutral' />

				<InView threshold={0.04}>
					{({ ref, inView }) => (
						<motion.div
							ref={ref}
							initial={{ opacity: 0 }}
							animate={inView ? { opacity: 1 } : { opacity: 0 }}
							transition={{ duration: 0.2 }}
							className='section'
							id='2'>
							<h1 className=' mb-40-30'>{data[17] && data[17]['technical-planning#2'][0]}</h1>
							<p className='lighter-color'>
							{data[17] && data[17]['technical-planning#2'][1]}
								<a
									className='lighter-color link-black'
									href='https://www.ncbi.nlm.nih.gov/books/NBK493594/'
									target='_blank'
									rel='noreferrer'>
									{data[17] && data[17]['technical-planning#2'][2]}
								</a>
								{data[17] && data[17]['technical-planning#2'][3]}
							</p>

							<p className='lighter-color'>
							{data[17] && data[17]['technical-planning#2'][4]}
							</p>

							<Accordion fluid className='mt-88-70 text-green-700'>
								<Accordion.Title
									active={activeIndex === 2}
									index={2}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[17] && data[17]['technical-planning#2'][5]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 2}>
									<p>
									{data[17] && data[17]['technical-planning#2'][6]}
										<a
											className='link-green'
											href='/pdf/LunaProductSheet_CommunityDrivenInnovation.pdf'
											target='_blank'
											rel='noreferrer'>
											{data[17] && data[17]['technical-planning#2'][7]}
										</a>{' '}
										{data[17] && data[17]['technical-planning#2'][8]}
									</p>
									<p>
									{data[17] && data[17]['technical-planning#2'][9]}
										<a
											className='link-green'
											href='https://www.ncbi.nlm.nih.gov/books/NBK493591/#purpose.s1'
											target='_blank'
											rel='noreferrer'>
											{data[17] && data[17]['technical-planning#2'][10]}
										</a>
										{data[17] && data[17]['technical-planning#2'][11]}
									</p>
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 3}
									index={3}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[17] && data[17]['technical-planning#2'][12]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 3}>
									<p>
									{data[17] && data[17]['technical-planning#2'][13]}
										<a
											className='link-green'
											href='https://www.ncbi.nlm.nih.gov/books/NBK493591/#purpose.s2'
											target='_blank'
											rel='noreferrer'>
											{data[17] && data[17]['technical-planning#2'][14]}
										</a>
										{data[17] && data[17]['technical-planning#2'][15]}
									</p>
									<p>
									{data[17] && data[17]['technical-planning#2'][16]}
									</p>
									<p>
									{data[17] && data[17]['technical-planning#2'][17]}
										<a
											className='link-green'
											href='https://www.ncbi.nlm.nih.gov/books/NBK493591/#purpose.s2'
											target='_blank'
											rel='noreferrer'>
											{data[17] && data[17]['technical-planning#2'][18]}
										</a>
										{data[17] && data[17]['technical-planning#2'][19]}
									</p>
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 4}
									index={4}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[17] && data[17]['technical-planning#2'][20]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 4}>
									<p>
									{data[17] && data[17]['technical-planning#2'][21]}
									</p>
									<p>
									{data[17] && data[17]['technical-planning#2'][22]}
										<a
											className='link-green'
											href='https://www.ncbi.nlm.nih.gov/books/NBK493591/#purpose.s3'
											target='_blank'
											rel='noreferrer'>
											{data[17] && data[17]['technical-planning#2'][23]}
										</a>
										{data[17] && data[17]['technical-planning#2'][24]}
									</p>
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 5}
									index={5}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[17] && data[17]['technical-planning#2'][25]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 5}>
									<p>
									{data[17] && data[17]['technical-planning#2'][26]}
										<a
											className='link-green'
											href='https://www.ncbi.nlm.nih.gov/books/NBK493591/#purpose.s4'
											target='_blank'
											rel='noreferrer'>
											{data[17] && data[17]['technical-planning#2'][27]}
										</a>
										{data[17] && data[17]['technical-planning#2'][28]}
									</p>
									<p>
									{data[17] && data[17]['technical-planning#2'][29]}
									</p>
									<p>
									{data[17] && data[17]['technical-planning#2'][30]}
										<a
											className='link-green'
											href='https://www.ncbi.nlm.nih.gov/books/NBK493591/#purpose.s4'
											target='_blank'
											rel='noreferrer'>
											{data[17] && data[17]['technical-planning#2'][31]}
										</a>
										{data[17] && data[17]['technical-planning#2'][32]}
									</p>
									<a href='#3'>
										<Button className='mt-40 mb-20 button-green button-text'>
											Information on collecting PROs
										</Button>
									</a>
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 6}
									index={6}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[17] && data[17]['technical-planning#2'][33]}
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 6}>
									<p>
									{data[17] && data[17]['technical-planning#2'][34]}
									</p>
									<p>
									{data[17] && data[17]['technical-planning#2'][35]}
										<a
											className='link-green'
											href='https://www.ncbi.nlm.nih.gov/books/NBK493591/#purpose.s5'
											target='_blank'
											rel='noreferrer'>
											{data[17] && data[17]['technical-planning#2'][36]}
										</a>
										{data[17] && data[17]['technical-planning#2'][37]}
									</p>
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 7}
									index={7}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[17] && data[17]['technical-planning#2'][38]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 7}>
									<p>
									{data[17] && data[17]['technical-planning#2'][39]}
									</p>
									<p>
									{data[17] && data[17]['technical-planning#2'][40]}
										<a
											className='link-green'
											href='https://www.ncbi.nlm.nih.gov/books/NBK493591/#purpose.s6'
											target='_blank'
											rel='noreferrer'>
											{data[17] && data[17]['technical-planning#2'][41]}
										</a>
										{data[17] && data[17]['technical-planning#2'][42]}
									</p>
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 8}
									index={8}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[17] && data[17]['technical-planning#2'][43]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 8}>
									<p>
									{data[17] && data[17]['technical-planning#2'][44]}
									</p>
									<p>
									{data[17] && data[17]['technical-planning#2'][45]}
										<a
											className='link-green'
											href='https://www.ncbi.nlm.nih.gov/books/NBK493591/#purpose.s7'
											target='_blank'
											rel='noreferrer'>
											{data[17] && data[17]['technical-planning#2'][46]}
										</a>
										{data[17] && data[17]['technical-planning#2'][47]}
									</p>
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 9}
									index={9}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[17] && data[17]['technical-planning#2'][48]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 9}>
									<p>
									{data[17] && data[17]['technical-planning#2'][49]}
										<a
											className='link-green'
											href='/pdf/LunaProductSheet_CommunityDrivenInnovation.pdf'
											target='_blank'
											rel='noreferrer'>
											{data[17] && data[17]['technical-planning#2'][50]}
										</a>{' '}
										{data[17] && data[17]['technical-planning#2'][51]}
									</p>
									<p>
									{data[17] && data[17]['technical-planning#2'][52]}
										<a
											className='link-green'
											href='https://www.ncbi.nlm.nih.gov/books/NBK493591/#purpose.s8'
											target='_blank'
											rel='noreferrer'>
											{data[17] && data[17]['technical-planning#2'][53]}
										</a>
										{data[17] && data[17]['technical-planning#2'][54]}
										<a
											className='link-green'
											href='/community-building'
											target='_blank'
											rel='noreferrer'>
											{data[17] && data[17]['technical-planning#2'][55]}
										</a>
										{data[17] && data[17]['technical-planning#2'][56]}
									</p>
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 10}
									index={10}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[17] && data[17]['technical-planning#2'][57]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 10}>
									<p>
									{data[17] && data[17]['technical-planning#2'][58]}
										<a
											className='link-green'
											href='https://www.ncbi.nlm.nih.gov/books/NBK493591/#purpose.s9'
											target='_blank'
											rel='noreferrer'>
											{data[17] && data[17]['technical-planning#2'][59]}
										</a>
										{data[17] && data[17]['technical-planning#2'][60]}
									</p>
								</Accordion.Content>
							</Accordion>

							<p className='lighter-color mt-88-70'>
							{data[17] && data[17]['technical-planning#2'][61]}
							</p>
						</motion.div>
					)}
				</InView>

				<hr className='mt-80-40 mb-80-40 border-neutral' />

				<InView threshold={0.04}>
					{({ ref, inView }) => (
						<motion.div
							ref={ref}
							initial={{ opacity: 0 }}
							animate={inView ? { opacity: 1 } : { opacity: 0 }}
							transition={{ duration: 0.2 }}
							className='section'
							id='3'>
							<h1 className=' mb-40-30'>{data[18] && data[18]['technical-planning#3'][0]}</h1>
							<p className='lighter-color'>
							{data[18] && data[18]['technical-planning#3'][1]}
							</p>

							<Accordion fluid className='mt-88-70 text-green-700'>
								<Accordion.Title
									active={activeIndex === 11}
									index={11}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[18] && data[18]['technical-planning#3'][2]}
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 11}>
									<p>
									{data[18] && data[18]['technical-planning#3'][3]}
									</p>
									<p>
									{data[18] && data[18]['technical-planning#3'][4]}
									</p>
									<p>
									{data[18] && data[18]['technical-planning#3'][5]}
										<span className='p-italic'>{data[18] && data[18]['technical-planning#3'][6]}</span>{data[18] && data[18]['technical-planning#3'][7]}
										<span className='p-italic'>{data[18] && data[18]['technical-planning#3'][8]}</span>{data[18] && data[18]['technical-planning#3'][9]}
										<span className='p-italic'>{data[18] && data[18]['technical-planning#3'][10]}</span>{data[18] && data[18]['technical-planning#3'][11]}{' '}
										<span className='p-italic'>{data[18] && data[18]['technical-planning#3'][12]}</span>{data[18] && data[18]['technical-planning#3'][13]}
									</p>
									<p>
									{data[18] && data[18]['technical-planning#3'][14]}
										<a
											className='link-green'
											href='https://www.phenxtoolkit.org/protocols'
											target='_blank'
											rel='noreferrer'>
											{data[18] && data[18]['technical-planning#3'][15]}
										</a>
										{data[18] && data[18]['technical-planning#3'][16]}{' '}
										<a
											className='link-green'
											href='/pdf/LunaProductSheet_CommunityDrivenInnovation.pdf'
											target='_blank'
											rel='noreferrer'>
											{data[18] && data[18]['technical-planning#3'][17]}
										</a>{' '}
										{data[18] && data[18]['technical-planning#3'][18]}
										<span className='p-italic'>{data[18] && data[18]['technical-planning#3'][19]}</span>{data[18] && data[18]['technical-planning#3'][20]}
										<span className='p-italic'>{data[18] && data[18]['technical-planning#3'][21]}</span>{data[18] && data[18]['technical-planning#3'][22]}
										<span className='p-bold'>{data[18] && data[18]['technical-planning#3'][23]}
										</span>{data[18] && data[18]['technical-planning#3'][24]}
									</p>
									<p>
									{data[18] && data[18]['technical-planning#3'][25]}
										<a
											className='link-green'
											href='https://www.commondataelements.ninds.nih.gov/crf-library'
											target='_blank'
											rel='noreferrer'>
											{data[18] && data[18]['technical-planning#3'][26]}
										</a>{' '}
										{data[18] && data[18]['technical-planning#3'][27]}
										<ul>
											<li>
												<a
													className='link-green'
													href='https://www.healthmeasures.net/explore-measurement-systems/promis'
													target='_blank'
													rel='noreferrer'>
													{data[18] && data[18]['technical-planning#3'][28]}
												</a>
												{data[18] && data[18]['technical-planning#3'][29]}
											</li>
											<li>
											{data[18] && data[18]['technical-planning#3'][30]}
											</li>
											<li>
											{data[18] && data[18]['technical-planning#3'][31]}
											</li>
											<li>
											{data[18] && data[18]['technical-planning#3'][32]}
												<a
													className='link-green'
													href='https://www.lunadna.com/'
													target='_blank'
													rel='noreferrer'>
													{data[18] && data[18]['technical-planning#3'][33]}
												</a>
												{data[18] && data[18]['technical-planning#3'][34]}
												<a
													className='link-green'
													href='https://geneticalliance.org/peer'
													target='_blank'
													rel='noreferrer'>
													{data[18] && data[18]['technical-planning#3'][35]}
												</a>{' '}
												{data[18] && data[18]['technical-planning#3'][36]}
											</li>
										</ul>
									</p>
									<p>
									{data[18] && data[18]['technical-planning#3'][37]}
										<a
											className='link-green'
											href='https://youtube.com/playlist?list=PLmDcGanXc-MMFJFZAYKxbw84rhiO9_iYj'
											target='_blank'
											rel='noreferrer'>
											{data[18] && data[18]['technical-planning#3'][38]}
										</a>
										{data[18] && data[18]['technical-planning#3'][39]}
										<a
											className='link-green'
											href='https://www.youtube.com/playlist?list=PLmDcGanXc-MPrECKNNEcPbc4cycvCCNNC'
											target='_blank'
											rel='noreferrer'>
											{data[18] && data[18]['technical-planning#3'][40]}
										</a>
										{data[18] && data[18]['technical-planning#3'][41]}
									</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 12}
									index={12}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[18] && data[18]['technical-planning#3'][42]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 12}>
									<p>
									{data[18] && data[18]['technical-planning#3'][43]}
									</p>
									<p>
									{data[18] && data[18]['technical-planning#3'][44]}
									</p>
									<p>
									{data[18] && data[18]['technical-planning#3'][45]}
										<a
											className='link-green'
											href='https://www.youtube.com/playlist?list=PLmDcGanXc-MPrECKNNEcPbc4cycvCCNNC'
											target='_blank'
											rel='noreferrer'>
											{data[18] && data[18]['technical-planning#3'][46]}
										</a>
										{data[18] && data[18]['technical-planning#3'][47]}
									</p>
									<p>
									{data[18] && data[18]['technical-planning#3'][48]}
									</p>
									<p>
									{data[18] && data[18]['technical-planning#3'][49]}
										<a
											className='link-green'
											href='https://www.youtube.com/playlist?list=PLmDcGanXc-MPrECKNNEcPbc4cycvCCNNC'
											rel='noreferrer'
											target='_blank'>
											{data[18] && data[18]['technical-planning#3'][50]}
										</a>
										{data[18] && data[18]['technical-planning#3'][51]}
										<a
											className='link-green'
											href='/pdf/LunaProductSheet_CommunityDrivenInnovation.pdf'
											target='_blank'
											rel='noreferrer'>
											{data[18] && data[18]['technical-planning#3'][52]}
										</a>{' '}
										{data[18] && data[18]['technical-planning#3'][53]}
										<a
											className='link-green'
											href='/registry-goals#2'
											target='_blank'
											rel='noreferrer'>
											{data[18] && data[18]['technical-planning#3'][54]}
										</a>
										{data[18] && data[18]['technical-planning#3'][55]}
									</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 13}
									index={13}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[18] && data[18]['technical-planning#3'][56]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 13}>
									<p>
									{data[18] && data[18]['technical-planning#3'][57]}
										<a
											className='link-green'
											href='https://www.ncbi.nlm.nih.gov/books/NBK551878/table/ch4.tab1/?report=objectonly'
											target='_blank'
											rel='noreferrer'>
											{data[18] && data[18]['technical-planning#3'][58]}
										</a>{' '}
										{data[18] && data[18]['technical-planning#3'][59]}
										<a
											className='link-green'
											href='https://www.ncbi.nlm.nih.gov/books/NBK551879/'
											target='_blank'
											rel='noreferrer'>
											{data[18] && data[18]['technical-planning#3'][60]}
										</a>
										{data[18] && data[18]['technical-planning#3'][61]}
									</p>
									<p>
									{data[18] && data[18]['technical-planning#3'][62]}
										<a
											className='link-green'
											href='https://www.ncbi.nlm.nih.gov/books/NBK551879/'
											target='_blank'
											rel='noreferrer'>
											{data[18] && data[18]['technical-planning#3'][63]}
										</a>
										{data[18] && data[18]['technical-planning#3'][64]}
										<a
											className='link-green'
											href='https://www.lunadna.com'
											target='_blank'
											rel='noreferrer'>
											{data[18] && data[18]['technical-planning#3'][65]}
										</a>{' '}
										{data[18] && data[18]['technical-planning#3'][66]}
									</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 14}
									index={14}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[18] && data[18]['technical-planning#3'][67]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 14}>
									<p>
									{data[18] && data[18]['technical-planning#3'][68]}{' '}
										<span className='p-italic'>{data[18] && data[18]['technical-planning#3'][69]}</span>{data[18] && data[18]['technical-planning#3'][70]}<span className='p-italic'>{data[18] && data[18]['technical-planning#3'][71]}</span>{data[18] && data[18]['technical-planning#3'][72]}
									</p>
									<p>
									{data[18] && data[18]['technical-planning#3'][73]}<span className='p-italic'>{data[18] && data[18]['technical-planning#3'][74]}</span>{data[18] && data[18]['technical-planning#3'][75]}
										<span className='p-italic'>{data[18] && data[18]['technical-planning#3'][76]}</span>{data[18] && data[18]['technical-planning#3'][77]}
									</p>
									<p>
									{data[18] && data[18]['technical-planning#3'][78]}
										<span className='p-italic'>{data[18] && data[18]['technical-planning#3'][79]}</span>{data[18] && data[18]['technical-planning#3'][80]}
										<span className='p-italic'>{data[18] && data[18]['technical-planning#3'][81]}</span>{data[18] && data[18]['technical-planning#3'][82]}
										<a
											className='link-green'
											href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8088851/'
											target='_blank'
											rel='noreferrer'>
											{data[18] && data[18]['technical-planning#3'][83]}
										</a>{' '}
										{data[18] && data[18]['technical-planning#3'][84]}
									</p>
									<p>
									{data[18] && data[18]['technical-planning#3'][85]}
									</p>
									<p>
									{data[18] && data[18]['technical-planning#3'][86]}
									</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 15}
									index={15}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[18] && data[18]['technical-planning#3'][87]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 15}>
									<p>
									{data[18] && data[18]['technical-planning#3'][88]}
									</p>
									<p>
									{data[18] && data[18]['technical-planning#3'][89]}
										<a
											className='link-green'
											href='https://wikiadvocacy.org/Biobank_Sample_Collection'
											target='_blank'
											rel='noreferrer'>
											{data[18] && data[18]['technical-planning#3'][90]}
										</a>{' '}
										{data[18] && data[18]['technical-planning#3'][91]}
										<a
											className='link-green'
											href='https://biospecimens.cancer.gov/bestpractices/2016-NCIBestPractices.pdf'
											target='_blank'
											rel='noreferrer'>
											{data[18] && data[18]['technical-planning#3'][92]}
										</a>
										{data[18] && data[18]['technical-planning#3'][93]}
									</p>
									<p>
									{data[18] && data[18]['technical-planning#3'][94]}
										<a
											className='link-green'
											href='https://geneticalliance.org/biobank'
											target='_blank'
											rel='noreferrer'>
											{data[18] && data[18]['technical-planning#3'][95]}
										</a>{' '}
										{data[18] && data[18]['technical-planning#3'][96]}
									</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 16}
									index={16}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[18] && data[18]['technical-planning#3'][97]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 16}>
									<p>
									{data[18] && data[18]['technical-planning#3'][98]}
									</p>
									<p>
									{data[18] && data[18]['technical-planning#3'][99]}
										<a
											className='link-green'
											href='http://kahuna.clayton.edu/jqu/FHH/html/index.html'
											target='_blank'
											rel='noreferrer'>
											{data[18] && data[18]['technical-planning#3'][100]}
										</a>{' '}
										{data[18] && data[18]['technical-planning#3'][101]}
										<a
											className='link-green'
											href='http://familyhealthhistory.org/pages/learn_more.php'
											target='_blank'
											rel='noreferrer'>
											{data[18] && data[18]['technical-planning#3'][102]}
										</a>{' '}
										{data[18] && data[18]['technical-planning#3'][103]}
									</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 17}
									index={17}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[18] && data[18]['technical-planning#3'][104]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 17}>
									<p>
									{data[18] && data[18]['technical-planning#3'][105]}
									</p>
									<p>
									{data[18] && data[18]['technical-planning#3'][106]}
										<a
											className='link-green'
											href='https://learning.closer.ac.uk/learning-modules/introduction/types-of-longitudinal-research/'
											target='_blank'
											rel='noreferrer'>
											{data[18] && data[18]['technical-planning#3'][107]}
										</a>{' '}
										{data[18] && data[18]['technical-planning#3'][108]}
									</p>
									<p>
									{data[18] && data[18]['technical-planning#3'][109]}
									</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 18}
									index={18}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[18] && data[18]['technical-planning#3'][110]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 18}>
									<p>
									{data[18] && data[18]['technical-planning#3'][111]}
									</p>
									<p>
									{data[18] && data[18]['technical-planning#3'][112]}
									</p>
									<p>
									{data[18] && data[18]['technical-planning#3'][113]}
									</p>
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 19}
									index={19}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[18] && data[18]['technical-planning#3'][114]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 19}>
									<p>
									{data[18] && data[18]['technical-planning#3'][115]}
									</p>
									<p>
									{data[18] && data[18]['technical-planning#3'][116]}
									</p>
								</Accordion.Content>
							</Accordion>
						</motion.div>
					)}
				</InView>

				<hr className='mt-80-40 mb-80-40 border-neutral' />

				<InView threshold={0.04}>
					{({ ref, inView }) => (
						<motion.div
							ref={ref}
							initial={{ opacity: 0 }}
							animate={inView ? { opacity: 1 } : { opacity: 0 }}
							transition={{ duration: 0.2 }}
							className='section'
							id='4'>
							<h1 className=' mb-40-30'>{data[19] && data[19]['technical-planning#4'][0]}</h1>
							<p className='lighter-color'>
							{data[19] && data[19]['technical-planning#4'][1]}
							</p>
							<a href='#7'>
								<Button className='mt-40 mb-40 button-green button-text'>
									Learn more about ethics review
								</Button>
							</a>
							<p className='lighter-color'>
							{data[19] && data[19]['technical-planning#4'][2]}
								<a
									className='lighter-color link-black'
									href='http://wikiadvocacy.org/index.php/Registries'
									target='_blank'
									rel='noreferrer'>
									{data[19] && data[19]['technical-planning#4'][3]}
								</a>{' '}
								{data[19] && data[19]['technical-planning#4'][4]}
							</p>
							<p className='lighter-color'>
							{data[19] && data[19]['technical-planning#4'][5]}
								<span className='p-italic lighter-color'>{data[19] && data[19]['technical-planning#4'][6]}</span> {data[19] && data[19]['technical-planning#4'][7]}
								<span className='p-italic lighter-color'>{data[19] && data[19]['technical-planning#4'][8]}</span>{data[19] && data[19]['technical-planning#4'][9]}
								<a
									className='lighter-color link-black'
									href='https://www.geneticalliance.org/peer'
									target='_blank'
									rel='noreferrer'>
									{data[19] && data[19]['technical-planning#4'][10]}
								</a>{' '}
								{data[19] && data[19]['technical-planning#4'][11]}
							</p>
							<p className='lighter-color'>
							{data[19] && data[19]['technical-planning#4'][12]}
							</p>

							<h2 id='4.1' className='mt-40-30 mb-40-30'>
							{data[19] && data[19]['technical-planning#4'][13]}
							</h2>
							<p className='lighter-color'>
							{data[19] && data[19]['technical-planning#4'][14]}
							</p>

							<Accordion fluid className='mt-88-70 text-green-700'>
								<Accordion.Title
									active={activeIndex === 20}
									index={20}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[19] && data[19]['technical-planning#4'][15]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 20}>
									<p>
									{data[19] && data[19]['technical-planning#4'][16]}
									</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 21}
									index={21}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[19] && data[19]['technical-planning#4'][17]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 21}>
									<p>
									{data[19] && data[19]['technical-planning#4'][18]}
									</p>
								</Accordion.Content>
							</Accordion>

							<h2 id='4.2' className='mt-88-70 mb-40-30'>
							{data[19] && data[19]['technical-planning#4'][19]}
							</h2>
							<p className='lighter-color'>
							{data[19] && data[19]['technical-planning#4'][20]}
							</p>

							<Accordion fluid className='mt-88-70 text-green-700'>
								<Accordion.Title
									active={activeIndex === 22}
									index={22}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[19] && data[19]['technical-planning#4'][21]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 22}>
									<p>
									{data[19] && data[19]['technical-planning#4'][22]}
										<a
											className='link-green'
											href='https://docs.google.com/document/d/1L_t5VccQHY1g67EGa_XEqeFETGm_yLu_ycJ-2oMT4fU/edit?usp=sharing'
											rel='noreferrer'
											target='_blank'>
											{data[19] && data[19]['technical-planning#4'][23]}
										</a>{' '}
										{data[19] && data[19]['technical-planning#4'][24]}
									</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 23}
									index={23}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[19] && data[19]['technical-planning#4'][25]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 23}>
									<p>
									{data[19] && data[19]['technical-planning#4'][26]}
									</p>
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 24}
									index={24}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[19] && data[19]['technical-planning#4'][27]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 24}>
									<p>
									{data[19] && data[19]['technical-planning#4'][28]}
									</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 25}
									index={25}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[19] && data[19]['technical-planning#4'][29]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 25}>
									<p>
									{data[19] && data[19]['technical-planning#4'][30]}
									</p>
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 26}
									index={26}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[19] && data[19]['technical-planning#4'][31]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 26}>
									<p>
									{data[19] && data[19]['technical-planning#4'][32]}
									</p>
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 27}
									index={27}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[19] && data[19]['technical-planning#4'][33]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 27}>
									<p>
									{data[19] && data[19]['technical-planning#4'][34]}
									</p>
								</Accordion.Content>
							</Accordion>

							<p className='lighter-color mt-88-70'>
							{data[19] && data[19]['technical-planning#4'][35]}
								<a
									className='lighter-color link-black'
									href='https://www.ncbi.nlm.nih.gov/books/NBK562567/#ch2.s2.7'
									rel='noreferrer'
									target='_blank'>
									{data[19] && data[19]['technical-planning#4'][36]}
								</a>
							</p>
						</motion.div>
					)}
				</InView>

				<hr className='mt-80-40 mb-80-40 border-neutral' />
			
				{/* <InView threshold={0.04}>
					{({ ref, inView }) => (
						<motion.div
							ref={ref}
							initial={{ opacity: 0 }}
							animate={inView ? { opacity: 1 } : { opacity: 0 }}
							transition={{ duration: 0.2 }}
							className='section'
							id='5'>
							<h1 className=' mb-40-30'>{data[20] && data[20]['technical-planning#5'][0]}</h1>
							<p className='lighter-color'>
							{data[20] && data[20]['technical-planning#5'][1]}
								<i className='lighter-color'>{data[20] && data[20]['technical-planning#5'][2]}</i>{data[20] && data[20]['technical-planning#5'][3]}
							</p>
							<a href='#7'>
								<Button className='mt-40 mb-40 button-green button-text'>
									Learn more about ethics review
								</Button>
							</a>
							<p className='lighter-color'>
							{data[20] && data[20]['technical-planning#5'][4]}
								<a
									className='lighter-color link-black'
									href='https://ori.hhs.gov/basic-research-concepts-brc'
									rel='noreferrer'
									target='_blank'>
									{data[20] && data[20]['technical-planning#5'][5]}
								</a>{' '}
								{data[20] && data[20]['technical-planning#5'][6]}
							</p>
							<p className='lighter-color'>
							{data[20] && data[20]['technical-planning#5'][7]}
								<a
									className='lighter-color link-black'
									href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4792175/'
									rel='noreferrer'
									target='_blank'>
									{data[20] && data[20]['technical-planning#5'][8]}
								</a>
								{data[20] && data[20]['technical-planning#5'][9]}
							</p> */}

				<div 
					initial={{ opacity: 0 }}
					transition={{ duration: 0.2 }}
					className='section'
					id='5'>
					<h1 className=' mb-40-30'>{data[20] && data[20]['technical-planning#5'][0]}</h1>
					<p className='lighter-color'>
					{data[20] && data[20]['technical-planning#5'][1]}
						<i className='lighter-color'>{data[20] && data[20]['technical-planning#5'][2]}</i>{data[20] && data[20]['technical-planning#5'][3]}
					</p>
					<a href='#7'>
						<Button className='mt-40 mb-40 button-green button-text'>
							Learn more about ethics review
						</Button>
					</a>
					<p className='lighter-color'>
					{data[20] && data[20]['technical-planning#5'][4]}
						<a
							className='lighter-color link-black'
							href='https://ori.hhs.gov/basic-research-concepts-brc'
							rel='noreferrer'
							target='_blank'>
							{data[20] && data[20]['technical-planning#5'][5]}
						</a>{' '}
						{data[20] && data[20]['technical-planning#5'][6]}
					</p>
					<p className='lighter-color'>
					{data[20] && data[20]['technical-planning#5'][7]}
						<a
							className='lighter-color link-black'
							href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4792175/'
							rel='noreferrer'
							target='_blank'>
							{data[20] && data[20]['technical-planning#5'][8]}
						</a>
						{data[20] && data[20]['technical-planning#5'][9]}
					</p>
				</div>
			</div>
						
			<div className=''>
				<div
					// ref={carousel}
					className='carousel-fair hide-mobile'
					/* whileTap={{ cursor: 'grabbing' }} */>
					<button className={`handle-aspects left-handle-aspects ${sliderIndex === 0 ? 'disable-handle-aspects' : ''}`} onClick={handleLeftSlider}>
					<div className="text-aspects"><h2><Icon name='chevron left' className={`handle-color-aspects ${sliderIndex === 0 ? 'disable-handle-color-aspects' : ''}`} onClick={handleLeftSlider} /></h2></div>
					</button>
					<div
						// drag='x'
						// dragConstraints={{ right: 0, left: -width }}
						className='inner-carousel-fair slider-aspects'>
						<div className='item'>
							<img src='/images/F-green.svg' alt='Findable' />
							<div className='text-green-700'>
								<h2 className='text-green-700 h2-dropdown'>Findable</h2>
								<p>Data can be found quickly.</p>
							</div>
						</div>
						{/* <img className="next-carousel-button" src='/images/next-carousel-button.png' height="56" alt=""  /> */}
						<div className='item'>
							<img src='/images/A-green.svg' alt='Accessible' />
							<div className='text-green-700'>
								<h2 className='text-green-700 h2-dropdown'>Accessible</h2>
								<p>Data is easily retrieved and universally implemented.</p>
							</div>
						</div>
						<div className='item'>
							<img src='/images/I-green.svg' alt='Interoperable' />
							<div className='text-green-700'>
								<h2 className='text-green-700 h2-dropdown'>Interoperable</h2>
								<p>Data is applicable across different platforms and users.</p>
							</div>
						</div>
						<div className='item'>
							<img src='/images/R-green.svg' alt='Reusable' />
							<div className='text-green-700'>
								<h2 className='text-green-700 h2-dropdown'>Reusable</h2>
								<p>
									Data that is accurate, relevant, and can be used in other research.
								</p>
							</div>
						</div>
					</div>
					<button className={`handle-aspects right-handle-aspects ${sliderIndex === 6 ? 'disable-handle-aspects' : ''}`}onClick={handleRightSlider}><div className="text-aspects"><h2><Icon name='chevron right' className={`handle-color-aspects ${sliderIndex === 6 ? 'disable-handle-color-aspects' : ''}`} onClick={handleRightSlider} /></h2></div>
					</button>
				</div>
			</div>
			
			<div className='content-container mb-160-40'>		
							<Accordion
								fluid
								className='mt-88-70 mb-88-70 text-green-700 hide-desktop'>
								<Accordion.Title
									active={activeIndex === 28}
									index={28}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
										F - Findable
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 28}>
									<p>Data can be found quickly.</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 29}
									index={29}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
										A - Accessible
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 29}>
									<p>Data is easily retrieved and universally implemented.</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 30}
									index={30}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
										I - Interoperable
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 30}>
									<p>Data is applicable across different platforms and users.</p>
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 31}
									index={31}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
										R - Reusable
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 31}>
									<p>
										Data that is accurate, relevant, and can be used in other research.
									</p>
								</Accordion.Content>
							</Accordion>

							<p className='lighter-color'>
							{data[20] && data[20]['technical-planning#5'][10]}
							</p>

							<h2 id='5.1' className='mt-40-30 mb-40-30'>
							{data[20] && data[20]['technical-planning#5'][11]}
							</h2>
							<p className='lighter-color'>
							{data[20] && data[20]['technical-planning#5'][12]}
								<a
									className='lighter-color link-black'
									href='http://wikiadvocacy.org/index.php/Registries'
									rel='noreferrer'
									target='_blank'>
									{data[20] && data[20]['technical-planning#5'][13]}
								</a>{' '}
								{data[20] && data[20]['technical-planning#5'][14]}
							</p>

							<Accordion fluid className='mt-88-70 text-green-700'>
								<Accordion.Title
									active={activeIndex === 32}
									index={32}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[20] && data[20]['technical-planning#5'][15]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 32}>
									<p>
									{data[20] && data[20]['technical-planning#5'][16]}
									</p>
									<p>
										<a href='#6'>
											<Button className='mt-40 mb-20 button-green button-text'>
												Engaging with data analysts
											</Button>
										</a>
									</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 33}
									index={33}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[20] && data[20]['technical-planning#5'][17]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 33}>
									<p>
									{data[20] && data[20]['technical-planning#5'][18]}
									</p>
									<p>
									{data[20] && data[20]['technical-planning#5'][19]}
									</p>
									<p>
										<a href='#7'>
											<Button className='mt-40 button-green button-text'>
												Learn more about ethics review
											</Button>
										</a>
									</p>
									<p>
										<a href='#8'>
											<Button className='mt-40 mb-20 button-green button-text'>
												Learn more about regulatory compliance
											</Button>
										</a>
									</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 34}
									index={34}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[20] && data[20]['technical-planning#5'][20]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 34}>
									<p>
									{data[20] && data[20]['technical-planning#5'][21]}
										<a
											className='link-green'
											href='https://docs.google.com/document/u/0/d/1L_t5VccQHY1g67EGa_XEqeFETGm_yLu_ycJ-2oMT4fU/edit'
											rel='noreferrer'
											target='_blank'>
											{data[20] && data[20]['technical-planning#5'][22]}
										</a>
										{data[20] && data[20]['technical-planning#5'][23]}
									</p>
									<p>
									{data[20] && data[20]['technical-planning#5'][24]}
									</p>
									<p>
										<a href='#9'>
											<Button className='mt-40 mb-20 button-green button-text'>
												Choosing the right platform provider
											</Button>
										</a>
									</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 35}
									index={35}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[20] && data[20]['technical-planning#5'][25]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 35}>
									<p>
									{data[20] && data[20]['technical-planning#5'][26]}
										<a
											className='link-green'
											href='https://www.youtube.com/playlist?list=PLmDcGanXc-MPrECKNNEcPbc4cycvCCNNC'
											rel='noreferrer'
											target='_blank'>
											{data[20] && data[20]['technical-planning#5'][27]}
										</a>
										{data[20] && data[20]['technical-planning#5'][28]}
									</p>
									<p>
										<a href='#3'>
											<Button className='mt-40 mb-20 button-green button-text'>
												Additional resources for PROs
											</Button>
										</a>
									</p>
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 36}
									index={36}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[20] && data[20]['technical-planning#5'][29]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 36}>
									<p>
									{data[20] && data[20]['technical-planning#5'][30]}
									</p>
									<p>
										<span className='p-bold'>
										{data[20] && data[20]['technical-planning#5'][31]}
										</span>{' '}
										{data[20] && data[20]['technical-planning#5'][32]}
									</p>
									<p>
										<span className='p-bold'>{data[20] && data[20]['technical-planning#5'][33]}</span>{data[20] && data[20]['technical-planning#5'][34]}
									</p>
									<p>
										<span className='p-bold'>{data[20] && data[20]['technical-planning#5'][35]}</span>{data[20] && data[20]['technical-planning#5'][36]}
									</p>
									<p>
										<span className='p-bold'>{data[20] && data[20]['technical-planning#5'][37]}</span>{data[20] && data[20]['technical-planning#5'][38]}
									</p>
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 37}
									index={37}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[20] && data[20]['technical-planning#5'][39]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 37}>
									<p>
									{data[20] && data[20]['technical-planning#5'][40]}
										<a
											className='link-green'
											href='/registry-goals#7'
											rel='noreferrer'
											target='_blank'>
											{data[20] && data[20]['technical-planning#5'][41]}
										</a>
										{data[20] && data[20]['technical-planning#5'][42]}
										<a
											className='link-green'
											href='/community-building'
											rel='noreferrer'
											target='_blank'>
											{data[20] && data[20]['technical-planning#5'][43]}
										</a>
										{data[20] && data[20]['technical-planning#5'][44]}
									</p>
								</Accordion.Content>
							</Accordion>

							<p className='lighter-color mt-88-70'>
							{data[20] && data[20]['technical-planning#5'][45]}
							</p>

							<a href='#7'>
								<Button className='mt-40 mb-20 button-green button-text'>
									Learn more about ethics review
								</Button>
							</a>
						 {/* </motion.div> 
					 )} 
				 </InView>  */}

			
				<hr className='mt-80-40 mb-80-40 border-neutral' />

				<InView threshold={0.04}>
					{({ ref, inView }) => (
						<motion.div
							ref={ref}
							initial={{ opacity: 0 }}
							animate={inView ? { opacity: 1 } : { opacity: 0 }}
							transition={{ duration: 0.2 }}
							className='section'
							id='6'>
							<h1 className=' mb-40-30'>{data[21] && data[21]['technical-planning#6'][0]}</h1>
							<p className='lighter-color'>
							{data[21] && data[21]['technical-planning#6'][1]}
							</p>
							<p className='lighter-color'>
							{data[21] && data[21]['technical-planning#6'][2]}
								<i className='lighter-color'>{data[21] && data[21]['technical-planning#6'][3]}</i>{data[21] && data[21]['technical-planning#6'][4]}
								<i className='lighter-color'>{data[21] && data[21]['technical-planning#6'][5]}</i>
							</p>

							<Accordion fluid className='mt-88-70 text-green-700'>
								<Accordion.Title
									active={activeIndex === 38}
									index={38}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[21] && data[21]['technical-planning#6'][6]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 38}>
									<p>
									{data[21] && data[21]['technical-planning#6'][7]}
									</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 39}
									index={39}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[21] && data[21]['technical-planning#6'][8]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 39}>
									<p>
									{data[21] && data[21]['technical-planning#6'][9]}{' '}
										<span className='p-italic'>{data[21] && data[21]['technical-planning#6'][10]}</span>{data[21] && data[21]['technical-planning#6'][11]}<span className='p-italic'>{data[21] && data[21]['technical-planning#6'][12]}</span>{data[21] && data[21]['technical-planning#6'][13]}
									</p>
									<p>
									{data[21] && data[21]['technical-planning#6'][14]}
									</p>
								</Accordion.Content>
							</Accordion>

							<p className='lighter-color mt-88-70'>
							{data[21] && data[21]['technical-planning#6'][15]}
								<a
									className='lighter-color link-black'
									href='https://ori.hhs.gov/content/module-3-elements-research-section-1'
									rel='noreferrer'
									target='_blank'>
									{data[21] && data[21]['technical-planning#6'][16]}
								</a>{' '}
								{data[21] && data[21]['technical-planning#6'][17]}
							</p>
							<p className='lighter-color'>
							{data[21] && data[21]['technical-planning#6'][18]}
							</p>
							<p className='lighter-color'>
							{data[21] && data[21]['technical-planning#6'][19]}{' '}
								<a
									className='lighter-color link-black'
									href='https://www.youtube.com/watch?v=BAKRKZq_Ebo'
									rel='noreferrer'
									target='_blank'>
									{data[21] && data[21]['technical-planning#6'][20]}
								</a>
								{data[21] && data[21]['technical-planning#6'][21]}
							</p>
							<p className='lighter-color'>
							{data[21] && data[21]['technical-planning#6'][22]}
							</p>
							<p className='lighter-color'>
							{data[21] && data[21]['technical-planning#6'][23]}
							</p>

							<Accordion fluid className='mt-88-70 text-green-700'>
								<Accordion.Title
									active={activeIndex === 40}
									index={40}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[21] && data[21]['technical-planning#6'][24]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 40}>
									<div className='text-center'>
										<div className='video-responsive'>
											<iframe
												width='560'
												height='315'
												src='https://www.youtube.com/embed/zsfC3tWKiyA'
												title='YouTube video player'
												frameborder='0'
												allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
												allowfullscreen></iframe>
										</div>
									</div>
									<p className='mt-50'>
									{data[21] && data[21]['technical-planning#6'][25]}
									</p>
								</Accordion.Content>
							</Accordion>
						</motion.div>
					)}
				</InView>

				<hr className='mt-80-40 mb-80-40 border-neutral' />

				<InView threshold={0.04}>
					{({ ref, inView }) => (
						<motion.div
							ref={ref}
							initial={{ opacity: 0 }}
							animate={inView ? { opacity: 1 } : { opacity: 0 }}
							transition={{ duration: 0.2 }}
							className='section'
							id='7'>
							<h1 className=' mb-40-30'>{data[22] && data[22]['technical-planning#7'][0]}</h1>
							<p className='lighter-color'>
							{data[22] && data[22]['technical-planning#7'][1]}
							</p>

							<Accordion fluid className='mt-88-70 text-green-700'>
								<Accordion.Title
									active={activeIndex === 41}
									index={41}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[22] && data[22]['technical-planning#7'][2]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 41}>
									<div className='text-center'>
										<div className='video-responsive'>
											<iframe
												width='560'
												height='315'
												src='https://www.youtube.com/embed/1dF7ceUqioM'
												title='YouTube video player'
												frameborder='0'
												allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
												allowfullscreen></iframe>
										</div>
									</div>
									<p className='mt-50'>
									{data[22] && data[22]['technical-planning#7'][3]}
									</p>
									<ol>
										<li>{data[22] && data[22]['technical-planning#7'][4]}</li>
										<li>{data[22] && data[22]['technical-planning#7'][5]}</li>
										<li>{data[22] && data[22]['technical-planning#7'][6]}</li>
										<li>
										{data[22] && data[22]['technical-planning#7'][7]}
										</li>
									</ol>
									<p>
									{data[22] && data[22]['technical-planning#7'][8]}
									</p>
								</Accordion.Content>
							</Accordion>

							<p className='lighter-color mt-88-70'>
							{data[22] && data[22]['technical-planning#7'][9]}
							</p>
							<p className='lighter-color'>
							{data[22] && data[22]['technical-planning#7'][10]}
							</p>
							<p className='lighter-color'>
							{data[22] && data[22]['technical-planning#7'][11]}
								<a
									className='lighter-color link-black'
									href='https://www.wcgirb.com/'
									rel='noreferrer'
									target='_blank'>
									{data[22] && data[22]['technical-planning#7'][12]}
								</a>{' '}
								{data[22] && data[22]['technical-planning#7'][13]}
								<a
									className='lighter-color link-black'
									href='https://geneticalliance.org/irb'
									rel='noreferrer'
									target='_blank'>
									{data[22] && data[22]['technical-planning#7'][14]}
								</a>
								{data[22] && data[22]['technical-planning#7'][15]}
							</p>
							<p className='lighter-color'>
							{data[22] && data[22]['technical-planning#7'][16]}
								<span className='p-italic lighter-color'>{data[22] && data[22]['technical-planning#7'][17]}</span>{data[22] && data[22]['technical-planning#7'][18]}
							</p>
							<p className='lighter-color'>
							{data[22] && data[22]['technical-planning#7'][19]}
							</p>

							<Accordion fluid className='mt-88-70 text-green-700'>
								<Accordion.Title
									active={activeIndex === 42}
									index={42}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[22] && data[22]['technical-planning#7'][20]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 42}>
									<p>
									{data[22] && data[22]['technical-planning#7'][21]}
									</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 43}
									index={43}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[22] && data[22]['technical-planning#7'][22]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 43}>
									<p>
									{data[22] && data[22]['technical-planning#7'][23]}
									</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 44}
									index={44}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[22] && data[22]['technical-planning#7'][24]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 44}>
									<p>
									{data[22] && data[22]['technical-planning#7'][25]}
									</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 45}
									index={45}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[22] && data[22]['technical-planning#7'][26]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 45}>
									<p>
									{data[22] && data[22]['technical-planning#7'][27]}
									</p>
									<p>
									{data[22] && data[22]['technical-planning#7'][28]}
									</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 46}
									index={46}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[22] && data[22]['technical-planning#7'][29]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 46}>
									<p>
									{data[22] && data[22]['technical-planning#7'][30]}
									</p>
									<p>
									{data[22] && data[22]['technical-planning#7'][31]}
									</p>
									<p>
									{data[22] && data[22]['technical-planning#7'][32]}
										<a
											className='link-green'
											href='/community-building'
											rel='noreferrer'
											target='_blank'>
											{data[22] && data[22]['technical-planning#7'][33]}
										</a>
										{data[22] && data[22]['technical-planning#7'][34]}
									</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 47}
									index={47}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[22] && data[22]['technical-planning#7'][35]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 47}>
									<p>
									{data[22] && data[22]['technical-planning#7'][36]}
									</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 48}
									index={48}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[22] && data[22]['technical-planning#7'][37]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 48}>
									<p>
									{data[22] && data[22]['technical-planning#7'][38]}
									</p>
									<ul>
										<li>
										{data[22] && data[22]['technical-planning#7'][39]}
										</li>
										<li>
										{data[22] && data[22]['technical-planning#7'][40]}
										</li>
										<li>
										{data[22] && data[22]['technical-planning#7'][41]}
										</li>
										<li>{data[22] && data[22]['technical-planning#7'][42]}</li>
										<li>{data[22] && data[22]['technical-planning#7'][43]}</li>
										<li>{data[22] && data[22]['technical-planning#7'][44]}</li>
										<li>{data[22] && data[22]['technical-planning#7'][45]}</li>
									</ul>
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 49}
									index={49}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[22] && data[22]['technical-planning#7'][46]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 49}>
									<p>
									{data[22] && data[22]['technical-planning#7'][47]}
									</p>
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 50}
									index={50}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[22] && data[22]['technical-planning#7'][48]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 50}>
									<p>
									{data[22] && data[22]['technical-planning#7'][49]}
									</p>
								</Accordion.Content>
							</Accordion>

							<p className='lighter-color mt-88-70'>
							{data[22] && data[22]['technical-planning#7'][50]}
								<a
									className='lighter-color link-black'
									href='https://www.hhs.gov/ohrp/education-and-outreach/online-education/human-research-protection-training/index.html'
									rel='noreferrer'
									target='_blank'>
									{data[22] && data[22]['technical-planning#7'][51]}
								</a>{' '}
								{data[22] && data[22]['technical-planning#7'][52]}
								<a
									className='lighter-color link-black'
									href='https://about.citiprogram.org/series/human-subjects-research-hsr/'
									rel='noreferrer'
									target='_blank'>
									{data[22] && data[22]['technical-planning#7'][53]}
								</a>{' '}
								{data[22] && data[22]['technical-planning#7'][54]}
								<a
									className='lighter-color link-black'
									href='https://phrptraining.com/'
									rel='noreferrer'
									target='_blank'>
									{data[22] && data[22]['technical-planning#7'][55]}
								</a>{' '}
								{data[22] && data[22]['technical-planning#7'][56]}
							</p>
						</motion.div>
					)}
				</InView>

				<hr className='mt-80-40 mb-80-40 border-neutral' />

				<InView threshold={0.04}>
					{({ ref, inView }) => (
						<motion.div
							ref={ref}
							initial={{ opacity: 0 }}
							animate={inView ? { opacity: 1 } : { opacity: 0 }}
							transition={{ duration: 0.2 }}
							className='section'
							id='8'>
							<h1 className=' mb-40-30'>{data[23] && data[23]['technical-planning#8'][0]}</h1>
							<p className='lighter-color'>
							{data[23] && data[23]['technical-planning#8'][1]}
								<a
									className='lighter-color link-black'
									href='https://www.hhs.gov/ohrp/regulations-and-policy/regulations/common-rule/index.html'
									rel='noreferrer'
									target='_blank'>
									{data[23] && data[23]['technical-planning#8'][2]}
								</a>
								{data[23] && data[23]['technical-planning#8'][3]}
								<a
									className='lighter-color link-black'
									href='https://www.hhs.gov/hipaa/index.html'
									rel='noreferrer'
									target='_blank'>
									{data[23] && data[23]['technical-planning#8'][4]}
								</a>
								{data[23] && data[23]['technical-planning#8'][5]}
								<a
									className='lighter-color link-black'
									href='https://www.fda.gov/about-fda/center-drug-evaluation-and-research-cder/good-clinical-practice'
									rel='noreferrer'
									target='_blank'>
									{data[23] && data[23]['technical-planning#8'][6]}
								</a>
								{data[23] && data[23]['technical-planning#8'][7]}
								<a
									className='lighter-color link-black'
									href='https://gdpr.eu/'
									rel='noreferrer'
									target='_blank'>
									{data[23] && data[23]['technical-planning#8'][8]}
								</a>{' '}
								{data[23] && data[23]['technical-planning#8'][9]}
							</p>

							<Accordion fluid className='mt-88-70 text-green-700'>
								<Accordion.Title
									active={activeIndex === 51}
									index={51}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[23] && data[23]['technical-planning#8'][10]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 51}>
									<p>
									{data[23] && data[23]['technical-planning#8'][11]}
										<a
											className='link-green'
											href='https://www.hhs.gov/ohrp/regulations-and-policy/belmont-report/index.html'
											rel='noreferrer'
											target='_blank'>
											{data[23] && data[23]['technical-planning#8'][12]}
										</a>{' '}
										{data[23] && data[23]['technical-planning#8'][13]}
									</p>
									<ul>
										<li>
										{data[23] && data[23]['technical-planning#8'][14]}
										</li>
										<li>
										{data[23] && data[23]['technical-planning#8'][15]}
										</li>
										<li>
										{data[23] && data[23]['technical-planning#8'][16]}
										</li>
									</ul>
									<p>
									{data[23] && data[23]['technical-planning#8'][17]}
									</p>
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 52}
									index={52}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[23] && data[23]['technical-planning#8'][18]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 52}>
									<p>
									{data[23] && data[23]['technical-planning#8'][19]}
										<a
											className='link-green'
											href='https://www.ncbi.nlm.nih.gov/books/NBK562583/'
											rel='noreferrer'
											target='_blank'>
											{data[23] && data[23]['technical-planning#8'][20]}
										</a>
									</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 53}
									index={53}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[23] && data[23]['technical-planning#8'][21]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 53}>
									<p>
									{data[23] && data[23]['technical-planning#8'][22]}
										<a
											className='link-green'
											href='https://www.ncbi.nlm.nih.gov/books/NBK562583/'
											rel='noreferrer'
											target='_blank'>
											{data[23] && data[23]['technical-planning#8'][23]}
										</a>
									</p>
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 54}
									index={54}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[23] && data[23]['technical-planning#8'][24]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 54}>
									<p>
									{data[23] && data[23]['technical-planning#8'][25]}
									</p>
									<p>
									{data[23] && data[23]['technical-planning#8'][26]}
									</p>
									<p>
									{data[23] && data[23]['technical-planning#8'][27]}
										<a
											className='link-green'
											href='https://www.lunadna.com'
											rel='noreferrer'
											target='_blank'>
											{data[23] && data[23]['technical-planning#8'][28]}
										</a>
										{data[23] && data[23]['technical-planning#8'][29]}
										<a
											className='link-green'
											href='https://geneticalliance.org/peer'
											rel='noreferrer'
											target='_blank'>
											{data[23] && data[23]['technical-planning#8'][30]}
										</a>
										{data[23] && data[23]['technical-planning#8'][31]}
										<a
											className='link-green'
											href='https://youtu.be/1jDQVM_D8-U'
											rel='noreferrer'
											target='_blank'>
											{data[23] && data[23]['technical-planning#8'][32]}
										</a>
										{data[23] && data[23]['technical-planning#8'][33]}
									</p>
								</Accordion.Content>
							</Accordion>

							<p className='lighter-color mt-88-70'>
							{data[23] && data[23]['technical-planning#8'][34]}
								<a
									className='lighter-color link-black'
									href='https://www.ncbi.nlm.nih.gov/books/NBK562583/'
									rel='noreferrer'
									target='_blank'>
									{data[23] && data[23]['technical-planning#8'][35]}
								</a>
							</p>
						</motion.div>
					)}
				</InView>

				<hr className='mt-80-40 mb-80-40 border-neutral' />

				<InView threshold={0.04}>
					{({ ref, inView }) => (
						<motion.div
							ref={ref}
							initial={{ opacity: 0 }}
							animate={inView ? { opacity: 1 } : { opacity: 0 }}
							transition={{ duration: 0.2 }}
							className='section'
							id='9'>
							<h1 className=' mb-40-30'>{data[24] && data[24]['technical-planning#9'][0]}</h1>
							<p className='lighter-color'>
							{data[24] && data[24]['technical-planning#9'][1]}
								<span class='tooltip'>
									<sup>
									{data[24] && data[24]['technical-planning#9'][2]}
										<span class='tooltiptext'>
											Adapted from FasterCures’ 2016 report,{' '}
											<a
												href='https://milkeninstitute.org/report/expanding-science-patient-input-building-smarter-patient-registries'
												target='_blank'
												rel='noreferrer'>
												“Expanding the Science of Patient Input: Building Smarter Patient
												Registries.”
											</a>
										</span>
									</sup>
								</span>{' '}
								{data[24] && data[24]['technical-planning#9'][3]}
							</p>

							<Accordion fluid className='mt-88-70 text-green-700'>
								<Accordion.Title
									active={activeIndex === 55}
									index={55}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[24] && data[24]['technical-planning#9'][4]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 55}>
									<p>
									{data[24] && data[24]['technical-planning#9'][5]}
									</p>
									<p>
									{data[24] && data[24]['technical-planning#9'][6]}
									</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 56}
									index={56}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[24] && data[24]['technical-planning#9'][7]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 56}>
									<p>
									{data[24] && data[24]['technical-planning#9'][8]}
									</p>
									<p>
									{data[24] && data[24]['technical-planning#9'][9]}
									</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 57}
									index={57}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[24] && data[24]['technical-planning#9'][10]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 57}>
									<p>
									{data[24] && data[24]['technical-planning#9'][11]}
									</p>
									<p>
									{data[24] && data[24]['technical-planning#9'][12]}
									</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 58}
									index={58}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[24] && data[24]['technical-planning#9'][13]}
									
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 58}>
									<p>
									{data[24] && data[24]['technical-planning#9'][14]}
									</p>
									<p>
									{data[24] && data[24]['technical-planning#9'][15]}
									</p>
									<p>
									{data[24] && data[24]['technical-planning#9'][16]}
										<a
											className='link-green'
											href='https://www.lunadna.com'
											rel='noreferrer'
											target='_blank'>
												{data[24] && data[24]['technical-planning#9'][17]}
										</a>
										{data[24] && data[24]['technical-planning#9'][18]}
										<a
											className='link-green'
											href='https://geneticalliance.org/peer'
											rel='noreferrer'
											target='_blank'>
												{data[24] && data[24]['technical-planning#9'][19]}
										</a>
										{data[24] && data[24]['technical-planning#9'][20]}<i>{data[24] && data[24]['technical-planning#9'][21]}</i>{data[24] && data[24]['technical-planning#9'][22]}
										<a
											className='link-green'
											href='https://gdpr.eu/'
											rel='noreferrer'
											target='_blank'>
											{data[24] && data[24]['technical-planning#9'][23]}
										</a>
										{data[24] && data[24]['technical-planning#9'][24]}
									</p>{' '}
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 59}
									index={59}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[24] && data[24]['technical-planning#9'][25]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 59}>
									<p>
									{data[24] && data[24]['technical-planning#9'][26]}
									</p>
								</Accordion.Content>
								<hr className='border-green-200' />
								<Accordion.Title
									active={activeIndex === 60}
									index={60}
									onClick={handleAccordion}>
									<h2 className='text-green-700 h2-dropdown'>
									{data[24] && data[24]['technical-planning#9'][27]}
										
									</h2>
									<div className='icon-dropdown'><h2><Icon name='dropdown' className='text-green-700' /></h2></div>{' '}
								</Accordion.Title>
								<Accordion.Content active={activeIndex === 60}>
									<p>
									{data[24] && data[24]['technical-planning#9'][28]}
									</p>
								</Accordion.Content>
							</Accordion>

							<p className='lighter-color mt-88-70'>
							{data[24] && data[24]['technical-planning#9'][29]}
								<a
									className='lighter-color link-black'
									href='/contact-us'
									rel='noreferrer'
									target='_blank'>
									{data[24] && data[24]['technical-planning#9'][30]}
								</a>
								{data[24] && data[24]['technical-planning#9'][31]}
							</p>
						</motion.div>
					)}
				</InView>

			</div>
		</>
	)
}

export default Aspects
