import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Button } from 'semantic-ui-react'
import './FirstQuestion.css'

function FirstQuestion() {
	return (
		<Container
			className='ui container first-question'
			style={{ textAlign: 'center', marginTop: '8%' }}>
			<p className='mb-50 lighter-color'>Welcome to Registry Bootcamp.</p>
			<h1 style={{ maxWidth: '808px', margin: '0 auto' }}>
				Is there something specific you are looking for?
			</h1>
			<div className='two-buttons'>
				<div className='get-started ml-25 mr-25'>
					<Link to='/search'>
						<Button className='button-yes button-blue button-text'>Yes</Button>
					</Link>
				</div>
				<div className='get-started ml-25 mr-25'>
					<Link to='/short-menu'>
						<Button className='button-no button-gray button-text'>No</Button>
					</Link>
				</div>
			</div>
		</Container>
	)
}

export default FirstQuestion
