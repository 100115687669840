import React from 'react'
import styled from 'styled-components'
import { useNavigate, Link } from 'react-router-dom'
import { Image } from 'semantic-ui-react'
import './Search.css'
import { motion } from 'framer-motion'
import * as Realm from 'realm-web'
import { useEffect, useState } from 'react'

const SearchInputContainer = styled.input`
	background: transparent;
	font-size: 24px !important;
	line-height: 24px !important;
	letter-spacing: -0.01em !important;
	color: #a3a8cc !important;
	padding-left: 0px !important;
	border-radius: 0px !important;
`

function Search() {
	const navigate = useNavigate()
	const [searchTerm, setSearchTerm] = useState('')
	const [showFoundData, setShowFoundData] = useState()

	useEffect(() => 
		setSearchTerm(localStorage.getItem('search')
	), [])

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (searchTerm) {
				const app = new Realm.App({ id: "peer-bootcamp-application-ghmpz" });
				const credentials = Realm.Credentials.anonymous();
				async function main() {
					try {
						const data = await app.logIn(credentials);
						const searchData = await data.functions.searchBootcamp(searchTerm)
						setShowFoundData(searchData)
						console.log('searchData', searchData);
					} catch (err) {
						console.error("Failed to log in", err);
					}
				}
				localStorage.setItem('search', searchTerm)
				main()
			}
		}, 500)
		return () => clearTimeout(delayDebounceFn)
	}, [searchTerm])
	
	return (
		<>
		<motion.div
			className='navigation'
			initial={{ x: '-100vw' }}
			animate={{ x: 0 }}
			transition={{ duration: 0.2 }}>
			<div className='navigation-container2'>
				<div className='close icon' onClick={() => navigate(-1)}>
					<Image src='/images/icon-close.svg' />
				</div>
				<div
					className='ui icon input smaller-search1'
						style={{ width: '800px', margin: '120px 15px auto' }}>
						<div className='search-results' style={{ width: '100%' }}>
							<div style={{ width: '100%' }}>
								<SearchInputContainer
									className='font-search search-box search-text'
									id='smaller-search2'
									type='text'
									placeholder='What are you looking for?'
									style={{
										background: 'transparent',
										padding: '10px',
										width: '90%'
									}}
									value={searchTerm}
									autoComplete='off'
									onChange={(e) => setSearchTerm(e.target.value)}
								/>
								<Image
									className='search icon search-icon'
									style={{ borderBottom: '1px solid #A3A8CC', display: 'inline' }}
									src='/images/icon-search-big.svg'
								/>
								</div>
							{showFoundData && 
							<ul className='ul-two-column'>
								{showFoundData.map((item) =>
									<li key={item['_id']}><Link to={`/${item.link}`}>{item.h1}</Link></li>
								)}
							</ul>
							}
						</div>
				</div>
				</div>
		</motion.div>
			</>
	)
}

export default Search
