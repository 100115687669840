import React, { useState, useEffect } from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import { Image, Menu } from 'semantic-ui-react'
import './Header.css'
import { useLocation } from 'react-router-dom'

function Header() {
	const [showMenu, setShowMenu] = useState(false)
	const [hideMenu, setHideMenu] = useState(false)
	const [lightColor, setLightColor] = useState(false)
	const [absoluteMenuTop, setAbsoluteMenuTop] = useState(false)
	const [showShadow, setShowShadow] = useState(false)
	const [navbar, setNavbar] = useState(false)
	const [navbarPosition, setNavbarPosition] = useState(false)


	const location = useLocation()
	const scrollY = window.pageYOffset

	useEffect(() => {
		if (location.pathname === '/') {
			setHideMenu(true)
			setNavbar(false)
		} else {
			setNavbar(true)
			setHideMenu(false)
		}
		if (location.pathname === '/first-question') {
			setLightColor(true)
		} else {
			setLightColor(false)
		}
		if (
			location.pathname === '/' ||
			location.pathname === '/first-question'
		) {
			setNavbar(false)
		} else {
			setNavbar(true)
		}
	}, [location.pathname])

	useEffect(() => {
		if (location.pathname === '/short-menu') {
			setShowShadow(false)
			setNavbarPosition(true)
		}
		else {
			setNavbarPosition(false)
			if (scrollY > 200) {
				setShowShadow(true)
			} else {
				setShowShadow(false)
			}
		}
	}, [scrollY, location.pathname])

	// useEffect(() => {
	//   if (location.pathname === '/first-question' || '/' || 'short-menu') {
	//     if (document.getElementById("navbar")) {
	//       document.getElementById("navbar").removeAttribute("id");
	//     }
	//   }

	// }, [location.pathname])

	// useEffect(() => {
	//   // console.log();
	//   document.getElementsByClassName("showShadow").setAttribute("class", "navbar");

	//   // setAttribute("id", "navbar")
	// }, [])

	const [scrollDir, setScrollDir] = useState('scrolling down')
	useEffect(() => {
		const threshold = 0
		let lastScrollY = window.pageYOffset
		let ticking = false

		const updateScrollDir = () => {
			const scrollY = window.pageYOffset

			if (Math.abs(scrollY - lastScrollY) < threshold) {
				ticking = false
				return
			}
			if (scrollY > lastScrollY) {
				setAbsoluteMenuTop(false)
			} else {
				setAbsoluteMenuTop(true)
			}
			setScrollDir(scrollY > lastScrollY ? 'scrolling down' : 'scrolling up')
			lastScrollY = scrollY > 0 ? scrollY : 0
			ticking = false
		}

		const onScroll = () => {
			if (!ticking) {
				window.requestAnimationFrame(updateScrollDir)
				ticking = true
			}
		}

		window.addEventListener('scroll', onScroll)
		// console.log(scrollDir);

		return () => window.removeEventListener('scroll', onScroll)
	}, [scrollDir])

	var prevScrollpos = window.pageYOffset
	window.onscroll = function () {
		var currentScrollPos = window.pageYOffset
		if ((prevScrollpos > currentScrollPos) || (prevScrollpos === 0)) {
			if (document.getElementById('navbar')) {
				document.getElementById('navbar').style.top = '0'
			}
		} else {
			if (document.getElementById('navbar') && (location.pathname !== '/short-menu')) {
				document.getElementById('navbar').style.top = '-162px'
			}
		}
		prevScrollpos = currentScrollPos
	}

	return (
		<>
			<div
				id={navbar ? 'navbar' : ''}
				className={
					(absoluteMenuTop ? 'absoluteMenuTop' : '') +
					' ' +
					(showShadow ? 'showShadow' : '') +
					' ' +
					(navbarPosition ? 'navbar-short-menu' : '')
				}>
				<Menu className={hideMenu ? 'header hideMenu' : 'header'}>
					<Menu.Item as='a' className='logo'>
						<Link to='/short-menu'>
							<Image
								className='smaller-logo'
								src='/images/logo-bootcamp-GA.svg'
							/>
						</Link>
					</Menu.Item>
					<div className='right menu'>
						<Menu.Item as='a' className='about'>
							<Link
								to='/about'
								className={
									lightColor
										? 'lighter-color  link-black-menu link-about'
										: ' link-black-menu link-about'
								}
								style={{ color: '#000' }}>
								About
							</Link>
						</Menu.Item>
						<Menu.Item as='a' className='events hide'>
							<Link
								to='/events'
								className={
									lightColor
										? 'lighter-color  link-black-menu'
										: ' link-black-menu'
								}
								style={{ color: '#000' }}>
								Events
							</Link>
						</Menu.Item>
						<Menu.Item className='icon-search'>
							<Link to='/search' className='icon-header'>
								<div className='container-icons'>
									<Image
										src='/images/icon-search-black.svg'
										className='icon-header-default'
									/>
									<Image
										src='/images/icon-search-hover.svg'
										className='icon-header-hover'
									/>
								</div>
							</Link>
						</Menu.Item>
						<Menu.Item
							as='a'
							onClick={() => setShowMenu(true)}
							className=' icon-header icon-hamburger'>
							<div className='container-icons'>
								<Image
									src='/images/icon-menu-black.svg'
									className='icon-header-default'
								/>
								<Image
									src='/images/icon-menu-hover.svg'
									className='icon-header-hover'
								/>
							</div>
						</Menu.Item>
					</div>
				</Menu>
			</div>

			<div className={showMenu ? 'menu-navigation-animation' : 'menu-navigation'}>
				<div className='navigation-container'>
					<div className='close icon' onClick={() => setShowMenu(false)}>
						<Image id='close-icon' src='/images/icon-close.svg' />
					</div>
					<div
						/* className='mb-130-50'  */ className='ui stackable two column grid inside-menu-navigation menu-two-column' style={{ margin: 'auto 15px'}} /* style={{minWidth: '1224px'}} id='mt80-0' */
					>
						<div id='first-column' className='column'>
							<div className='registry-menu'>
								<h2 className='title-page hide-mobile'>
									<Link
										to='/registry-goals'
										onClick={() => setShowMenu(false)}
										className='registry-header-link'>
										Why are you starting a registry?
									</Link>
								</h2>
								<p className='title-page p-bold hide-desktop'>
									<Link
										to='/registry-goals'
										onClick={() => setShowMenu(false)}
										className='color-inherit'>
										Why are you starting a registry?
									</Link>
								</p>
								<div className='blue-300'>
									<p className='context-menu'>
										<Link
											to='/registry-goals#1'
											onClick={() => setShowMenu(false)}
											className='registry-first-link'>
											<i></i>Who needs to be at the table?
										</Link>
									</p>
									<p className='context-menu'>
										<Link
											to='/registry-goals#2'
											onClick={() => setShowMenu(false)}
											className='registry-second-link'>
											<i></i> Understanding your stakeholders’ needs
										</Link>
									</p>
									<p className='context-menu'>
										<Link
											to='/registry-goals#3'
											onClick={() => setShowMenu(false)}
											className='registry-third-link'>
											<i></i> What do you want to accomplish?
										</Link>
									</p>
									<p className='context-menu'>
										<Link
											to='/registry-goals#4'
											onClick={() => setShowMenu(false)}
											className='registry-fourth-link'>
											<i></i> Considering return on investment
										</Link>
									</p>
									<p className='context-menu'>
										<Link
											to='/registry-goals#5'
											onClick={() => setShowMenu(false)}
											className='registry-fifth-link'>
											<i></i> Creating an advisory committee
										</Link>
									</p>
									<p className='context-menu'>
										<Link
											to='/registry-goals#6'
											onClick={() => setShowMenu(false)}
											className='registry-sixth-link'>
											<i></i>Developing SMART objectives
										</Link>
									</p>
									<p className='context-menu'>
										<Link
											to='/registry-goals#7'
											onClick={() => setShowMenu(false)}
											className='registry-seventh-link'>
											<i></i>Planning for ongoing engagement
										</Link>
									</p>
								</div>
							</div>
							<hr className='border-navy-300 hide-desktop mt-20 mb-20' />
							<div className='resources-menu mt-100-0'>
								<h2 className='title-page hide-mobile'>
									<Link
										to='/required-resources'
										onClick={() => setShowMenu(false)}
										className='resources-header-link'>
										Introducing available resources
									</Link>
								</h2>
								<p className='title-page p-bold hide-desktop mt-100-0'>
									<Link
										to='/required-resources'
										onClick={() => setShowMenu(false)}
										className='color-inherit'>
										Introducing available resources
									</Link>
								</p>
								<div className='red-300'>
									<p className='context-menu'>
										<Link
											to='/required-resources#1'
											onClick={() => setShowMenu(false)}
											className='resources-first-link'>
											<i></i> Key resources
										</Link>
									</p>
									<p className='context-menu'>
										<Link
											to='/required-resources#2'
											onClick={() => setShowMenu(false)}
											className='resources-second-link'>
											<i></i> Thinking about cost
										</Link>
									</p>
									<p className='context-menu'>
										<Link
											to='/required-resources#3'
											onClick={() => setShowMenu(false)}
											className='resources-third-link'>
											<i></i> Funding your registry
										</Link>
									</p>
								</div>
							</div>
							<hr className='border-navy-300 hide-desktop mt-20 mb-20' />
						</div>
						<div id='second-column' className='column'>
							<div className='aspects-menu'>
								<h2 className='title-page hide-mobile'>
									<Link
										to='/technical-planning'
										onClick={() => setShowMenu(false)}
										className='aspects-header-link'>
										Planning the technical aspects
									</Link>
								</h2>
								<p className='title-page p-bold hide-desktop'>
									<Link
										to='/technical-planning'
										onClick={() => setShowMenu(false)}
										className='color-inherit'>
										Planning the technical aspects
									</Link>
								</p>
								<div className='green-300'>
									<p className='context-menu'>
										<Link
											to='/technical-planning#1'
											onClick={() => setShowMenu(false)}
											className='aspects-first-link'>
											<i></i> The registry planning process
										</Link>
									</p>
									<p className='context-menu'>
										<Link
											to='/technical-planning#2'
											onClick={() => setShowMenu(false)}
											className='aspects-second-link'>
											<i></i> What is the primary focus of your registry?
										</Link>
									</p>
									<p className='context-menu'>
										<Link
											to='/technical-planning#3'
											onClick={() => setShowMenu(false)}
											className='aspects-third-link'>
											<i></i> Understanding data types
										</Link>
									</p>
									<p className='context-menu'>
										<Link
											to='/technical-planning#4'
											onClick={() => setShowMenu(false)}
											className='aspects-fourth-link'>
											<i></i> Deciding what data you will collect
										</Link>
									</p>
									<p className='context-menu'>
										<Link
											to='/technical-planning#5'
											onClick={() => setShowMenu(false)}
											className='aspects-fifth-link'>
											<i></i> Planning for your research protocol
										</Link>
									</p>
									<p className='context-menu'>
										<Link
											to='/technical-planning#6'
											onClick={() => setShowMenu(false)}
											className='aspects-sixth-link'>
											<i></i> Considering data analysis
										</Link>
									</p>
									<p className='context-menu'>
										<Link
											to='/technical-planning#7'
											onClick={() => setShowMenu(false)}
											className='aspects-seventh-link'>
											<i></i> Ethics review for your registry
										</Link>
									</p>
									<p className='context-menu'>
										<Link
											to='/technical-planning#8'
											onClick={() => setShowMenu(false)}
											className='aspects-eighth-link'>
											<i></i> Registries and regulatory compliance
										</Link>
									</p>
									<p className='context-menu'>
										<Link
											to='/technical-planning#9'
											onClick={() => setShowMenu(false)}
											className='aspects-ninth-link'>
											<i></i> Choosing the right platform provider
										</Link>
									</p>
								</div>
							</div> 
							<hr className='border-navy-300 hide-desktop mt-20 mb-20' />
							<div className='building-menu mt-50-0'>
								<h2 className='title-page hide-mobile'>
									<Link
										to='/community-building'
										onClick={() => setShowMenu(false)}
										className='building-header-link'>
										Community-building and research
									</Link>
								</h2>
								<p className='title-page p-bold hide-desktop mt-50-0'>
									<Link
										to='/community-building'
										onClick={() => setShowMenu(false)}
										className='color-inherit'>
										Community-building and research
									</Link>
								</p>
								<div className='purple-300'>
									<p className='context-menu'>
										<Link
											to='/community-building#1'
											onClick={() => setShowMenu(false)}
											className='building-first-link'>
											<i></i> Articulating your commitment to your community
										</Link>
									</p>
									<p className='context-menu'>
										<Link
											to='/community-building#2'
											onClick={() => setShowMenu(false)}
											className='building-second-link'>
											<i></i> How are you reaching people?
										</Link>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Header
